/* eslint-disable max-len */
// Base
import React from 'react';
import BaseComponent from '@aroundmedia/frontend-library/lib/shared/basecomponent/BaseComponent';
import classNames from 'classnames';
// import { BrowserHelper } from '../../../helpers/browser-helper';

export default class Tutorial extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = props;

    this.tutorialText = props.localizer.get(
      this.props.isMobile ? 'mobileTutorial' : 'desktopTutorial'
    );
  }

  render() {
    const tutorialIcon = this.props.isMobile ? (
      <TutorialMobileIcon />
    ) : (
      <TutorialDesktopIcon />
    );
    const tutorialTypeContainerClass = classNames({
      'am-mobile-tutorial-content-container': this.props.isMobile,
      'am-desktop-tutorial-content-container': !this.props.isMobile
    });
    const textContainerClasses = classNames(
      'am-tutorial-container__text-container',
      {
        'am-tutorial-container__text-container--mobile': this.props.isMobile,
        'am-tutorial-container__text-container--desktop': !this.props.isMobile
      }
    );

    return (
      <div
        className={classNames('am-tutorial-container', {
          'am-tutorial-container--small': this.props.autoRotate
        })}
      >
        <div className="am-tutorial-content-container">
          <div className={tutorialTypeContainerClass}>
            {tutorialIcon}
            <div className={textContainerClasses}>
              <p className="tutorial-text">{this.tutorialText}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class TutorialDesktopIcon extends BaseComponent {
  render() {
    return (
      <div className="am-desktop-tutorial-container__icon-container am-desktop-intro-img">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="170px"
          height="70px"
          viewBox="0 0 170 70"
          style={{ enableBackground: 'new 0 0 170 70' }}
          xmlSpace="preserve"
        >
          <g id="XMLID_377_">
            <g id="XMLID_380_">
              <g id="XMLID_404_">
                <path
                  className="st0"
                  d="M108.7,35L90.7,24.7l-0.3-0.2L72.2,13.9c0,0-11.3,13-16,24.1c-6.6,15.7,8.3,25.8,10.7,27.3l0,0
                                    c0,0,0.1,0,0.2,0.1c0.1,0.1,0.2,0.1,0.2,0.1l0,0c2.5,1.3,18.4,9,28.7-4.6C103.1,51.3,108.7,35,108.7,35z"
                />
              </g>
              <g id="XMLID_397_">
                <g id="XMLID_398_">
                  <path
                    id="XMLID_403_"
                    className="st0"
                    d="M109.4,33.7c0,0,6-12.3,4.9-16.5C113,12.5,102,6,102,6l-2.1,3.6c1.1,1,1.4,2.7,0.6,4.1
                                        l-3,5.1c-0.8,1.4-2.4,2-3.9,1.5l-1.9,3.3L109.4,33.7z"
                  />
                  <path
                    id="XMLID_399_"
                    className="st1"
                    d="M91.8,15.5l3-5.1c0.8-1.4,2.4-2,3.9-1.5l2.1-3.6c0,0-11.2-6.3-16-5
                                        c-4.2,1.1-11.8,12.5-11.8,12.5l17.6,10.2l1.9-3.3C91.3,18.5,91,16.8,91.8,15.5z"
                  />
                </g>
              </g>
              <g id="XMLID_383_">
                <path
                  className="st0"
                  d="M93.3,18.9c-0.9-0.7-1.1-2.1-0.4-3.1l2.7-4.6c0.6-1.1,1.9-1.6,2.9-1.2c1.3,0.5,1.7,2.1,1,3.3l-2.8,4.8
                                    C96,19.3,94.4,19.7,93.3,18.9z"
                />
              </g>
            </g>
            <text
              id="XMLID_379_"
              transform="matrix(1 0 0 1 150.4565 50.6514)"
              className="st2 st3 st4"
            >
              &#10095;
            </text>
            <text
              id="XMLID_378_"
              transform="matrix(1 0 0 1 0 50.6514)"
              className="st2 st3 st4"
            >
              &#10094;
            </text>
          </g>
        </svg>
      </div>
    );
  }
}

export class TutorialMobileIcon extends BaseComponent {
  render() {
    return (
      <div className="am-mobile-tutorial-container__icon-container am-mobile-intro-img">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="200px"
          height="137px"
          viewBox="0 0 200 137"
          style={{ enableBackground: 'new 0 0 200 137' }}
          xmlSpace="preserve"
        >
          <g id="XMLID_3101_">
            <g id="XMLID_3115_">
              <path
                className="st0"
                d="M187,81.3c0-0.2,0.1-0.4,0.2-0.5L196,72l-8.8-8.8c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2,0.1-0.4,0.2-0.5l1.1-1.1
                                c0.1-0.1,0.3-0.2,0.5-0.2s0.4,0.1,0.5,0.2l10.4,10.4c0.1,0.1,0.2,0.3,0.2,0.5s-0.1,0.4-0.2,0.5l-10.4,10.4
                                c-0.1,0.1-0.3,0.2-0.5,0.2s-0.4-0.1-0.5-0.2l-1.1-1.1C187.1,81.7,187,81.5,187,81.3z"
              />
            </g>
            <g id="XMLID_3114_">
              <path
                className="st0"
                d="M0,73c0-0.2,0.1-0.4,0.2-0.5l10.4-10.4c0.1-0.1,0.3-0.2,0.5-0.2s0.4,0.1,0.5,0.2l1.1,1.1
                                c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.4-0.2,0.5L4,73l8.8,8.8c0.1,0.1,0.2,0.3,0.2,0.5s-0.1,0.4-0.2,0.5l-1.1,1.1
                                c-0.1,0.1-0.3,0.2-0.5,0.2s-0.4-0.1-0.5-0.2L0.2,73.5C0.1,73.4,0,73.2,0,73z"
              />
            </g>
            <g id="_x39__1_">
              <g id="Color_1_">
                <path
                  id="XMLID_3111_"
                  className="st1"
                  d="M121.2,34.5l20.5-17.8L122,0l-1.3,9.2c-0.1,0-19.5-0.4-34.1,3c-17.3,4.1-31.8,9.6-31.7,10
                                    c0,0.3,16.7-3.6,36.6-3.2c16,0.3,30.1,4.9,30.1,4.9L121.2,34.5z M120.5,16.7c0.1-4,3.5-7.1,7.5-7c4,0.1,7.1,3.5,7,7.5
                                    s-3.5,7.1-7.5,7C123.5,24,120.3,20.7,120.5,16.7z"
                />
                <path
                  id="XMLID_3108_"
                  className="st0"
                  d="M79.8,71.4c0,0,0.7-1.4,1.6-2.6c1.6-2.2,4.3-5.6,6.8-10.2c3-5.6,3.7-7.8,7.2-11.1
                                    c2.5-2.4,13.7-16.7,16.7-21.1c3.6-5.1,9.9-12.4,11.8-13.5c4.4-2.6,6.9-1.3,8.8,2.3c0.5,1,0.3,2.1-0.3,3.8
                                    c-0.9,2.4-1.8,3.3-3.4,5.4c-2.3,3.1-5.8,7.3-6.5,8.5c-1.9,3.7-8.5,13.4-8.5,13.4s4.8-4.1,10.6-2.6c3.3,1.2,6.4,3,6.6,5.3
                                    c0.5,4.5,0,7.9,0,7.9s4,0.2,5.3,4c1.3,3.8-0.5,10.6-0.5,10.6s2,0.8,3,4c1,3.2,0.9,8.8,1,10.2c0.4,2.8,0.4,1,0.3,3
                                    c0,0.5,0.1,2.9-1.2,5.9c-3.9,9.1-12.7,25.9-13.3,28.9c-0.2,1.1,0.3,2.8,0.1,3.7c-0.3,2.3-1,3-0.5,3.7c0.7,0.9-0.1,0.8-0.7,2.6
                                    c-0.6,1.8-39.9,8.5-44.9-2.6c-1.7-3.7-2.2-9.6-4.6-14.2c-1.3-2.5-5.9-8.4-9.8-17.1c-3.3-7.5-5.9-17.9-6.2-20.1
                                    c-0.5-3.5,2.1-8.4,3.6-13.8c1.2-4.5,2.3-9.4,3.5-10.3c3.7-2.9,7-3.4,7-3.4s1.1-0.9,1.5-1.1c0.4-0.1,3.1-5.4,6.1-5.5
                                    c1.7-0.1,3.1,1.2,3.7,4.3c0.2,0.9,0.2,2.7-0.1,4.6c-0.3,2-2,4-3.3,6.1c-1.7,2.8-3.9,3.4-3.9,3.3c-0.1,0.9,1,1.7,0.4,3.9
                                    c-0.7,2.2-1.5,1.6-1.7,2.7c0,0,1.9-0.4,2.6,0C79,70.3,79.8,71.4,79.8,71.4z"
                />
              </g>
              <g id="Shadows_1_">
                <linearGradient
                  id="XMLID_2_"
                  gradientUnits="userSpaceOnUse"
                  x1="440.2467"
                  y1="219.6227"
                  x2="434.2008"
                  y2="233.0392"
                  gradientTransform="matrix(0.6809 0.7323 -0.7323 0.6809 -16.1248 -424.7116)"
                >
                  <stop
                    offset="0"
                    style={{ stopColor: '#231F20', stopOpacity: '0' }}
                  />
                  <stop offset="1" style={{ stopColor: '#231F20' }} />
                </linearGradient>
                <path
                  id="XMLID_3106_"
                  className="st2"
                  d="M109.2,56.3c-0.1,0.3,2.9-1.8,4.5-3.2c2.3-1.9,5.1-5.2,7-6.4c2.7-1.7,4.3-1.4,4.1-2.5
                                    c-3.3-0.4-4.3-0.4-5.3-0.3c-1.9,0.1-4.7,2-4.7,2S109.7,55.1,109.2,56.3z"
                />
                <linearGradient
                  id="XMLID_3_"
                  gradientUnits="userSpaceOnUse"
                  x1="340.0322"
                  y1="208.9296"
                  x2="335.3784"
                  y2="210.7702"
                  gradientTransform="matrix(0.8529 0.5221 -0.5221 0.8529 -49.5256 -293.7122)"
                >
                  <stop
                    offset="0"
                    style={{ stopColor: '#231F20', stopOpacity: '0' }}
                  />
                  <stop offset="1" style={{ stopColor: '#231F20' }} />
                </linearGradient>
                <path
                  id="XMLID_3105_"
                  className="st3"
                  d="M125.8,68.6c0,0,0.7-2.7,2-6.1c1.2-3.4,3.6-8.1,3.6-8.1s0.4,1.2-0.4,3.9
                                    c-0.7,2.3-1,1.6-4.1,8.2C126.1,67.7,126.6,66.9,125.8,68.6z"
                />
                <linearGradient
                  id="XMLID_4_"
                  gradientUnits="userSpaceOnUse"
                  x1="363.0648"
                  y1="216.6518"
                  x2="361.0746"
                  y2="217.439"
                  gradientTransform="matrix(0.8376 0.5462 -0.5462 0.8376 -49.5485 -306.9049)"
                >
                  <stop
                    offset="0"
                    style={{ stopColor: '#231F20', stopOpacity: '0' }}
                  />
                  <stop offset="1" style={{ stopColor: '#231F20' }} />
                </linearGradient>
                <path
                  id="XMLID_3104_"
                  className="st4"
                  d="M134,77.6c0,0-0.5-1.1-0.1-2.8c0.3-1.7,2.8-7.1,2.8-7.1s-0.4,1.8-0.6,3.2
                                    c-0.1,1.2-0.5,0.7-1.7,3.9C134.2,75.4,133.9,76,134,77.6z"
                />
                <linearGradient
                  id="XMLID_5_"
                  gradientUnits="userSpaceOnUse"
                  x1="873.6833"
                  y1="421.5095"
                  x2="865.8053"
                  y2="423.8432"
                  gradientTransform="matrix(0.183 0.4497 -0.5364 0.2183 185.1483 -465.2981)"
                >
                  <stop offset="0" style={{ stopColor: '#231F20' }} />
                  <stop
                    offset="1"
                    style={{ stopColor: '#231F20', stopOpacity: '0' }}
                  />
                </linearGradient>
                <path
                  id="XMLID_3103_"
                  className="st5"
                  d="M115.2,22.2c-0.3,0.3-0.6,0.1-0.6,0.1l6-6.7c0,0,0.3-1.6,0.2-0.9c-0.1,0.7-0.3,1.4-0.3,1.4
                                    C120.1,16.2,115.6,21.8,115.2,22.2z"
                />
                <linearGradient
                  id="XMLID_6_"
                  gradientUnits="userSpaceOnUse"
                  x1="124.1481"
                  y1="25.957"
                  x2="130.5897"
                  y2="29.4066"
                >
                  <stop offset="0" style={{ stopColor: '#231F20' }} />
                  <stop
                    offset="1"
                    style={{ stopColor: '#231F20', stopOpacity: '0' }}
                  />
                </linearGradient>
                <path
                  id="XMLID_3102_"
                  className="st6"
                  d="M129.2,24l-7.1,9.7l8.3-7.2l2.7-4.7c0,0-1,0.8-2,1.6C130.1,24.1,129.2,24,129.2,24z"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

/* eslint-enable max-len */
