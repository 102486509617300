/* eslint-disable import/first */

// XR
import WebXRPolyfill from 'webxr-polyfill';
const polyfill = new WebXRPolyfill({
  allowCardboardOnDesktop: true,
  webvr: true
});

// Base
import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import Measure from 'react-measure';

import Raven from 'raven-js';
import classnames from 'classnames';

import BaseComponent from '@aroundmedia/frontend-library/lib/shared/basecomponent/BaseComponent';
import { ViewerSDK } from '@aroundmedia/frontend-library/lib/sdks/ViewerSDK';
import { Notifier } from '@aroundmedia/frontend-library/lib/shared/Notifier';
import { getQueryParameter } from '@aroundmedia/frontend-library/lib/shared/helpers/General';
import AFLCookieWarningHelper from '@aroundmedia/frontend-library/lib/shared/components/helpers/AFLCookieWarningHelper';
import Helpers from '@aroundmedia/frontend-library/lib/shared/helpers/Helpers';
import { CookieAsync } from '@aroundmedia/frontend-library/lib/shared/helpers/CookieAsync';

// THREE.js
import './three/three';

import '../helpers/deviceOrientationControls';
import WebVR from './webvr/WEBVR';

import BrowserHelper from '../helpers/browser-helper';
import FullScreen from '../helpers/fullscreen-helper';
import XRPresentingHelper from '../helpers/XRPresentingHelper';

import './controls/around-orbit-controls';

import SphereShaders from './shaders/SphereShaders';
import LogoShaders from './shaders/LogoShaders';

import Sphere from './context/Sphere';
import FadePlane from './context/FadePlane';
import SpotLinksContainer from './spotlinks/SpotLinksContainer';
import BottomLogo from './branding/BottomLogo';
import FontLoader from './UI/fonts/FontLoader';
import UIContainer from './UI/UIContainer';
import HomeViewButton from './UI/HomeViewButton';
import Loader from './UI/loader/Loader';
import Tutorial from './UI/tutorial/Tutorial';
import VrSplash from './UI/tutorial/VrSplash';
import Analytics from './analytics/Analytics';
import Localizer from '../languages/Localizer';
import TwilioManager from '../helpers/twilio-helper';
import TapListener from '../components/controls/TapListener';

import {
  ViewModes,
  isNormalViewMode,
  isGyroViewMode,
  isVRViewMode
} from '../config/types/ViewModes';
import { UIComponentTypes } from '../config/types/Components';

import ENV from '../config/active-environment/env.json';
import { version } from '../../../package.json'; // trust me this works
import { timestamp } from '../../../stamp.json'; // trust me this works
import { release } from '../../../release.json'; // trust me this works

import { rotateToStartPos, lookAtPoint } from '../helpers/camera-helper';
import { loadCssForAlbum } from '../helpers/helpers';
import WindowSizeListener from 'react-window-size-listener';

import { withMiniStore, changeViewModeAction } from '../MiniStore';

/* eslint-enable import/first */

const googleMaterialFontID = 'amav-google-font-Material-Icons';
const googleOpenSansFontID = 'amav-google-font-Open-Sans';

/**
 * Class for Viewer
 *
 */
class Viewer extends BaseComponent {
  constructor(props) {
    super(props);

    const metaData = this.parseQueryparameters(props.metaData);
    const isMobile = BrowserHelper.checkMobile();
    const hasGyro = BrowserHelper.hasGyro();
    const WebVRAvailable = WebVR.isAvailable();

    this.state = {
      metaData,
      isMobile,
      hasGyro,
      WebVRAvailable,
      fade: 'in',
      baseFOV: 60,
      fadingSpot: false,
      requireHomeViewButton: !!isMobile,
      sphereDiameter: 1000,
      activeInfoPointId: null,
      dev: ENV.env === 'development',
      renderTargetName: 'am-viewer-render-target', // This should come in as a prop
      features: {},
      authStatus: null,
      autoRotation: false,
      startComponent: null,
      naked: false,
      showNakedWarning: false,
      domElement: props.domElement
    };

    this.authChecked = false;
    this.resizeTimeout = null;

    this.analytics = new Analytics(metaData.albumCode);
    this.localizer = new Localizer(metaData.lang);
    this.tick = 0;
    this.time = 0;

    BrowserHelper.applyOldClasses();

    // Set other configs on the SDK
    ViewerSDK.setTextureLoader(new THREE.TextureLoader());
    ViewerSDK.setMobile(isMobile);

    // Append the fonts to be used
    FontLoader.loadFont(
      'https://fonts.googleapis.com/icon?family=Material+Icons',
      googleMaterialFontID
    );
    FontLoader.loadFont(
      'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600',
      googleOpenSansFontID
    );

    // Lower the default debounce amount to make the resize more responsive
    // WindowResizeListener.DEBOUNCE_TIME = 0;

    // Set the Unload so we get a correct userTiming
    window.addEventListener('unload', () => {
      this.analytics.sendTrackOnUnload();
      return false;
    });

    window.addEventListener('around.media.viewer.refresh', () => {
      this.setState(this.state);
    });

    // Settings for fullscreen
    FullScreen.isMobile = this.state.isMobile;
    FullScreen.registerFullscreenEscapeListener();
    FullScreen.onExitFullscreenCallback = this.onExitFullscreen;
    FullScreen.onFullScreenChange = this.onResize;

    const releaseString = release;
    if (ENV.RavenSDN && ENV.env !== 'development') {
      const environment = ENV.env;

      Raven.config(ENV.RavenSDN, {
        environment,
        release: releaseString,
        shouldSendCallback: (data) =>
          data && data.culprit && /am-viewer/.test(data.culprit),
        tags: {
          albumCode: this.state.metaData.albumCode
        }
      }).install();
    }

    window.console.log(`Powered by Around Media: ${release}`);

    // TODO - Switch this on when we have more info/iframe cookie system
    // Notifier.subscribe('cookies.warn', () => {
    //     this.setState({ showCookieWarning: true });
    // });
  }

  UNSAFE_componentWillMount() {
    ViewerSDK.getCurrentToken(this.state.metaData.albumCode)
      .then((token) => {
        this._currentToken = token;

        // create the shaders that will be used
        SphereShaders.createVertexShader('vShader');
        SphereShaders.createFragmentShader('fShader');
        LogoShaders.createVertexShader('vShader-logo');
        LogoShaders.createFragmentShader('fShader-logo');

        return ViewerSDK.getAlbumCoverImageData(this.state.metaData.albumCode);
      })
      .then((coverImageData) => {
        if (coverImageData) {
          this.setState(
            {
              coverImageDataUrl: `data:image/jpeg;base64,${coverImageData.image}`
            },
            () => {
              // this.WebVR.coverImageUrl = this.state.coverImageDataUrl;
            }
          );
        }

        // return ViewerSDK.loadAlbumWithCode(this.state.metaData.albumCode);
        CookieAsync.get('around-customer-token')
          .then((cookieValue) => {
            const customerSessionToken = cookieValue;
            this.analytics.customerToken = customerSessionToken;
            this.loadData(
              this.state.metaData.albumCode,
              null,
              customerSessionToken
            );
          })
          .catch(() => {
            this.loadData(this.state.metaData.albumCode);
          });
      })
      .catch((error) => {
        if (error && error.statusCode && error.statusCode === 404) {
          this.setState({ isNotFound: true });
        }
      });

    Notifier.subscribe('spot.changed', () => {
      this.setState(
        {
          previousSpot: this.state.currentSpot,
          currentSpot: ViewerSDK.getCurrentSpot()
        },
        this.onSpotChange
      );
    });
  }

  UNSAFE_componentWillUpdate() {}

  componentDidMount() {
    this.container = this.canvasTarget.parentNode;
    this.updateSize(() => {
      this.createRenderer(this.canvasTarget);
      const firstMoveEvent = () => {
        this.orbitControls.removeEventListener('start', firstMoveEvent);
        this.setState(
          {
            firstMoved: true,
            autoRotation: false
          },
          () => {
            this.orbitControls && (this.orbitControls.noZoom = false);
          }
        );

        const currentSpotObjectId = this.state.currentSpot
          ? this.state.currentSpot.objectId
          : '';
        this.analytics.sendTrackOnInteraction(currentSpotObjectId);
      };

      this.analytics.sendTrackOnLoad();
      this.orbitControls.addEventListener('start', firstMoveEvent);
    });
  }

  componentWillUnmount() {
    // TODO: make a renderloop component to handle all this
    // e.g. https://developer.mozilla.org/nl/docs/Web/API/Window/cancelAnimationFrame
    // this.renderLoop.stop();

    // Expand this list when new things are added to the 3d context
    this.scene = null;
    this.camera = null;
    this.renderer = null;
    this.orbitControls = null;
    this.DeviceOrientationControls = null;

    // Remove fonts
    FontLoader.removeFont(googleMaterialFontID);
    FontLoader.removeFont(googleOpenSansFontID);

    this.analytics.sendTrackOnUnload();
    // remove stats
    // document.body.removeChild(this.stats.dom);
  }

  // TODO - put this in a config/type/Parameters.js file
  parseQueryparameters = (data) => {
    const _data = data;

    const id = getQueryParameter('id');
    if (id) {
      _data.albumCode = id.replace(/\s/g, '');
    }

    const lang = getQueryParameter('amlang');
    if (lang) {
      _data.lang = lang;
    }

    const loaderLogo = getQueryParameter('amloaderlogo');
    if (loaderLogo) {
      _data.loaderLogoActive = loaderLogo;
    }

    return _data;
  };

  /**
   * load the data.
   * @param {string} albumCode
   * @param {string} [credential]
   * @param {string} [sessionToken]
   */
  loadData = (albumCode, credential, sessionToken) => {
    this.setState({ authStatus: { code: 1, state: 'default' } }, () => {
      ViewerSDK.loadAlbumWithCode(albumCode, credential, sessionToken)
        .then((data) => {
          if (data.album) {
            // Parse all features found in the styling object
            const features = {};
            if (data.styling) {
              if (data.styling.includedFeatureList) {
                data.styling.includedFeatureList.forEach((item) => {
                  features[item] = true;
                });
              }
              if (data.styling.customCss) {
                loadCssForAlbum(
                  this.state.metaData.albumCode,
                  this._currentToken,
                  `${version}.${timestamp}`
                );
              }
            }

            let albumConfig;
            if (data.album.albumConfig) {
              albumConfig = data.album.albumConfig;
              if (albumConfig.mailWallEnabled) {
                this.analytics.sendTrackOnMailWallEnabled();

                if (sessionToken) {
                  // This tour has a mailwall and was loaded with a token, so the mailwall was passed
                  this.analytics.sendTrackOnMailWallTokenUsed();
                }
              }
            }

            this.setState(
              {
                features,
                albumConfig,
                albumData: data.album,
                styling: data.styling,
                currentSpot: ViewerSDK.getCurrentSpot(),
                autoRotation: data.album.autoRotate
              },
              () => {
                if (this.state.autoRotation) {
                  // this.renderLoop.keepAlive = true;
                }
              }
            );
          }

          return ViewerSDK.getAlbumPublished();
        })
        .then((isPublished) => {
          this.setState({
            authRequired: false,
            isPublished
          });

          // Checking for chat
          if (TwilioManager.hasChannelInfo()) {
            TwilioManager.reconnecting = true;
            Notifier.publish('chat.loading');
            this.setState(
              {
                firstMoved: true,
                startComponent: UIComponentTypes.chat
              },
              () => {
                this.orbitControls && (this.orbitControls.noZoom = false);
              }
            );

            TwilioManager.attemptReconnect(() => {
              TwilioManager.reconnecting = false;
              TwilioManager.reconnected = true;

              Notifier.publish('chat.loaded');
            });
          }

          this.authChecked = true;
        })
        .catch((error) => {
          this.onDataLoadError(error);
          this.authChecked = true;
        });
    });
  };

  onDataLoadError = (error) => {
    if (
      error &&
      error.detail &&
      typeof error.detail.published !== 'undefined'
    ) {
      this.setState({
        isPublished: error.detail.published
      });
    }

    if (error && error.statusCode && error.statusCode === 401) {
      window.console.log('current authStatus: %o', this.authChecked);

      let accessLevel = 0; // PUBLIC
      let mailWallPhoneNumberMandatory = false;
      if (error.error && error.error.code === 1005) {
        accessLevel = 1; // MAILWALL
        mailWallPhoneNumberMandatory = error.error.mailWallPhoneNumberMandatory;
        this.analytics.sendTrackOnMailWallShown();
      } else if (error.error && error.error.code === 1004) {
        accessLevel = 2; // PRIVATE
      }

      this.setState({
        authStatus: {
          code: !this.authChecked ? 200 : 401,
          state: 'default',
          message: 'Please make sure you have entered the correct code'
        },
        authRequired: true,
        mailWallPhoneNumberMandatory,
        accessLevel
      });

      if (this.authChecked) {
        setTimeout(() => {
          this.setState({
            authStatus: { code: 200, state: 'default' }
          });
        }, 2500);
      }
    }

    if (error && error.statusCode && error.statusCode === 403) {
      if (
        error.error &&
        error.error.code === 1001 &&
        Helpers.getCookie('around-customer-token')
      ) {
        // If the error is 403 and code 1001 and there is a customer token cookie,
        // then it is the mailwall that failed
        this.setState({
          authStatus: {
            code: !this.authChecked ? 200 : 401,
            state: 'default',
            message: 'Please make sure you have entered the correct code'
          },
          authRequired: true,
          accessLevel: 1
        });
      }
    }

    window.console.log(error);
  };

  createRenderer = (target) => {
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(
      75,
      this.state.width / this.state.height,
      1,
      2000
    );
    this.camera.position.set(0, 0, 0);
    this.camera.up.set(0, 1, 0);
    this.scene.add(this.camera);

    // Create the render
    this.renderer = new THREE.WebGLRenderer({
      canvas: target,
      antialias: true
    });

    // Applying the device pixel ratio if available => this prevents blurry viewers on retina
    if (typeof this.renderer.setPixelRatio === 'function') {
      this.renderer.setPixelRatio(
        window.devicePixelRatio ? window.devicePixelRatio : 1
      );
    }

    // Set the size
    this.renderer.setSize(this.state.width, this.state.height);

    // Setup controls
    // TODO - Add dom element to limit movement
    // this.controls = new THREE.OrbitControls(this.camera, target);
    // The dolly has to be a PerspectiveCamera, as opposed
    // to a simple Object3D, since that's what
    // OrbitControls expects.
    this.dollyCam = new THREE.PerspectiveCamera(
      75,
      this.state.width / this.state.height,
      1,
      2000
    );
    this.dollyCam.position.set(0, 0, 1);
    this.dollyCam.up.set(0, 1, 0);
    this.scene.add(this.dollyCam);

    this.orbitControls = new THREE.OrbitControls(this.dollyCam, target);
    this.orbitControls.enableDamping = true;
    this.orbitControls.noZoom = true;
    this.orbitControls.dampingFactor = 0.25;

    if (this.state.isMobile || this.state.hasGyro) {
      this.DeviceOrientationControls = new THREE.DeviceOrientationControls(
        this.camera
      );
    }

    this.renderer.xr.enabled = true;
    this.renderer.setAnimationLoop(this.renderThree);

    this.renderer.render(this.scene, this.camera);
    this.camera.updateProjectionMatrix();

    this.setState({ rendererLoaded: true });
    this.tapListener = new TapListener(target, this.dollyCam);
  };

  renderThree = (timeSinceStart, shouldUpdate) => {
    const { appState } = this.props;
    this.frameRate = 90;

    const deltaTime = timeSinceStart - this.time;
    this.time = timeSinceStart;
    this.tick += deltaTime;

    if (this.tick >= 1000 / this.frameRate) {
      // check if we need to update gyro or normal rendering
      // We dont need to check the VR mode, because that is handles by the polyfill
      if (isGyroViewMode(appState.viewMode)) {
        this.DeviceOrientationControls.update();
        this.renderer.render(this.scene, this.camera);
      } else {
        this.orbitControls.update();
        this.renderer.render(this.scene, this.dollyCam);
      }

      this.tick = 0;
    }
    this.setState({ deltaTime });
  };

  onXRStart = () => {
    const { appStateDispatch } = this.props;
    appStateDispatch(changeViewModeAction(ViewModes.vr));
    this.forceUpdate();
  };
  onXREnd = () => {
    const { appStateDispatch } = this.props;
    appStateDispatch(changeViewModeAction(ViewModes.normal));
    this.forceUpdate();
  };
  onInfoPointClick = (infoSpotObjectId) => {
    this.setState({
      activeInfoPointId: infoSpotObjectId
    });
  };

  onCloseInfoPointModal = () => {
    this.setState({
      activeInfoPointId: null
    });
  };

  onSpotPointClick = (spotPointObjectId) => {
    this.setState({ spotFading: true, fade: 'out' });
    // this.renderLoop.kick();

    this.onSpotClickedCallback = () => {
      ViewerSDK.setCurrentSpot(spotPointObjectId);
    };
  };

  onSpotChange = () => {
    if (this.state.currentSpot && this.orbitControls) {
      this.setState({ fade: 'in' });
      // Check if you came from a point
      if (this.state.previousSpot) {
        // get the reverse spot link of point you came from
        ViewerSDK.getSpotLinkWithReferenceInCurrentSpot(
          this.state.previousSpot.objectId
        )
          .then((reverseSpotLink) => {
            lookAtPoint(
              {
                x: reverseSpotLink.point[0],
                y: reverseSpotLink.point[1],
                z: reverseSpotLink.point[2]
              },
              this.orbitControls
            );

            // Check if a start position was added for this point
            rotateToStartPos(this.state.currentSpot, this.orbitControls);
          })
          .catch(() => {
            // window.console.log(error);
            // no moving through the tour
            // This can be from using the gallery or the floorplan
          });

        if (this.dollyCam) {
          this.dollyCam.zoom = 1;
          this.dollyCam.updateProjectionMatrix();
        }
        // this.renderLoop.keepAlive = true;
        this.analytics.spotChanged(this.state.currentSpot.objectId);
      }
      // Check if a start position was added for this point
      rotateToStartPos(this.state.currentSpot, this.orbitControls);
    }
  };

  /**
   * Callback triggered when the fadePlane is done fading
   * @param {string} fadeDirection - can be 'out' of 'in'
   */
  onSpotFade = (fadeDirection) => {
    if (fadeDirection === 'out') {
      this.onSpotClickedCallback && this.onSpotClickedCallback();
    } else if (fadeDirection === 'in') {
      this.setState({ spotFading: false });
      // this.renderLoop.keepAlive = false;
    }
  };

  onResize = () => {
    const { appState } = this.props;

    // Disable the tapListener if the viewmode is VR
    if (this.tapListener) {
      this.tapListener.disabled = isVRViewMode(appState.viewMode);
    }

    this.updateSize(() => {
      if (this.renderer) {
        this.renderer.setSize(this.state.width, this.state.height);

        // Set Aspect
        this.camera.aspect = this.state.width / this.state.height;
        this.dollyCam.aspect = this.state.width / this.state.height;

        // Limit and normalize the camera aspect values. Then flip them.
        const ratioLimits = { upper: 3, lower: 0 };
        const limRatio = Math.max(
          Math.min(this.camera.aspect, ratioLimits.upper),
          ratioLimits.lower
        );
        const normLimRatio = limRatio / ratioLimits.upper;

        // Flip for factor
        const FOVfactor = 1 - normLimRatio + 1;
        const relFOV = 60 * FOVfactor;

        // Apply To Cameras
        this.camera.fov = Math.min(Math.max(relFOV, 45), 90);
        this.camera.updateProjectionMatrix();

        this.dollyCam.fov = Math.min(Math.max(relFOV, 45), 90);
        this.dollyCam.updateProjectionMatrix();

        // this.renderLoop.kick();
      }
    });
  };

  updateSize = (callback) => {
    let width = 100;
    let height = 100;
    if (this.container) {
      width = this.container.clientWidth;
      height = this.container.clientHeight;
    }
    this.setState({ width, height }, () => callback && callback());
  };

  onSphereLoaded = () => {
    this.setState({
      authStatus: { code: 200, state: 'default' },
      loaded: true
    });
    this.onResize();

    if (this.state.isMobile || this.state.hasGyro) {
      this.tapListener.attach(() => {
        this.setState(
          {
            naked: !this.state.naked,
            showNakedWarning: !this.state.naked
          },
          () => {
            setTimeout(() => {
              this.setState({
                showNakedWarning: false
              });
            }, 6000);
          }
        );
      });
    }
  };

  onVRTexturesLoad = () => {
    this.setState({ VRTexLoaded: true });
  };

  onExitFullscreen = () => {
    if (this.state.isMobile) {
      this.setState({ requireHomeViewButton: true });
    }
  };

  getMaxAnisotropy = () => {
    let ani = 1;
    if (
      this.renderer &&
      typeof this.renderer.capabilities.getMaxAnisotropy === 'function'
    ) {
      ani = this.renderer.capabilities.getMaxAnisotropy();
    }

    return ani;
  };

  onHomeViewButtonClicked = () => {
    if (typeof DeviceOrientationEvent.requestPermission === 'function') {
      // iOS 13+
      DeviceOrientationEvent.requestPermission().then((response) => {
        if (response == 'granted') {
          this.setState({ motionGranted: true });
        }
      });
    }
    this.setState({ requireHomeViewButton: false });
  };

  render() {
    const { appState } = this.props;

    const activeCamera = isGyroViewMode(appState.viewMode)
      ? this.camera
      : this.dollyCam;

    let sphere;
    let fadePlane;
    let spotLinksContainer;
    let logo;
    let versionLog;
    let UI;
    let tutorial;
    let loader;
    let tutorialVisible;
    let cookieWarning;
    let nakedWarning;

    if (!this.state.loaded) {
      loader = (
        <Loader
          accessLevel={this.state.accessLevel}
          mailWallPhoneNumberMandatory={this.state.mailWallPhoneNumberMandatory}
          albumCode={this.state.metaData.albumCode}
          analytics={this.analytics}
          authStatus={this.state.authStatus}
          authRequired={this.state.authRequired}
          coverImageDataUrl={this.state.coverImageDataUrl}
          isPublished={this.state.isPublished}
          isNotFound={this.state.isNotFound}
          localizer={this.localizer}
          loaderActive={this.state.metaData.loaderLogoActive}
          onAuthenticate={this.loadData}
          size={{
            width: this.state.width,
            height: this.state.height
          }}
        />
      );
    }

    if (this.state.rendererLoaded && this.state.currentSpot) {
      this.orbitControls.autoRotate = this.state.autoRotation;

      sphere = (
        <Sphere
          currentSpot={this.state.currentSpot}
          isMobile={this.state.isMobile}
          maxAni={this.getMaxAnisotropy()}
          onLoaded={this.onSphereLoaded}
          parts="18"
          scene={this.scene}
          sphereDiameter={this.state.sphereDiameter}
          // renderLoop={this.renderLoop}
        />
      );

      if (this.state.spotFading) {
        fadePlane = (
          <FadePlane
            deltaTime={this.state.deltaTime}
            camera={activeCamera}
            fade={this.state.fade}
            onFaded={this.onSpotFade}
          />
        );
      }

      if (this.state.styling) {
        logo = (
          <BottomLogo
            albumData={this.state.albumData}
            camera={activeCamera}
            scene={this.scene}
            sphereDiameter={this.state.sphereDiameter}
            styling={this.state.styling}
          />
        );
      }

      if (
        !this.state.firstMoved &&
        this.state.loaded &&
        !this.state.requireHomeViewButton
      ) {
        tutorialVisible = true;
        tutorial = (
          <Tutorial
            isMobile={this.state.isMobile}
            localizer={this.localizer}
            autoRotate={this.state.autoRotation}
          />
        );
      }

      if (
        !this.state.spotFading &&
        !this.state.requireHomeViewButton &&
        !tutorialVisible &&
        !this.state.naked
      ) {
        spotLinksContainer = (
          <SpotLinksContainer
            albumData={this.state.albumData}
            camera={activeCamera}
            currentSpot={this.state.currentSpot}
            isMobile={this.state.isMobile}
            loaded={this.state.loaded}
            onInfoPointClick={this.onInfoPointClick}
            onSpotPointClick={this.onSpotPointClick}
            onVRTexturesLoad={this.onVRTexturesLoad}
            scene={this.scene}
            view={this.renderer.domElement}
            WebVR={this.WebVR}
          />
        );
      }

      if (this.state.requireHomeViewButton) {
        UI = (
          <HomeViewButton
            onClick={() => {
              this.onHomeViewButtonClicked();
            }}
          />
        );
      } else if (!tutorialVisible && !this.state.naked) {
        UI = (
          <UIContainer
            renderer={this.renderer}
            activeInfoPointId={this.state.activeInfoPointId}
            albumData={this.state.albumData}
            analytics={this.analytics}
            callSpotChangeEvent={this.onSpotPointClick}
            camera={this.dollyCam}
            controls={this.orbitControls}
            currentSpot={this.state.currentSpot}
            features={this.state.features}
            isMobile={this.state.isMobile}
            loaded={this.state.loaded}
            localizer={this.localizer}
            onCloseInfoPointModal={this.onCloseInfoPointModal}
            onRenderRequire={() => {
              if (isGyroViewMode(appState.viewMode)) {
                this.DeviceOrientationControls.connect();
              }
              this.forceUpdate();
            }}
            size={{
              width: this.state.width,
              height: this.state.height
            }}
            scene={this.scene}
            startComponent={this.state.startComponent}
            styling={this.state.styling}
            widgetMaxHeight={this.state.height - 20} // Magic number 20 for excluding sidebar padding
          />
        );
      }
    }

    if (this.state.dev) {
      versionLog = (
        <div className="amav-version-log">
          {version}.{timestamp}
        </div>
      );
    }

    if (this.state.showCookieWarning) {
      cookieWarning = (
        <AFLCookieWarningHelper
          cookieWarningText={this.localizer.get('cookieWarningText')}
          cookieWarningOkButton={this.localizer.get('cookieWarningOK')}
        />
      );
    }

    if (this.state.showNakedWarning) {
      nakedWarning = (
        <div className="amav-naked-mode-warning-container">
          <div
            className={classnames('amav-naked-mode-warning-content-container', {
              'amav-naked-mode-warning-content-container--mobile':
                this.state.isMobile
            })}
          >
            {this.localizer.get(
              this.state.isMobile
                ? 'nakedWarningTextMobile'
                : 'nakedWarningTextDesktop'
            )}
          </div>
        </div>
      );
    }

    return (
      <Measure bounds onResize={this.onResize}>
        {({ measureRef }) => (
          <div
            id="viewer-container"
            ref={measureRef}
            className={classnames('amav', {
              'amav-fullscreen': FullScreen.isFullscreen
            })}
          >
            {cookieWarning}
            {loader}
            {tutorial}
            {sphere}
            {fadePlane}
            {logo}
            <CSSTransitionGroup
              transitionName="fade"
              transitionEnterTimeout={300}
              transitionLeaveTimeout={300}
            >
              {spotLinksContainer}
              {UI}
              {nakedWarning}
            </CSSTransitionGroup>
            <XRPresentingHelper
              renderer={this.renderer}
              onLaunch={this.onXRStart}
              onEnd={this.onXREnd}
            />
            <VrSplash
              coverImageDataUrl={this.state.coverImageDataUrl}
              size={{
                width: this.state.width,
                height: this.state.height
              }}
            />
            <WindowSizeListener
              onResize={() => {
                setTimeout(() => {
                  this.onResize();
                }, 200);
              }}
            />
            <canvas
              id={this.state.renderTargetName}
              className="render-canvas"
              ref={(canvas) => {
                this.canvasTarget = canvas;
              }}
            />
            {versionLog}
          </div>
        )}
      </Measure>
    );
  }
}

export default withMiniStore(Viewer);
