// Imports
// Base

// Exports
export default class FontLoader {
    /**
     * Attach a Font
     * @param {string} url
     * @param {string} id
     */
    static loadFont(url, id) {
        const link = document.createElement('link');
        link.id = id;
        link.rel = 'stylesheet';
        link.href = url;
        document.head.appendChild(link);
    }

    /**
     * remove a font by ID
     * @param {string} id
     */
    static removeFont(id) {
        const fontLink = document.getElementById(id);
        fontLink.parentNode.removeChild(fontLink);
    }
}
