import { getQueryParameter } from '@aroundmedia/frontend-library/lib/shared/helpers/General';
import { MakePoint3D } from './types-helper';

/**
 * Look at a point in 3D
 * @param {Point3D} pointToLookAt
 * @param controls
 */
export const lookAtPoint = (pointToLookAt, controls) => {
  const _pointToLookAt = pointToLookAt;

  // Flip some stuff, because, reasons
  _pointToLookAt.x *= -1;
  _pointToLookAt.z *= -1;

  // calculate angles
  const rotationAngleY = Math.asin(-_pointToLookAt.y, -1);
  const rotationAngleXZ = Math.atan2(-_pointToLookAt.x, -_pointToLookAt.z);

  if (rotationAngleY && rotationAngleXZ) {
    controls.reset();
    controls.rotateUp(rotationAngleY * 2); // phi
    controls.rotateLeft(rotationAngleXZ * 2); // theta
  }
};

/**
 * orientCameraInTour
 * @param {object} positionObject - The object that orients us in the tour.
 * @param {Array} positionObject.lookAtPoint - 3d point to look at.
 * @param {string} positionObject.objectId - objectId of the point to go to.
 * @param {ViewerSDK} ViewerSDK - SDK For Viewer
 * @param {THREE.OrbitControls} controls - camera controls to be moved
 */
export const orientCameraInTour = (positionObject, ViewerSDK, controls) => {
  const pointToLookAt = MakePoint3D(positionObject.lookAtPoint);

  // Check if the spot is already correct or needs changing
  if (positionObject.objectId) {
    // Change The Spot and look at point
    ViewerSDK.setCurrentSpot(positionObject.objectId).then(() => {
      lookAtPoint(pointToLookAt, controls);
    });
  } else {
    // no need to change, so just turn the camera
    lookAtPoint(pointToLookAt, controls);
  }
};

/**
 * Rotate the camera to the start rotation of the spot
 * @param spot
 * @param controls
 */
export const rotateToStartPos = (spot, controls) => {
  // If the spot has a startRotation
  // OR there is a startlocation in the URL parameter,
  // use the startrotation (or 0 if undefined),
  // else, ignore it and don't rotate

  // Get the Point from the URL
  const URLSpotPoint = getQueryParameter('spotPoint') || '';
  const URLSpotPointArray = URLSpotPoint.split(',');
  if (spot.startRotation || URLSpotPoint) {
    const startRot = {
      x: parseFloat(URLSpotPointArray[0]) || spot.startRotation[0] || 0,
      y: parseFloat(URLSpotPointArray[1]) || spot.startRotation[1] || 0,
      z: parseFloat(URLSpotPointArray[2]) || spot.startRotation[2] || 0
    };

    lookAtPoint(startRot, controls);
    controls.reset();
  }
};
