/**
 * @typedef {Object} Point3D
 * @property {number} x - The X Coordinate
 * @property {number} y - The Y Coordinate
 * @property {number} z - The Y Coordinate
 */

/**
 * MakePoint3D
 * @param {Array} pointArray
 * @return {Point3D} point3d
 */
export const MakePoint3D = (pointArray) => {
    const point3d = {
        x: 0,
        y: 0,
        z: 0
    };

    if (pointArray && pointArray.length === 3) {
        point3d.x = pointArray[0];
        point3d.y = pointArray[1];
        point3d.z = pointArray[2];
    } else {
        throw new Error('can\'t create a point with an empty or inconguent array');
    }

    return point3d;
};
