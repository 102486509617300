export default class BrowserHelper {
    static lowerThanAndroidVersion = function lowerThanAndroidVersion(
        testVersion
    ) {
        const useragent = navigator.userAgent;
        const androidpoint = useragent.indexOf('Android');
        if (androidpoint >= 0) {
            const rest = useragent.substring(
                androidpoint + 8,
                useragent.length
            );
            const version = rest.substring(0, rest.indexOf(';'));
            return version < testVersion;
        }

        return true;
    };

    static getBrowserInfo = function getBrowserInfo() {
        const ua = navigator.userAgent.toLowerCase();

        // @see https://gist.github.com/padolsey/527683
        const ie = () => {
            let v = 3;
            const div = document.createElement('div');
            const all = div.getElementsByTagName('i');

            while (
                // eslint-disable-line
                ((div.innerHTML = `<!--[if gt IE ${++v}]><i></i><![endif]-->`), // eslint-disable-line
                all[0])
            ) {
                // Nothing to see
            }

            return v > 4 ? v : false;
        };

        const android = ua.indexOf('android') !== -1;
        const chrome = /\bchrome\b/.test(ua);
        const ie7 = ie === 7;
        const ie8 = ie === 8;
        const ie9 = ie === 9;
        const ie10 = ua.indexOf('MSIE 10.0') !== -1;
        const ie11 = ua.indexOf('Trident/7.0; rv:11.0') !== -1;
        const ipad = ua.match(/iPad/i) !== null;
        const newios =
            ua.indexOf('iphone os 6') !== -1 ||
            ua.indexOf('iphone os 7') !== -1 ||
            ua.indexOf('iphone os 8') !== -1 ||
            ua.indexOf('iphone os 9') !== -1 ||
            ua.indexOf('iphone os 10') !== -1;
        const oldios =
            ua.indexOf('iphone os') !== -1 && ua.indexOf('iphone os 5') === -1;
        const safari = !chrome && /safari/.test(ua);
        const safari3 = safari && /version\/3/.test(ua);
        const safari4 = safari && /version\/4/.test(ua);
        const safari5 = safari && /version\/5/.test(ua);
        const oldsafari = safari3 || safari4 || safari5;
        const oldandroid = this.lowerThanAndroidVersion('5.0');

        return {
            android,
            oldandroid,
            chrome,
            ie,
            ie7,
            ie8,
            ie9,
            ie10,
            ie11,
            ipad,
            newios,
            oldios,
            ios: newios || oldios || ipad,
            safari,
            safari3,
            safari4,
            safari5,
            oldsafari
        };
    };

    static applyOldClasses = function applyOldClasses() {
        const info = this.getBrowserInfo();

        // we need a css fix for old safari, append class to the root element
        if (info.oldsafari && !info.ipad && !info.oldios) {
            document.documentElement.className += ' old-safari';
        }

        // we need a css fix for old android, append class to the root element
        if (info.oldandroid) {
            document.documentElement.className += ' old-android';
        }
    };

    static checkMobile = function checkMobile() {
        const ua = navigator.userAgent;
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            ua
        );
    };
    static hasGyro = function hasGyro() {
        return window.DeviceOrientationEvent && 'ontouchstart' in window;
    };

    static isAndroid = function isAndroid() {
        const ua = navigator.userAgent;
        return /Android/i.test(ua);
    };

    static isIOS = function isIos() {
        const ua = navigator.userAgent;
        return (
            /iPhone|iPad|iPod/.test(ua) &&
            /OS [1-9]_[0-9_]* like Mac OS X/i.test(navigator.userAgent) &&
            ua.indexOf('AppleWebKit') > -1
        );
    };
}
