/* ============================================================================
   * Creating a new Type:
   * ---
   * add an object to the UIComponentTypes
   * set the name to the same as the key for the object
   * choose a default state: sidebar/expanded
   * choose modal mode: this puts the class on the widget - TODO Refactor this
   * choose hasBackground whether it has a blue background (blocks UI)
   ============================================================================ */

/**
 * Exports a collection of types
 * @type {{gallery: {name: string, defaultState: string}, leftMenuBar: {name: string, defaultState: string}}}
 */
export const UIComponentTypes = {
    gallery: {
        name: 'gallery',
        defaultState: 'sidebar',
        isModal: false,
        hasBackground: false
    },
    floorPlan: {
        name: 'floorPlan',
        defaultState: 'sidebar',
        isModal: false,
        hasBackground: false
    },
    infoPoint: {
        name: 'infoPoint',
        defaultState: 'sidebar',
        isModal: true,
        hasBackground: false
    },
    leftMenuBar: {
        name: 'leftMenuBar',
        defaultState: 'sidebar',
        isModal: false,
        hasBackground: false
    },
    chat: {
        name: 'chat',
        defaultState: 'sidebar',
        isModal: false,
        hasBackground: false
    }
};

/**
 * This returns the componentType for a given name
 * @param typeName
 */
export const ComponentForType = (typeName) => {
    let type = null;

    Object.keys(UIComponentTypes).forEach((i) => {
        if (i === typeName) {
            type = UIComponentTypes[i];
        }
    });

    return type || new Error('This type does not exist');
};
