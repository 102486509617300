/* eslint-disable max-len */
// Base
import React from 'react';
import { shape, string } from 'prop-types';
import AFLImageBlur from '@aroundmedia/frontend-library/lib/shared/components/images/AFLImageBlur';
import AFLButton from '@aroundmedia/frontend-library/lib/shared/components/buttons/AFLButton';
import classNames from 'classnames';

const VrSplash = ({ size, WebVR, coverImageDataUrl }) => {
  const shouldRender = () => {
    if (WebVR && WebVR.tutorialNode) {
      WebVR.setZIndexOfTutorial(0);
      return WebVR.tutorialNode.style.display === 'block';
    }

    return false;
  };

  if (!shouldRender()) {
    return false;
  }

  // Build the button to get a cardboard,
  // Hide this if the height is less than 400 px
  let getCardBoard;
  if (size && size.height > 400) {
    getCardBoard = (
      <div className="amav-vr-splash__getCardboard">
        <p className="amav-vr-splash__getCardboard__text">
          {"Don't have one?"}
        </p>
        <a
          className="amav-vr-splash__getCardboard__link"
          href="https://vr.google.com/cardboard/get-cardboard/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AFLButton className="amav-vr-splash__getCardboard__button">
            <span className="button__title">Get one here</span>
          </AFLButton>
        </a>
      </div>
    );
  }

  // Build the content of the view
  const container = (
    <div className="amav-vr-splash">
      <div className="amav-vr-splash__content">
        <VrSplashCardboardIcon />
        <p>
          Place your phone into <br />
          the cardboard viewer.
        </p>
        {getCardBoard}
        <div className="amav-vr-spash__content__back">
          <a
            rel="noopener noreferrer"
            onClick={() => {
              WebVR.exitVR();
            }}
          >
            Exit VR
          </a>
        </div>
      </div>
    </div>
  );
  // Build content, wrap in blurcomponent to show the coverimage
  // If it has a coverimage provided
  let content = container;
  if (coverImageDataUrl) {
    content = (
      <AFLImageBlur blurRadius={50} img={coverImageDataUrl}>
        {container}
      </AFLImageBlur>
    );
  }

  return (
    <div
      className={classNames('amav-vr-splash-container', {
        'has-image': coverImageDataUrl
      })}
    >
      {content}
    </div>
  );
};

VrSplash.propTypes = {
  size: shape({}).isRequired,
  WebVR: shape({}),
  coverImageDataUrl: string
};

VrSplash.defaultProps = {
  coverImageDataUrl: '',
  WebVR: null
};

export default VrSplash;

export const VrSplashCardboardIcon = () => (
  <div className="amav-vr-splash__icon">
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 431.63 600"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            d="M404.33,349.46H27.28C14,349.46,0,362.69,0,374.26V578.5C0,593.38,14,600,30.58,600H145.53c9.08,0,19.23-8.07,22.74-19.85,9.33-31.34,16.12-76.08,47.55-76.08s38.22,44.74,47.55,76.08C266.86,591.93,277,600,286.09,600H401c16.53,0,30.6-6.62,30.6-21.5V374.26c0-11.57-14.07-24.8-27.3-24.8m-289,174.87a49.61,49.61,0,1,1,49.62-49.62,49.6,49.6,0,0,1-49.62,49.62m201,0a49.61,49.61,0,1,1,49.62-49.62,49.61,49.61,0,0,1-49.62,49.62"
            style={{ clipRule: 'evenodd' }}
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <rect x="-6042.86" y="-8311.98" width="7510.42" height="20142.88" />
        </clipPath>
        <clipPath id="clip-path-3">
          <polygon
            points="211.38 277.92 211.38 225.42 220.25 225.42 220.25 277.92 234.65 263.53 240.93 269.81 215.82 294.92 212.68 291.78 190.71 269.81 196.99 263.53 211.38 277.92"
            style={{ clipRule: 'evenodd' }}
          />
        </clipPath>
        <clipPath id="clip-path-4">
          <rect x="171.43" y="216.55" width="88.77" height="88.77" />
        </clipPath>
      </defs>
      <path
        d="M404.33,349.46H27.28C14,349.46,0,362.69,0,374.26V578.5C0,593.38,14,600,30.58,600H145.53c9.08,0,19.23-8.07,22.74-19.85,9.33-31.34,16.12-76.08,47.55-76.08s38.22,44.74,47.55,76.08C266.86,591.93,277,600,286.09,600H401c16.53,0,30.6-6.62,30.6-21.5V374.26c0-11.57-14.07-24.8-27.3-24.8m-289,174.87a49.61,49.61,0,1,1,49.62-49.62,49.6,49.6,0,0,1-49.62,49.62m201,0a49.61,49.61,0,1,1,49.62-49.62,49.61,49.61,0,0,1-49.62,49.62"
        style={{ fillRule: 'evenodd' }}
      />
      <g style={{ clipPath: 'url(#clip-path)' }}>
        <rect x="-6042.86" y="-8311.98" width="7510.42" height="20142.88" />
        <g style={{ clipPath: 'url(#clip-path-2)' }}>
          <rect x="-143.88" y="205.58" width="719.39" height="538.3" />
        </g>
      </g>
      <path d="M389.5,170.88H42.13a5.12,5.12,0,0,1-5.1-5.1V5.1A5.12,5.12,0,0,1,42.13,0H389.5a5.12,5.12,0,0,1,5.1,5.1V165.78A5.12,5.12,0,0,1,389.5,170.88Zm-39.19-161h-264V161h264Zm25.19,97.42V63.6a1.17,1.17,0,0,0-1.17-1.17H369a1.17,1.17,0,0,0-1.17,1.17v43.67a1.17,1.17,0,0,0,1.17,1.17h5.33A1.17,1.17,0,0,0,375.5,107.28ZM62.77,69A16.43,16.43,0,1,0,79.2,85.44,16.43,16.43,0,0,0,62.77,69Z" />
      <polygon
        points="211.38 277.92 211.38 225.42 220.25 225.42 220.25 277.92 234.65 263.53 240.93 269.81 215.82 294.92 212.68 291.78 190.71 269.81 196.99 263.53 211.38 277.92"
        style={{ fillRule: 'evenodd' }}
      />
      <g style={{ clipPath: 'url(#clip-path-3)' }}>
        <rect x="171.43" y="216.55" width="88.77" height="88.77" />
        <g style={{ clipPath: 'url(#clip-path-4)' }}>
          <rect x="168.52" y="203.23" width="94.6" height="113.88" />
        </g>
      </g>
    </svg>
  </div>
);

/* eslint-enable max-len */
