export default class SphereHelper {
    /* eslint-disable max-len */
    /**
     * Get the metaData for a Sphere Part
     * @param {Number} parts - total amount of parts
     * @param {Number} part - part to get the metadata from
     * @return {{spherePartUVOffset: {x: number, y: number}, segments: (*|number), phiStart: number, phiLength: number, thetaStart: number, thetaLength: number}}
     */
    /* eslint-enable max-len */
    static getSpherePartMetaData(parts, part) {
        const rows = Math.sqrt(parts / 2);
        const cols = rows * 2;

        const xStep = 1 / cols;
        const yStep = 1 / rows;

        const row = Math.floor(part / cols) + 1;
        const col = part % cols;

        return {
            spherePartUVOffset: {
                x: xStep * col,
                y: 1 - (yStep * row)
            },
            segments: Math.round(Math.sqrt(2048 / parts)),
            phiStart: (Math.PI * xStep * col * 2),
            phiLength: (Math.PI * (xStep * 2)),
            thetaStart: ((Math.PI / rows) * (row - 1)),
            thetaLength: (Math.PI / rows)
        };
    }

    /**
     * create the sphere material that goes on the sphere
     * @param {THREE.Texture} tex - the texture to be applied
     * @param {THREE} THREE - the threejs lib module
     * @param {Number} cols - amount of columns
     * @param {Number} rows - amount of rows
     *
     * @returns {THREE.ShaderMaterial}
     */
    static createSphereMaterial(tex, THREE, cols, rows) {
        const uniforms = {
            uTex: { type: 't', value: tex },
            uBrightness: { type: 'f', value: 0.0 },
            uContrast: { type: 'f', value: 0.0 },
            uHue: { type: 'f', value: 0.0 },
            uSaturation: { type: 'f', value: 0.0 },
            uWarmth: { type: 'f', value: 0.0 },
            uUVOffset: { type: 'v2', value: new THREE.Vector2(0.0, 0.0) },
            uUVParts: { type: 'v2', value: new THREE.Vector2(cols, rows) }
        };

        return new THREE.ShaderMaterial({
            uniforms,
            vertexShader: document.getElementById('vShader').textContent,
            fragmentShader: document.getElementById('fShader').textContent
        });
    }

    static rowsForParts(parts) {
        return Math.sqrt(parts / 2);
    }

    static colsForParts(parts) {
        return (this.rowsForParts(parts) * 2);
    }

}
