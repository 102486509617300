export default class TapListener {
    /**
     * @param {canvas} domElement
     * @param {THREE.PerspectiveCamera} camera
     */
    constructor(domElement, camera) {
        this._domElement = domElement;
        this._camera = camera;
        this._moveDelta = 0.003;
        this._disabled = false;
    }

    /**
     * attach the tap listener to the domElement so it fires
     * @param {function} func - function to be called when tapped
     */
    attach = func => {
        this._domElement.addEventListener('mousedown', this.onDown, false);
        this._domElement.addEventListener('mouseup', this.onUp, false);

        this._domElement.addEventListener('touchstart', this.onDown, false);
        this._domElement.addEventListener('touchend', this.onUp, false);

        this.onTap = func;
    };

    /**
     * detach it from the domElement
     */
    detach = () => {
        this._domElement.removeEventListener('mousedown', this.onDown, false);
        this._domElement.removeEventListener('mouseup', this.onUp, false);

        this._domElement.removeEventListener('touchstart', this.onDown, false);
        this._domElement.removeEventListener('touchend', this.onUp, false);
    };

    onDown = e => {
        e.preventDefault();

        this.startPos = {
            x: this._camera.position.x,
            y: this._camera.position.y,
            z: this._camera.position.z
        };
    };

    onUp = e => {
        e.preventDefault();

        setTimeout(() => {
            this.endPos = {
                x: this._camera.position.x,
                y: this._camera.position.y,
                z: this._camera.position.z
            };

            // If the position is the same the camera has not moved, do your click actions here
            if (!this.checkDiff()) {
                if (this._onTap && !this._disabled) {
                    this._onTap();
                }
            }

            // Reset Values
            this.startPos = {};
            this.endPos = {};
        }, 50);
    };

    checkDiff = () => {
        const xDiff =
            Math.abs(this.endPos.x) >
                Math.abs(this.startPos.x) + this._moveDelta ||
            Math.abs(this.endPos.x) <
                Math.abs(this.startPos.x) - this._moveDelta;
        // const yDiff = this.endPos.y > this.startPos.y + this._moveDelta && this.endPos.y < this.startPos.y - this._moveDelta;
        // const zDiff = this.endPos.z > this.startPos.z + this._moveDelta && this.endPos.z < this.startPos.z - this._moveDelta;

        return xDiff;
    };

    /**
     * Action to fire when tapped
     * @param {function} func
     */
    set onTap(func) {
        this._onTap = func;
    }

    /**
     * Set the disabled state
     * @param {boolean} disabled
     */
    set disabled(disabled) {
        this._disabled = disabled;
    }
}
