// Base
import React from 'react';
import classnames from 'classnames';
import Measure from 'react-measure';
import PropTypes from 'prop-types';

// Lodash
import keyBy from 'lodash.keyby';
import reject from 'lodash.reject';
import isNil from 'lodash.isnil';
import forEach from 'lodash.foreach';

// SFL
import BaseComponent from '@aroundmedia/frontend-library/lib/shared/basecomponent/BaseComponent';
import { AFLValidator } from '@aroundmedia/frontend-library/lib/shared/helpers/AFLValidator';
import { getQueryParameter } from '@aroundmedia/frontend-library/lib/shared/helpers/General';
import { Notifier } from '@aroundmedia/frontend-library/lib/shared/Notifier';

// AFL - UI Components
import AFLForm from '@aroundmedia/frontend-library/lib/shared/components/forms/AFLForm';
import AFLContentLoader from '@aroundmedia/frontend-library/lib/shared/components/loaders/AFLContentLoader';

// import ENV from '../../../config/active-environment/env.json';
import TwilioManager from '../../../helpers/twilio-helper';
import UIWidget from './UIWidget';

import { findPoint3D, flipPoint3D } from '../../../helpers/helpers';
import { lookAtPoint } from '../../../helpers/camera-helper';

/**
 * UIGallery Widget Component
 */
export default class UIChatWidget extends BaseComponent {
  constructor(props) {
    super(props);

    this.chatData = {
      fields: {
        chat_firstName: {
          fieldType: 'input',
          type: 'text',
          label: 'First name',
          value: '',
          status: 0,
          size: 2
        },

        chat_lastName: {
          fieldType: 'input',
          type: 'text',
          label: 'Last name',
          value: '',
          status: 0,
          size: 2
        },

        spacer_1: {
          fieldType: 'field__spacer',
          includeMargin: true,
          size: 1
        },

        chat_email: {
          fieldType: 'input',
          type: 'email',
          label: 'Email',
          value: '',
          status: 0,
          size: 1
        },

        chat_phone: {
          fieldType: 'input',
          type: 'text',
          label: 'Phone',
          value: '',
          status: 0,
          size: 1
        }
      },
      rules: {
        chat_firstName: {
          required: true
        },
        chat_lastName: {
          required: true
        },
        chat_email: {
          email: true,
          required: true
        }
      },
      valid: false
    };

    // TODO - enable as prop too
    this.title = this.props.localizer.get('chat');

    this.twilioManager = TwilioManager;
    this.twilioManager.onMessageAddCallback = this.onMessageAdded;

    let chatHasStarted = false;
    let chatStatus = { code: 200, state: 'default' };
    if (
      this.twilioManager.reconnecting ||
      this.twilioManager.reconnected ||
      this.twilioManager.channelJoined
    ) {
      // TODO - SET LOADING

      // Setting the chat to started so the messages can be loaded
      chatHasStarted = true;

      if (this.twilioManager.reconnected || this.twilioManager.channelJoined) {
        chatStatus = { code: 200, state: 'default' };
      } else {
        chatStatus = { code: 0, state: 'default' };
      }
    }

    this.state = {
      chatStatus,
      chatHasStarted,
      ...props
    };

    this.messageReplyBoxRef = null;
    this.canvasRef = document.getElementById('am-viewer-render-target');

    this.props.analytics.sendTrackOnChatOpened();

    Notifier.subscribe('chat.loaded', () => {
      if (this.state.chatStatus.code !== 200) {
        this.setState({ chatStatus: { code: 200, state: 'default' } });
      }

      setTimeout(() => {
        Notifier.publish('chat.scrolldown');
      }, 250);
    });

    Notifier.subscribe('chat.scrolldown', () => {
      if (
        this.messageDisplayBoxRef &&
        this.messageDisplayBoxRef.contentWrapper
      ) {
        this.messageDisplayBoxRef.contentWrapper.scrollTop = this.messageDisplayBoxRef.contentWrapper.scrollHeight;
      }
    });
  }

  // === SFL CALLS ===

  // === CALLBACKS ===
  onLocationSelectHandler = () => {
    if (this.state.savedStartLocationId) {
      this.twilioManager.clearLocationWithId(this.state.savedStartLocationId);
      this.setState({ savedStartLocationId: null });
      this.props.onRenderRequire && this.props.onRenderRequire();
    } else {
      // TODO - If a location is selected (new chat) - highlight icon (.reply__action-active)
      this.canvasRef.addEventListener('click', this.onCanvasClick);
      this.setState({ addLocationActive: true });
    }
  };

  onCanvasClick = (e) => {
    const point3D = findPoint3D(
      e,
      this.canvasRef,
      this.props.scene,
      this.props.camera,
      THREE
    );
    const savedLocationId = this.twilioManager.addLocation(
      this.props.currentSpot.objectId,
      flipPoint3D(point3D)
    );

    const newState = { addLocationActive: false };
    if (!this.state.chatHasStarted) {
      newState.savedStartLocationId = savedLocationId;
    } else {
      this.onMessageSend(null, true, savedLocationId);
    }

    this.setState(newState);
    this.canvasRef.removeEventListener('click', this.onCanvasClick);
  };

  onRemoveLocation = () => {
    this.setState({ addLocationActive: false });
  };

  onSendReplyHandler = () => {
    if (this.messageReplyBoxRef.textArea.value) {
      if (!this.state.chatHasStarted) {
        this.onChatStart();
      } else {
        this.onMessageSend(this.messageReplyBoxRef.textArea.value, false, null);
        this.messageReplyBoxRef.textArea.value = '';
      }
    } else {
      // TODO - ERROR HANDLING
    }
  };

  onFieldChange = (ref, value) => {
    this.chatData.fields[ref].value = value;
    this.setState({ lastActionAt: Date.now() });
  };

  onMessageAdded = () => {
    this.forceUpdate();
    Notifier.publish('chat.scrolldown');
  };

  onMessageSend = (value, isLocation, positionId) => {
    const location = this.twilioManager.getLocationWithId(positionId);

    let locationPoint = null;
    if (location && location.point) {
      locationPoint = location.point;
    }

    this.twilioManager.sendMessage(
      value || '',
      this.props.albumData.objectId,
      this.props.currentSpot.objectId,
      isLocation,
      locationPoint || this.props.camera.position,
      () => {
        this.twilioManager.clearLocationWithId(positionId);
        this.props.analytics.sendTrackOnChatMessageSent();
      }
    );
  };

  onChatStart = () => {
    AFLValidator.validate(this.chatData.fields, this.chatData.rules)
      .then((result) => {
        // Filter null or undefined values from result
        let tempValidatorResult = reject(result, isNil);

        // Check if result is empty, if so: form is valid
        this.chatData.valid =
          !tempValidatorResult || tempValidatorResult.length === 0;
        this.chatData.lastValidated = Date.now();

        // Convert field validator array to dictionary > Object {}
        tempValidatorResult = keyBy(tempValidatorResult, 'fieldKey');

        // Update all fields with new status and message
        forEach(this.chatData.fields, (fieldProps, key) => {
          // Update trialData fields with validator results
          this.chatData.fields[key].status = tempValidatorResult[key]
            ? tempValidatorResult[key].status
            : 200;
          this.chatData.fields[key].message = tempValidatorResult[key]
            ? tempValidatorResult[key].message
            : null;
        });

        // Update state with new values and status
        this.setState({ chatData: this.chatData }, () => {
          if (this.chatData.valid) {
            this.setState({ chatStatus: { code: 0, state: 'default' } }, () => {
              this.twilioManager.startCustomerTicket(
                this.chatData.fields.chat_email.value,
                this.props.albumData.objectId,
                this.createTicket
              );
            });
          }
        });
      })
      .catch((error) => {
        // TODO - Handle error
        window.console.log('--- error - validate error: %o', error);
      });
  };

  createTicket = (chatChannelName, chatChannelSid) => {
    const pos = this.props.camera.position;

    const spotPoint = [pos.x || 0, pos.y || 0, pos.z || 0];

    const params = {
      email: this.chatData.fields.chat_email.value,
      firstName: this.chatData.fields.chat_firstName.value,
      lastName: this.chatData.fields.chat_lastName.value,
      chatChannelSid,
      chatChannelName,
      albumObjectId: this.props.albumData.objectId,
      spotObjectId: this.props.currentSpot.objectId,
      point: spotPoint,
      viewerUrl: window.location.href
    };

    // if the phone has been added, send it along
    if (this.chatData.fields.chat_phone.value) {
      params.phoneNumber = this.chatData.fields.chat_phone.value;
    }

    this.twilioManager
      .createCustomerTicket(params, this.props.albumData.objectId)
      .then(() => {
        this.onMessageSend(this.messageReplyBoxRef.textArea.value, false, null);
        if (this.state.savedStartLocationId) {
          this.onMessageSend(null, true, this.state.savedStartLocationId);
        }
        this.messageReplyBoxRef.textArea.value = '';
        this.props.analytics.sendTrackOnChatStarted();

        this.setState({
          savedStartLocationId: null,
          chatHasStarted: true,
          chatStatus: { code: 200, state: 'default' }
        });
      })
      .catch((error) => {
        this.setState(
          {
            chatHasStarted: false,
            chatStatus: { code: 200, state: 'default' }
          },
          () => {
            setTimeout(() => {
              this.setState({
                chatStatus: { code: 400, state: 'default' }
              });
            }, 2500);
          }
        );

        window.console.log(error);
        // TODO - ERROR HANDLING: CREATING TICKET
      });
  };

  // === RENDER ===
  render() {
    const chatFields = this.chatData.fields;
    let chatDescription = this.props.localizer.get('CTMLeaveDetails');

    let chatContent = (
      <div className="chat__form">
        <AFLForm
          key="afl-form"
          fields={chatFields}
          onFieldChange={this.onFieldChange}
        />
      </div>
    );

    if (this.state.chatHasStarted) {
      chatDescription = this.props.localizer.get('CTMOkToLeaveChatMessage');

      const identity =
        this.chatData.fields.chat_email.value ||
        getQueryParameter('chatChannelIdentifier') ||
        this.twilioManager.chatIdentity;

      chatContent = (
        <MessagesDisplayBox
          identity={identity}
          username={`${this.chatData.fields.chat_firstName.value} ${this.chatData.fields.chat_lastName.value}`}
          messagesList={this.twilioManager.messages}
          localizer={this.props.localizer}
          currentSpot={this.props.currentSpot}
          controls={this.props.controls}
          onRenderRequire={this.props.onRenderRequire}
          callSpotChangeEvent={this.props.callSpotChangeEvent}
          ref={(msgDisplayBox) => {
            this.messageDisplayBoxRef = msgDisplayBox;
          }}
        />
      );
    }

    // We need to account for top padding of 60px on the .amav-widget__content wrapper.
    const formHeight = this.props.maxHeight - (this.state.replyBoxHeight || 0);

    const chatContentClass = {
      'amav-chat-content': true,
      'amav-chat-started-content': this.state.chatHasStarted
    };

    let contentLoader;
    if (this.state && this.state.chatStatus) {
      contentLoader = (
        <AFLContentLoader
          contentStatusCode={this.state.chatStatus.code}
          title="Starting chat"
        />
      );
    }

    return (
      <UIWidget
        size={this.props.size}
        {...this.props}
        typeModifier="amav-widget--chat"
        headerTitle={this.title}
        maxHeight={this.props.maxHeight}
      >
        <div
          className={classnames(chatContentClass)}
          style={{ maxHeight: formHeight }}
        >
          <div className="chat__description">
            <span className="description__text">{chatDescription}</span>
          </div>

          {chatContent}

          <div className="amav-clearfix" />
        </div>

        <Measure
          bounds
          onResize={(dimensions) => {
            this.setState({
              replyBoxHeight: dimensions.bounds.height || 0
            });
          }}
        >
          {({ measureRef }) => (
            <div ref={measureRef} className="amav-chat-reply-content">
              <div className="chat__reply">
                <MessageReplyBox
                  onLocationSelectHandler={this.onLocationSelectHandler}
                  onRemoveLocation={this.onRemoveLocation}
                  onSendReplyHandler={this.onSendReplyHandler}
                  addLocationActive={this.state.addLocationActive}
                  savedStartLocationId={this.state.savedStartLocationId}
                  ref={(msgReplyBox) => {
                    this.messageReplyBoxRef = msgReplyBox;
                  }}
                  localizer={this.props.localizer}
                />
              </div>
            </div>
          )}
        </Measure>

        {contentLoader}
      </UIWidget>
    );
  }
}

/**
 * Chat Widget Bottom Chat Box
 * @param onLocationSelectHandler
 * @param onRemoveLocation
 * @param onSendReply
 * @param addLocationActive
 */
class MessageReplyBox extends BaseComponent {
  constructor(props) {
    super(props);
    this.textArea = null;
  }

  onKeyPress = (e) => {
    if (e.charCode === 13) {
      if (!e.shiftKey) {
        e.preventDefault();
        this.props.onSendReplyHandler();
      }
    }
  };

  render() {
    let locationWrapper;
    if (this.props.addLocationActive) {
      locationWrapper = (
        <div
          className={classnames({
            'reply__location__added-wrapper': true
          })}
        >
          <div className="reply__location__added-text">
            <span>{this.props.localizer.get('CTMLocationChatMessage')}</span>
          </div>

          <div
            className="material-icons amav-icon amav-icon--close"
            onClick={this.props.onRemoveLocation}
          />
        </div>
      );
    }

    return (
      <div className="content__reply-wrapper">
        <textarea
          className="reply__textarea"
          ref={(textarea) => {
            this.textArea = textarea;
          }}
          placeholder={this.props.localizer.get('CTMFormtextAreaLabel')}
          onKeyPress={this.onKeyPress}
        />

        <div className="reply__actions">
          {/*
                     // TODO - If a location has been added, display following icon:
                     <div className="material-icons amav-icon amav-icon--location-edit" onClick={onLocationSelectHandler} />

                     // TODO - Clicking this icon lets the user select a new location to send.
                     // TODO - Only applies when a new chat is started, if it's a reply, location is send immediately
                     */}

          <div
            className={classnames(
              'material-icons amav-icon amav-icon--location',
              {
                'reply__action-active': this.props.savedStartLocationId
              }
            )}
            onClick={this.props.onLocationSelectHandler}
          />
          <div
            className="material-icons amav-icon amav-icon--send-reply"
            onClick={this.props.onSendReplyHandler}
          />
        </div>

        {locationWrapper}
      </div>
    );
  }
}

MessageReplyBox.propTypes = {
  onLocationSelectHandler: PropTypes.func.isRequired,
  onRemoveLocation: PropTypes.func.isRequired,
  onSendReplyHandler: PropTypes.func.isRequired,
  addLocationActive: PropTypes.bool,
  savedStartLocationId: PropTypes.string
};

class MessagesDisplayBox extends BaseComponent {
  constructor(props) {
    super(props);
    this.previousAuthor = '';
  }
  createMessageBubbles = () => {
    const messages = [];
    this.previousAuthor = '';

    this.props.messagesList.forEach((item) => {
      const message = JSON.parse(item.body);

      // Figure out if we should put the author above the chat message
      let authorDiv;
      if (this.previousAuthor !== item.author) {
        this.previousAuthor = item.author;
        let author = item.author;
        if (author) {
          author = author.split('---SkynetRules---')[1] || author;
          authorDiv = <div className="message__author">{author}</div>;
        }
      }

      let locationIcon;
      if (message.isLocation) {
        message.text = this.props.localizer.get('CTMGeneralSharedLocation');
        if (this.props.identity === item.author) {
          message.text = this.props.localizer.get('CTMUserSharedLocation');
        }
        locationIcon = (
          <i className="material-icons amav-icon amav-icon--place" />
        );
      }

      const textValue = message.text.replace(/\r?\n/g, '<br/>');

      /* eslint-disable react/no-danger */
      messages.push(
        <div
          className={classnames('messages__message', {
            'messages__message--is-mine': this.props.identity === item.author,
            'messages__message--location': message.isLocation
          })}
          key={item.index}
          onClick={() => {
            if (message.isLocation) {
              this.onLocationClick(message.spotObjectId, message.spotPoint);
            }
          }}
        >
          {authorDiv}
          {locationIcon}
          <div
            className="message__text"
            dangerouslySetInnerHTML={{ __html: textValue }}
          />
        </div>
      );

      /* eslint-enable react/no-danger */
    });

    return messages;
  };

  onLocationClick = (spotObject, spotPoint) => {
    if (spotObject !== this.props.currentSpot.objectId) {
      this.props.callSpotChangeEvent &&
        this.props.callSpotChangeEvent(spotObject);

      // TODO - Implement a promise to get rid of this timeout
      setTimeout(() => {
        lookAtPoint(
          {
            x: spotPoint[0],
            y: spotPoint[1],
            z: spotPoint[2]
          },
          this.props.controls
        );
      }, 350);
    } else {
      this.props.onRenderRequire && this.props.onRenderRequire();

      lookAtPoint(
        {
          x: spotPoint[0],
          y: spotPoint[1],
          z: spotPoint[2]
        },
        this.props.controls
      );
    }
  };

  render() {
    const messages = this.createMessageBubbles();

    return (
      <div
        className="chat__messages"
        ref={(contentWrapper) => {
          this.contentWrapper = contentWrapper;
        }}
      >
        <div
          className="content__messages amav-clearfix"
          ref={(content) => {
            this.content = content;
          }}
        >
          {messages}
        </div>
      </div>
    );
  }
}

MessagesDisplayBox.propTypes = {
  messagesList: PropTypes.arrayOf(PropTypes.shape).isRequired,
  identity: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired
};
