// Base
import React from 'react';

import classnames from 'classnames';

import BaseComponent from '@aroundmedia/frontend-library/lib/shared/basecomponent/BaseComponent';
import { Notifier } from '@aroundmedia/frontend-library/lib/shared/Notifier';

import ZoomLevelIndicator from './ZoomLevelIndicator';
// Widgets
import UIWidget from './widgets/UIWidget';
import UIInfoPointWidget from './widgets/UIInfoPointWidget';
import UIGalleryWidget from './widgets/UIGalleryWidget';
import UIFloorPlanWidget from './widgets/UIFloorPlanWidget';
import UIChatWidget from './widgets/UIChatWidget';
import UIMenuWidget from './widgets/UIMenuWidget';

import MenuTopBar from './menu/MenuTopBar';

import { isVRViewMode } from '../../config/types/ViewModes';
import { withMiniStore } from '../../MiniStore';

import {
  UIComponentTypes,
  ComponentForType
} from '../../config/types/Components';

const collapseBreakpointWidth = 500;
const collapseBreakpointHeight = 500;

class UIContainer extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      menuOpen: false,
      sideBarMode: false,
      activeComponent: props.activeComponent || null,
      activeInfoPointId: props.activeInfoPointId,
      albumData: props.albumData,
      expandable:
        props.size.width > collapseBreakpointWidth &&
        props.size.height > collapseBreakpointHeight
    };

    Notifier.subscribe('chat.loading', () => {
      this.onComponentToggle(UIComponentTypes.chat);
    });
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.albumData !== null) {
      this.setState({
        albumData: nextProps.albumData
      });
    }

    if (nextProps.activeInfoPointId !== this.state.activeInfoPointId) {
      this.setState(
        {
          activeInfoPointId: nextProps.activeInfoPointId
        },
        () => {
          if (nextProps.activeInfoPointId !== null) {
            this.onComponentToggle(UIComponentTypes.infoPoint);
          } else {
            this.onCloseInfoPoint();
          }
        }
      );
    }

    if (
      nextProps.size.width !== this.props.size.width ||
      nextProps.size.height !== this.props.size.height
    ) {
      if (
        nextProps.size.width > collapseBreakpointWidth &&
        nextProps.size.height > collapseBreakpointHeight
      ) {
        this.setState({
          expandable: true
        });
      } else {
        this.setState({
          sideBarMode: true,
          expandable: false
        });
      }
    }
  };

  // Sidebar button was clicked
  onComponentToggle = (type) => {
    const isInfoPoint = type.name === UIComponentTypes.infoPoint.name;
    const componentType = ComponentForType(type.name);

    // If the activeComponent is the same but the menu is open, we should close the menu, but keep de component active
    if (this.state.menuOpen && this.state.activeComponent === type.name) {
      this.onMenuToggle();
    } else {
      // Turn the active component null if the same component is activated, unless it's an infopoint
      const activeComponent =
        this.state.activeComponent !== type.name || isInfoPoint
          ? type.name
          : null;

      // If there is an active component, and the default state is sidebar, turn on sidebar mode
      const sideBarMode =
        activeComponent !== null && componentType.defaultState === 'sidebar';

      // Set the container active if component is not the same as the current component or it's an infopoint
      const active = activeComponent !== null;

      this.setState({
        active,
        sideBarMode,
        activeComponent
      });
    }
  };

  onMenuToggle = () => {
    const active = !this.state.menuOpen || !!this.state.activeComponent;

    this.setState({
      menuOpen: !this.state.menuOpen,
      sideBarMode: !this.state.sideBarMode,
      active
    });

    this.props.onRenderRequire();
  };

  closeMenuIfOpen = () => {
    if (this.state.menuOpen) {
      this.onMenuToggle();
    }
  };

  onCloseInfoPoint = () => {
    this.props.onCloseInfoPointModal();
    // this.onComponentToggle(type);

    this.setState({
      active: false,
      sideBarMode: true,
      activeComponent: null,
      activeInfoPointId: null
    });
  };

  changeSideBarMode = (sideBarMode) => {
    this.setState({
      sideBarMode
    });
  };

  toggleSideBarMode = () => {
    this.setState({
      sideBarMode: !this.state.sideBarMode
    });
  };

  onContentMeasureChanged = (widgetContentHeight) => {
    if (this.state.widgetContentHeight !== widgetContentHeight) {
      this.setState({ widgetContentHeight });
    }
  };

  createWidget = () => {
    if (this.state.activeComponent !== null && !this.state.menuOpen) {
      if (this.state.activeComponent === UIComponentTypes.infoPoint.name) {
        return (
          <UIInfoPointWidget
            onCloseClick={this.onCloseInfoPoint}
            currentSpot={this.props.currentSpot}
            sideBarMode={this.state.sideBarMode}
            toggleSideBarMode={this.toggleSideBarMode}
            onSideBarModeChange={this.changeSideBarMode}
            infoPointReferenceId={this.state.activeInfoPointId}
            type={ComponentForType(this.state.activeComponent)}
            onContentMeasureChanged={this.onContentMeasureChanged}
            analytics={this.props.analytics}
            localizer={this.props.localizer}
            size={this.props.size}
            expandable={this.state.expandable}
          />
        );
      } else if (this.state.activeComponent === UIComponentTypes.gallery.name) {
        return (
          <UIGalleryWidget
            onCloseClick={this.onComponentToggle}
            spotList={this.state.albumData.spotList}
            type={ComponentForType(this.state.activeComponent)}
            currentSpot={this.props.currentSpot}
            sideBarMode={this.state.sideBarMode}
            toggleSideBarMode={this.toggleSideBarMode}
            onSideBarModeChange={this.changeSideBarMode}
            onContentMeasureChanged={this.onContentMeasureChanged}
            maxHeight={null}
            isMobile={this.props.isMobile}
            callSpotChangeEvent={this.props.callSpotChangeEvent}
            size={this.props.size}
            localizer={this.props.localizer}
            expandable={this.state.expandable}
          />
        );
      } else if (
        this.state.activeComponent === UIComponentTypes.floorPlan.name
      ) {
        return (
          <UIFloorPlanWidget
            onCloseClick={this.onComponentToggle}
            spotList={this.state.albumData.spotList}
            type={ComponentForType(this.state.activeComponent)}
            currentSpot={this.props.currentSpot}
            floorPlanList={this.props.albumData.floorPlanList}
            callSpotChangeEvent={this.props.callSpotChangeEvent}
            sideBarMode={this.state.sideBarMode}
            toggleSideBarMode={this.toggleSideBarMode}
            onSideBarModeChange={this.changeSideBarMode}
            onContentMeasureChanged={this.onContentMeasureChanged}
            maxHeight={this.props.widgetMaxHeight}
            size={this.props.size}
            localizer={this.props.localizer}
            expandable={this.state.expandable}
          />
        );
      } else if (this.state.activeComponent === UIComponentTypes.chat.name) {
        return (
          <UIChatWidget
            onCloseClick={this.onComponentToggle}
            callSpotChangeEvent={this.props.callSpotChangeEvent}
            type={ComponentForType(this.state.activeComponent)}
            currentSpot={this.props.currentSpot}
            analytics={this.props.analytics}
            albumData={this.props.albumData}
            sideBarMode={this.state.sideBarMode}
            toggleSideBarMode={this.toggleSideBarMode}
            onSideBarModeChange={this.changeSideBarMode}
            onContentMeasureChanged={this.onContentMeasureChanged}
            maxHeight={this.props.widgetMaxHeight}
            size={this.props.size}
            localizer={this.props.localizer}
            camera={this.props.camera}
            scene={this.props.scene}
            controls={this.props.controls}
            onRenderRequire={this.props.onRenderRequire}
            expandable={this.state.expandable}
          />
        );
      }

      return (
        <UIWidget
          onCloseClick={this.onComponentToggle}
          type={ComponentForType(this.state.activeComponent)}
          sideBarMode={this.state.sideBarMode}
          toggleSideBarMode={this.toggleSideBarMode}
          onSideBarModeChange={this.changeSideBarMode}
          onContentMeasureChanged={this.onContentMeasureChanged}
          size={this.props.size}
          maxHeight={this.props.widgetMaxHeight}
          expandable={this.state.expandable}
        />
      );
    }

    return null;
  };

  createMenuBar = () => {
    if (this.state.menuOpen) {
      return (
        <UIMenuWidget
          albumData={this.props.albumData}
          type={UIComponentTypes.leftMenuBar}
          onViewModeToggle={this.onMenuToggle}
          onMenuToggle={this.onMenuToggle}
          onComponentToggle={this.onComponentToggle}
          isMobile={this.props.isMobile}
          onContentMeasureChanged={this.onContentMeasureChanged}
          maxHeight={null}
          size={this.props.size}
          localizer={this.props.localizer}
          styling={this.props.styling}
          features={this.props.features}
          expandable={this.state.expandable}
          renderer={this.props.renderer}
        />
      );
    }

    return null;
  };

  render() {
    const {
      WebVR,
      loaded,
      isMobile,
      size,
      albumData,
      currentSpot,
      camera,
      onRenderRequire
    } = this.props;

    const {
      activeComponent,
      sideBarMode,
      active,
      menuOpen,
      widgetContentHeight
    } = this.state;

    const needsBackground =
      ComponentForType(activeComponent).hasBackground || !sideBarMode;

    let needsAutoHeight;
    if (size && size.height && widgetContentHeight) {
      needsAutoHeight = widgetContentHeight <= size.height - 20;
    }
    const classNames = {
      'amav-ui-container': true,
      'is-active': active,
      'is-menu-active': menuOpen,
      'is-full-height': !needsAutoHeight && !needsBackground
    };

    // If the ui is set to active and thus a modal is shown, we need to set which kind of modal that is
    if (active) {
      const modeClass =
        sideBarMode || menuOpen ? 'is-sidebar-mode' : 'is-expanded-mode';
      classNames[modeClass] = true;
    }

    const currentWidget = this.createWidget();
    const menuWidget = this.createMenuBar();

    let spotName;
    if (currentSpot) {
      spotName = currentSpot.name;
    }

    let menuTopBar;
    if (loaded) {
      const topBarProps = {
        albumData,
        size,
        isMobile
      };
      menuTopBar = (
        <MenuTopBar
          currentSpotName={spotName}
          closeMenuIfOpen={this.closeMenuIfOpen}
          onMenuToggle={() => {
            this.onMenuToggle();
          }}
          onComponentToggle={this.onComponentToggle}
          {...topBarProps}
        />
      );
      const { appState } = this.props;
      if (isVRViewMode(appState.viewMode)) {
        menuTopBar = null;
      }
    }

    return (
      <div className={classnames(classNames)}>
        {menuTopBar}
        {menuWidget}
        {currentWidget}
        <ZoomLevelIndicator
          zoomLevel={camera.zoom}
          onResetZoom={() => {
            if (camera) {
              camera.zoom = 1;
              camera.updateProjectionMatrix();
              onRenderRequire();
            }
          }}
          isMobile={isMobile}
        />
      </div>
    );
  }
}

export default withMiniStore(UIContainer);
