// Base
import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from '@aroundmedia/frontend-library/lib/shared/basecomponent/BaseComponent';
import { CSSTransitionGroup } from 'react-transition-group';
import classNames from 'classnames';

class ZoomLevelIndicator extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      ...props
    };
    this.visibleTimeout = null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.zoomLevel !== this.state.zoomLevel) {
      this.setState({ zoomLevel: nextProps.zoomLevel, visible: true }, () => {
        this.stopHidingTimer();
        this.startHidingTimer();
      });
    }
  }

  stopHidingTimer = () => {
    clearTimeout(this.visibleTimeout);
  };

  startHidingTimer = () => {
    this.visibleTimeout = setTimeout(
      () => {
        this.setState({ visible: false });
      },
      this.props.isMobile ? 500 : 3000
    );
  };

  onMouseOver = () => {
    this.stopHidingTimer();
  };

  onMouseOut = () => {
    this.startHidingTimer();
  };

  onReset = () => {
    this.props.onResetZoom();
  };

  render() {
    let resetButton;
    if (!this.props.isMobile) {
      resetButton = (
        <div className="amav-zoom-level__reset" onClick={this.onReset}>
          Reset
        </div>
      );
    }

    let indicator;
    if (this.state.visible) {
      indicator = (
        <div
          className={classNames('amav-zoom-level', {
            'amav-zoom-level--mobile': this.props.isMobile
          })}
          key="1"
          onMouseOver={this.onMouseOver}
          onMouseOut={this.onMouseOut}
        >
          <div className="amav-zoom-level__text">
            {parseInt(this.state.zoomLevel * 100, 10)}%
          </div>
          {resetButton}
        </div>
      );
    }

    return (
      <CSSTransitionGroup
        transitionName="fade"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
      >
        {indicator}
      </CSSTransitionGroup>
    );
  }
}

ZoomLevelIndicator.propTypes = {
  zoomLevel: PropTypes.number.isRequired,
  onResetZoom: PropTypes.func.isRequired,
  isMobile: PropTypes.bool
};

export default ZoomLevelIndicator;
