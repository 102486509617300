// Import
// Base
import React from 'react';
import ReactDOM from 'react-dom';
import { ViewerSDK } from '@aroundmedia/frontend-library/lib/sdks/ViewerSDK';
import 'custom-event-polyfill';
import MiniStore from './MiniStore';

// import MountTest from './tests/MountTest';
import Viewer from './components/Viewer';
import ENV from './config/active-environment/env.json';

// Require because I have to here :(
require('../scss/main.scss');

function start() {
  ViewerSDK.initWithConfig(ENV);

  const around = document.getElementById('around');

  let albumCode = around.getAttribute('data-tour');
  if (albumCode) {
    albumCode = albumCode.replace(/\s/g, '');
  }

  let lang = around.getAttribute('data-lang');
  if (lang) {
    lang = lang.replace(/\s/g, '');
  } else {
    lang = 'eng';
  }

  const loaderLogoActive = around.getAttribute('data-loader-logo') || 'on';

  const viewerMetaData = {
    albumCode,
    lang,
    loaderLogoActive
  };

  const domElement = document.getElementById('around');
  ReactDOM.render(
    <MiniStore>
      <Viewer metaData={viewerMetaData} domElement={domElement} />
    </MiniStore>,
    domElement
  );
}

start();

export { start };
