// Imports
// Base

export default class SphereShaders {
    // TODO - check if the shaders exist yet, don't append them if they do

    /**
     * chreate the vertexShader
     * @param scriptId
     * @returns {Element}
     */
    static createVertexShader(scriptId) {
        const script = document.createElement('script');
        script.id = scriptId;
        script.type = 'x-shader/x-vertex';
        script.textContent =
            `varying vec2 vUv;
             void main() {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
            }`;
        document.head.appendChild(script);
        return script;
    }

    /**
     * create the fragment shader
     * @param scriptId
     * @returns {Element}
     */
    static createFragmentShader(scriptId) {
        const script = document.createElement('script');
        script.id = scriptId;
        script.type = 'x-shader/x-fragment';
        script.textContent =
            `precision mediump float;
            varying vec2 vUv;
            vec4 colorWarm = vec4(1.0, 0.42, 0.19, 0.3);
            vec4 colorCold = vec4(0.19, 0.82, 1.0, 0.3);
            uniform vec2 uUVOffset;
            uniform vec2 uUVParts;
            uniform sampler2D uTex;
            uniform float uBrightness;
            uniform float uContrast;
            uniform float uHue;
            uniform float uSaturation;
            uniform float uWarmth;
            void main() {
              vec4 color = texture2D(uTex, vec2((vUv.x / uUVParts.x) + uUVOffset.x, (vUv.y / uUVParts.y) + uUVOffset.y));
              if (uWarmth != 0.0) {
                if (uWarmth > 0.0) {
                  color.rgb = (color.rgb * (1.0 - abs(uWarmth))) + (colorWarm.rgb * abs(uWarmth));
                } else {
                  color.rgb = (color.rgb * (1.0 - abs(uWarmth))) + (colorCold.rgb * abs(uWarmth));
                }
              };
              if (uBrightness != 0.0) {
                color.rgb += uBrightness;
              };
              if (uContrast != 0.0) {
                if (uContrast > 0.0) {
                  color.rgb = (color.rgb - 0.5) / (1.0 - uContrast) + 0.5;
                } else {
                  color.rgb = (color.rgb - 0.5) * (1.0 + uContrast) + 0.5;
                }
              };
              if (uHue != 0.0) {
                float angle = uHue * 3.14159265;
                float s = sin(angle), c = cos(angle);
                vec3 weights = (vec3(2.0 * c, -sqrt(3.0) * s - c, sqrt(3.0) * s - c) + 1.0) / 3.0;
                float len = length(color.rgb);
                color.rgb = vec3(
                  dot(color.rgb, weights.xyz),
                  dot(color.rgb, weights.zxy),
                  dot(color.rgb, weights.yzx)
                );
              };
              if (uSaturation != 0.0) {
                float average = (color.r + color.g + color.b) / 3.0;
                if (uSaturation > 0.0) {
                  color.rgb += (average - color.rgb) * (1.0 - 1.0 / (1.001 - uSaturation));
                } else {
                  color.rgb += (average - color.rgb) * (-uSaturation);
                }
              };
              gl_FragColor = color;
            }`;
        document.head.appendChild(script);
        return script;
    }
}
