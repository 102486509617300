// Base
// import React from 'react';
import BaseComponent from '@aroundmedia/frontend-library/lib/shared/basecomponent/BaseComponent';
import * as THREE from 'three/build/three.min';
// import { LazyLoader } from '@aroundmedia/frontend-library/lib/shared/modules/LazyLoader';
import LogoHelper from '../../helpers/logo-helper';
import ENV from '../../config/active-environment/env.json';
import entityConfig from '../../../active-entity/config.json';

export default class BottomLogo extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = props;

    let url = ENV.cdnUrl + entityConfig.visual.logos.bottomLogo;
    let logoScale = 1;

    if (this.props.styling) {
      // TODO -  && this.state.styling.includedFeatureList contains branding.
      logoScale = this.props.styling.logoScale || logoScale;
      url = this.props.styling.logoUrl || url;
    }

    if (this.props.albumData.logoEnabled) {
      // LOADING THE IMAGE HERE OLDSCHOOL BECAUSE TEXTURELOADER DOES NOT HANDLE SVG
      // TODO - MAKE A LOADER OUT OF THIS TO USE THE LAZYLOADER
      const image = document.createElement('img');
      image.crossOrigin = 'anonymous';

      image.onload = () => {
        // Make Texture
        const texture = new THREE.Texture(image);
        if (typeof texture === 'undefined') {
          return;
        }

        texture.magFilter = THREE.LinearFilter;
        texture.minFilter = THREE.LinearFilter;
        texture.premultiplyAlpha = true;

        // Load the texture and then use it when it's loaded
        // LazyLoader.add(url, new THREE.TextureLoader(), 2).then((texture) => {
        // Make Geometry

        // By using Pythagoras we can determine how big the radius of the logo has to be to fit the section
        // of the sphere for a given y position to dial in the correct size to cover the tripod
        const scaleModifier = logoScale;
        const yOffset = 360;
        const logoRadius = Math.sqrt(
          (this.props.sphereDiameter ** 2 - yOffset ** 2) * scaleModifier
        );

        this.plane = new THREE.Mesh(
          new THREE.PlaneGeometry(logoRadius * 2, logoRadius * 2, 10, 10),
          LogoHelper.createLogoMaterial(texture, THREE)
        );

        // TODO - Reenable this if we decide to support canvas rendering
        // if (A.main.renderMode == 'canvas') {
        //     material.map = texture; //we save this for compatibility with canvas
        //     material.overdraw = 0.5;
        // }

        this.plane.position.y = -yOffset;
        this.plane.position.x = 0;
        this.plane.position.z = 0;
        this.plane.scale.set(0.1, 0.1, 0.1);

        this.plane.rotation.x = -Math.PI / 2;

        this.plane.material.transparent = true;
        // this.plane.material.needsUpdate = true;

        this.updateLogoTexture();

        // Add Geometry to scene
        this.state.scene.add(this.plane);
        this.logoLoaded = true;

        // });
      };

      // TODO - error handling
      // image.onerror = function () {
      //     //image.setAttribute('src', D.defaultLogoURL);
      // };

      image.setAttribute('src', url);
    }
  }

  componentWillUnmount() {
    this.scene.remove(this.plane);
    this.logoLoaded = false;
  }

  updateLogoTexture = (tex) => {
    if (tex) {
      this.plane.material.uniforms.uTex.value = tex;
      // this.plane.material.map = tex; // Saved here for canvas compatibility
    }

    this.plane.material.uniforms.uTex.value.needsUpdate = true;
    this.plane.material.needsUpdate = true;
  };

  render() {
    if (this.props.albumData.logoEnabled) {
      if (this.props.camera && this.plane) {
        this.plane.rotation.z = this.props.camera.rotation.z;
      }
    }

    return false;
  }
}
