// Base
import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from '@aroundmedia/frontend-library/lib/shared/basecomponent/BaseComponent';
import AFLButton from '@aroundmedia/frontend-library/lib/shared/components/buttons/AFLButton';
import { AFLVerticalDivider } from '@aroundmedia/frontend-library/lib/shared/components/helpers/Functionals';
import QR from 'qrcode-npm';

import UIWidget from './UIWidget';
import ViewModeToggle from '../menu/ViewModeToggle';

import { UIComponentTypes } from '../../../config/types/Components';

import ENV from '../../../config/active-environment/env.json';

class UIMenuWidget extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      canPresent: false,
      promiseDone: false
    };

    // TODO - enable as prop too
    this.title = this.props.localizer.get('menu');
    this.galleryBtnTitle = this.props.localizer.get('gallery');
    this.floorPlanBtnTitle = this.props.localizer.get('floorPlan');
    this.chatBtnTitle = this.props.localizer.get('chat');
    this.qrCodeLabel = this.props.localizer.get('qrCodeLabel');
    this.getVRLabel = this.props.localizer.get('getVRCardboard');
    this.referralLink = 'https://vr.google.com/cardboard/get-cardboard/';

    this.createQRCode();
  }

  createQRCode = () => {
    const code = QR.qrcode(4, 'M');
    code.addData(`${ENV.viewerUrl}/?id=${this.props.albumData.code}`);
    code.make();

    this.image = code.createImgTag(4);
    const div = document.createElement('div');
    div.innerHTML = this.image;
    const firstImage = div.getElementsByTagName('img')[0];
    this.qrImgSrc = firstImage ? firstImage.src : '';
  };

  /**
   *
   * @param type
   */
  onComponentSelect = (type) => {
    this.props.onMenuToggle();
    this.props.onComponentToggle(type);
  };

  render() {
    let floorPlanButton;
    if (
      this.props.albumData &&
      this.props.albumData.floorPlanList &&
      this.props.features.floorPlan
    ) {
      if (this.props.albumData.floorPlanList.length > 0) {
        floorPlanButton = (
          <MenuButton
            className="amav-widget__button amav-widget__button--menu"
            title={this.floorPlanBtnTitle}
            iconClass="amav-icon--rounded-corner"
            onClickAction={() => {
              this.onComponentSelect(UIComponentTypes.floorPlan);
            }}
          />
        );
      }
    }

    // TODO check chat settings in the portal
    let chatButton;
    if (
      !this.props.isMobile &&
      this.props.features.chat &&
      this.props.styling &&
      this.props.styling.customerTicketEnabled
    ) {
      chatButton = (
        <MenuButton
          className="amav-widget__button amav-widget__button--menu"
          title={this.chatBtnTitle}
          iconClass="amav-icon--chat-bubble-outline"
          onClickAction={() => {
            this.onComponentSelect(UIComponentTypes.chat);
          }}
        />
      );
    }

    let qrCode = (
      <div className="amav-qr-code">
        <p className="amav-qr-code__label">{this.qrCodeLabel}</p>
        <a
          className="amav-qr-code__vr-link"
          href={this.referralLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <p className="amav-qr-code__label amav-qr-code__label--vr-link">
            {this.getVRLabel}
          </p>
        </a>
        <img
          className="amav-qr-code__img"
          src={this.qrImgSrc}
          alt="Viewer QR Code"
        />
      </div>
    );

    return (
      <UIWidget
        size={this.props.size}
        {...this.props}
        typeModifier="amav-widget--menu"
        headerTitle={this.title}
      >
        <strong className="amav-widget__section--title">
          {this.props.localizer.get('options')}
        </strong>
        <MenuButton
          className="amav-widget__button amav-widget__button--menu"
          title={this.galleryBtnTitle}
          iconClass="amav-icon--filter"
          onClickAction={() => {
            this.onComponentSelect(UIComponentTypes.gallery);
          }}
        />
        {floorPlanButton}
        {chatButton}
        <div className="amav-widget__spacer" />
        <strong className="amav-widget__section--title">
          {this.props.localizer.get('experience')}
        </strong>
        <ViewModeToggle
          onViewModeToggle={this.props.onViewModeToggle}
          WebVR={this.props.WebVR}
          localizer={this.props.localizer}
          renderer={this.props.renderer}
        />
        {qrCode}
      </UIWidget>
    );
  }
}

class MenuButton extends BaseComponent {
  render() {
    return (
      <AFLButton
        onClickAction={this.props.onClickAction}
        className={this.props.className}
      >
        <i
          className={`material-icons amav-icon amav-icon--left ${this.props.iconClass}`}
        />
        <span className="button__title">{this.props.title}</span>
        <AFLVerticalDivider color="#787878" />
        <i
          className={
            'material-icons amav-icon amav-icon--right amav-icon--keyboard-arrow-right'
          }
        />
      </AFLButton>
    );
  }
}

MenuButton.propTypes = {
  title: PropTypes.string.isRequired,
  iconClass: PropTypes.string,
  renderer: PropTypes.shape({})
};

export default UIMenuWidget;
