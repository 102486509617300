// Base
import React, { Fragment } from 'react';
import BaseComponent from '@aroundmedia/frontend-library/lib/shared/basecomponent/BaseComponent';
import AFLImageBlur from '@aroundmedia/frontend-library/lib/shared/components/images/AFLImageBlur';
import { ThreeBounce } from 'better-react-spinkit';
import classNames from 'classnames';
import Authenticator from './Authenticator';

import CompanyLogo from '../../branding/CompanyLogo';

export default class Loader extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = props;

    window.console.log('loader props: %o', props);

    this.publishIssueText = props.localizer.get('notPublished');
    this.existanceIssueText = props.localizer.get('nonExistent');
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.coverImageDataUrl &&
      this.state.coverImageDataUrl !== nextProps.coverImageDataUrl
    ) {
      this.setState({
        coverImageDataUrl: nextProps.coverImageDataUrl
      });
    }
  }

  checkIssueText = () => {
    if (this.props.isPublished === false) {
      return <pre className="amav-issue-text">{this.publishIssueText}</pre>;
    } else if (this.props.isNotFound) {
      return <pre className="amav-issue-text">{this.existanceIssueText}</pre>;
    }

    return false;
  };

  render() {
    const coverImage = this.state.coverImageDataUrl;

    const issueText = this.checkIssueText();

    let middleContainer;
    if (this.props.authRequired) {
      middleContainer = (
        <Authenticator
          accessLevel={this.props.accessLevel}
          mailWallPhoneNumberMandatory={this.props.mailWallPhoneNumberMandatory}
          albumCode={this.props.albumCode}
          analytics={this.props.analytics}
          authStatus={this.props.authStatus}
          localizer={this.props.localizer}
          onAuthenticate={this.props.onAuthenticate}
          size={this.props.size}
        />
      );
    } else {
      let bouncer;
      if (!issueText) {
        bouncer = (
          <div
            className={classNames('amav-loader__bouncer', {
              'has-background': coverImage
            })}
          >
            <ThreeBounce timingFunction="ease-in" size={15} gutter={5} />
          </div>
        );
      }

      middleContainer = (
        <div
          className={classNames('amav-loader__middleContainer', {
            'has-background': coverImage
          })}
        >
          {bouncer}
          {issueText}
        </div>
      );
    }

    let loaderLogo;
    if (this.props.loaderActive === 'on') {
      loaderLogo = (
        <div className="amav-loader__logo">
          <CompanyLogo hasBackground={coverImage} />
        </div>
      );
    }

    let bottomRightContainer = (
      <div
        className={classNames('amav-loader__bottomRightContainer', {
          'has-background': coverImage
        })}
      >
        <p
          className={classNames('amav-loader__logoPrefix', {
            'has-background': coverImage
          })}
        >
          Powered by
        </p>
        {loaderLogo}
        {issueText}
      </div>
    );

    let content;
    if (coverImage) {
      content = (
        <AFLImageBlur blurRadius={50} img={coverImage}>
          {middleContainer}
          {bottomRightContainer}
        </AFLImageBlur>
      );
    } else {
      content = (
        <Fragment>
          {middleContainer}
          {bottomRightContainer}
        </Fragment>
      );
    }

    return <div className="amav-loader amav-loader--main">{content}</div>;
  }
}
