// Base
import React from 'react';
import classNames from 'classnames';
import Measure from 'react-measure';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

// SFL
import BaseComponent from '@aroundmedia/frontend-library/lib/shared/basecomponent/BaseComponent';
// import { ViewerSDK } from '@aroundmedia/frontend-library/lib/sdks/ViewerSDK';

import ENV from '../../../config/active-environment/env.json';
import UIWidget from './UIWidget';

export default class UIFloorPlanWidget extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      currentFloorPlanLoaded: true,
      currentFloorPlanPortrait: false,
      drawnSize: { width: 100, height: 200 },
      naturalSize: { width: 100, height: 200 }
    };

    this.title = this.props.localizer.get('floorPlan');
  }

  componentDidMount() {
    this.updateCurrentFloorPlan();
  }

  onFloorPlanChange = (entry) => {
    this.props.floorPlanList.forEach((item) => {
      if (item.objectId === entry.value) {
        this.setState({ floorPlan: item });
      }
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.currentSpot !== this.props.currentSpot) {
      this.setState(
        {
          currentSpot: nextProps.currentSpot
        },
        this.updateCurrentFloorPlan
      );
    }
  }

  updateCurrentFloorPlan = () => {
    let result;
    if (this.props.floorPlanList && this.state.currentSpot) {
      const list = this.props.floorPlanList;

      list.forEach((floorPlan) => {
        const linkList = floorPlan.floorPlanLinkList;
        if (linkList.length > 0) {
          linkList.forEach((floorPlanLink) => {
            if (
              !result &&
              floorPlanLink.linkReference.objectId ===
                this.state.currentSpot.objectId
            ) {
              result = floorPlan;
            }
          });
        }
      });

      window.console.log('result: %o', result);
      if (!result) {
        result = this.props.floorPlanList[0];
      }
    }

    this.setState({
      floorPlan: result,
      currentFloorPlanLoaded: this.state.floorPlan === result
    });
  };

  onFloorPlanLoaded = ({ target: image }) => {
    this.setState({
      currentFloorPlanLoaded: true,
      currentFloorPlanPortrait: image.naturalHeight > image.naturalWidth,
      drawnSize: { width: image.offsetWidth, height: image.offsetHeight },
      naturalSize: {
        width: image.naturalWidth,
        height: image.naturalHeight
      }
    });
  };

  onLinkClicked = () => {
    this.props.onSideBarModeChange(true);
  };

  buildFloorPlan = () => {
    // Build Image Url
    const imageUrl = `${ENV.baseImageUrl}/${this.state.floorPlan.imageUrl}`;

    window.console.log('buildFloorPlan: %o', imageUrl);

    // Build All FloorPlanLinks
    const floorPlanLinks = [];
    if (this.state.currentFloorPlanLoaded) {
      this.state.floorPlan.floorPlanLinkList.forEach((item) => {
        floorPlanLinks.push(
          <UIFloorPlanLink
            key={item.name}
            metaData={item}
            currentSpot={this.state.currentSpot}
            clicked={this.onLinkClicked}
            {...this.props}
          />
        );
      });
    }

    // We need to account for top padding of 60px on the .amav-widget__content wrapper.
    const imageMaxHeight =
      this.props.maxHeight - (this.state.selectorHeight || 0) - 60;
    let style = { maxHeight: imageMaxHeight, margin: '0 auto' };

    // Make the holder width smaller if necessary
    const scaleRatio = imageMaxHeight / this.state.naturalSize.height;
    const intendedDrawWidth = this.state.naturalSize.width * scaleRatio;
    if (intendedDrawWidth < 260) {
      // TODO: Link this to the widget width value, so it's not a magic number
      style.maxWidth = intendedDrawWidth;
    }

    const titleAndSelectorHeight = (this.state.selectorHeight || 0) + 60;
    const maxWidgetHeight =
      this.props.size.height * 0.8 - titleAndSelectorHeight;
    const maxWidgetWidth = this.props.size.width * 0.8;

    if (!this.props.sideBarMode) {
      let maxHeight = maxWidgetHeight;
      let maxWidth = Math.min(this.state.naturalSize.width, maxWidgetWidth);

      if (this.state.currentFloorPlanPortrait) {
        maxHeight = Math.min(this.state.naturalSize.height, maxWidgetHeight);
        maxWidth = maxWidgetWidth;
      }

      style = { maxHeight, maxWidth };
    }

    window.console.log('---- imageUrl: %o', imageUrl);
    window.console.log('---- this.state.floorPlan: %o', this.state.floorPlan);

    return (
      <div className="amav-floor-plan__image-holder" style={style}>
        <div
          className="amav-floor-plan__image-wrapper"
          style={{ height: '100%' }}
        >
          <img
            className="amav-floor-plan__image"
            src={imageUrl}
            alt={this.state.floorPlan.name}
            onLoad={this.onFloorPlanLoaded}
          />
        </div>
        {floorPlanLinks}
      </div>
    );
  };

  buildFloorPlanSelector = () => {
    if (this.props.floorPlanList && this.props.floorPlanList.length > 1) {
      const selectOptions = [];
      this.props.floorPlanList.forEach((item) => {
        selectOptions.push({ value: item.objectId, label: item.name });
      });

      return (
        <Measure
          bounds
          onResize={(dimensions) => {
            this.setState({
              selectorHeight: dimensions.bounds.height || 0
            });
          }}
        >
          {({ measureRef }) => (
            <div ref={measureRef} className="amav-floor-plan__select">
              <Dropdown
                name="floor-plan-selector"
                value={{
                  value: this.state.floorPlan,
                  label: this.state.floorPlan.name
                }}
                options={selectOptions}
                onChange={this.onFloorPlanChange}
                clearable={false}
                autoBlur
              />
            </div>
          )}
        </Measure>
      );
    }

    return (
      <div className="amav-floor-plan__name">{this.state.floorPlan.name}</div>
    );
  };

  render() {
    if (!this.state.floorPlan) {
      return false;
    }

    const floorPlan = this.buildFloorPlan();
    const floorPlanSelect = this.buildFloorPlanSelector();

    return (
      <UIWidget
        size={this.props.size}
        {...this.props}
        typeModifier="amav-widget--floor-plan"
        headerTitle={this.title}
      >
        {floorPlan}
        <div className="amav-floor-plan__footer">
          {floorPlanSelect}
          <div
            className="amav-floor-plan__expander"
            onClick={() =>
              this.props.toggleSideBarMode && this.props.toggleSideBarMode()
            }
          >
            <i
              className={`material-icons amav-icon amav-icon--${
                this.props.sideBarMode ? 'zoom-in' : 'zoom-out'
              }`}
            />
          </div>
        </div>
      </UIWidget>
    );
  }
}

class UIFloorPlanSpotTitle extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      dimensions: {
        bounds: {
          width: -1,
          height: -1
        }
      }
    };
  }

  render() {
    const { width } = this.state.dimensions.bounds;
    const style = {
      left: `${-((width - this.props.parentWidth) / 2)}px`
      // left: `${0}px`
    };

    return (
      <Measure
        bounds
        onResize={(dimensions) => {
          this.setState({ dimensions });
        }}
      >
        {({ measureRef }) => (
          <div
            ref={measureRef}
            className="amav-floor-plan-spot__title"
            style={style}
          >
            {this.props.name}
          </div>
        )}
      </Measure>
    );
  }
}

class UIFloorPlanLink extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      dimensions: {
        bounds: {
          width: -1,
          height: -1
        }
      }
    };
  }

  /**
   * Change the viewer to a new spot
   * @param objectId
   */
  onFloorPlanLinkClick = (objectId) => {
    if (objectId !== this.props.currentSpot.objectId) {
      this.props.callSpotChangeEvent &&
        this.props.callSpotChangeEvent(objectId);
    }
  };

  render() {
    const link = this.props.metaData;
    const isActive =
      link.linkReference.objectId === this.props.currentSpot.objectId;
    const posStyle = {
      left: `${link.point[0] * 100}%`,
      top: `${link.point[1] * 100}%`
    };

    const { width } = this.state.dimensions.bounds;

    return (
      <Measure
        whitelist={['height']}
        includeMargin={false}
        bounds
        onResize={(dimensions) => {
          this.setState({ dimensions });
        }}
      >
        {({ measureRef }) => (
          <div
            className={classNames({
              'amav-floor-plan-spot': true,
              'amav-floor-plan-spot--active': isActive
            })}
            style={posStyle}
            onClick={() => {
              this.onFloorPlanLinkClick(link.linkReference.objectId);
              this.props.clicked();
            }}
            key={link.name}
            ref={measureRef}
          >
            <div className="amav-floor-plan-spot__dot" />
            <UIFloorPlanSpotTitle name={link.name} parentWidth={width} />
          </div>
        )}
      </Measure>
    );
  }
}
