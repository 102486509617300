// Imports
import ga from 'universal-ga';
import ENV from '../../config/active-environment/env.json';
import { version } from '../../../../package.json'; // trust me this works

// Exports

/**
 * analytics time tracker
 */
class TimeTracker {
    constructor(type) {
        this.type = type;
        this.curTime = Date.now();
        this.delta = 0;
        this.startTime = 0;
        this.trackedId = '';
    }

    sendTiming = () => {
        this.curTime = Date.now();
        this.delta = this.curTime - this.startTime;
        ga.name('amViewerTracker').timing(this.type, 'view', this.delta, this.trackedId);
    };

    track = (id) => {
        if (!this.trackedId) {
            this.startTime = Date.now();
            this.trackedId = id;
        } else {
            this.sendTiming();

            // reset
            this.startTime = this.curTime;
            this.trackedId = id;
        }
    };

    stop = () => {
        this.sendTiming();
        this.trackedId = null;
    };

    get currentObjectId() {
        return this.trackedId;
    }
}

/**
 * Analytics consumer class
 */
export default class Analytics {
    /**
     * Constructor
     * @param {string} albumCode - format: XXXXX
     */
    constructor(albumCode) {
        this.albumCode = albumCode;
        this.interacted = false;
        this.eventStreamStarted = false;
        this.spotTracker = new TimeTracker('spot');
        this.infoPointTracker = new TimeTracker('infopoint');

        // TODO - move this to the backend or a config file
        this.isPortal =
            window.location.href.indexOf('devportal.around.media') >= 0 ||
            window.location.href.indexOf('stagingportal.around.media') >= 0 ||
            window.location.href.indexOf('portal.around.media') >= 0 ||
            window.location.href.indexOf('devportalv2.around.media') >= 0 ||
            window.location.href.indexOf('stagingportalv2.around.media') >= 0 ||
            window.location.href.indexOf('portalv2.around.media') >= 0 ||
            window.location.href.indexOf('around-portal.dev') >= 0 ||
            window.location.href.indexOf('localhost:10080') >= 0 ||
            window.location.href.indexOf('localhost:11080') >= 0 ||
            window.location.href.indexOf('360app-devv2.prompto.com') >= 0 ||
            window.location.href.indexOf('360app-stagingv2.prompto.com') >= 0 ||
            window.location.href.indexOf('360app.prompto.com') >= 0;

        // Config and Setting of tracker code
        const curAmTrackerCode = ENV.gaTracker;

        // Creating the tracking
        ga.initialize(curAmTrackerCode, {
            name: 'amViewerTracker',
            siteSpeedSampleRate: 50,
            alwaysSendReferrer: true
        });

        const referrer = (window.location !== window.parent.location)
            ? document.referrer
            : document.location.href;

        ga.name('amViewerTracker').set('location', referrer);
        ga.name('amViewerTracker').set('referrer', referrer);
        ga.name('amViewerTracker').set('transport', 'beacon');

        ga.name('amViewerTracker').set('appName', 'media.around.viewer');
        ga.name('amViewerTracker').set('appVersion', version.toString());
    }

    /**
     * Sent when a user interacts with the viewer for the first time
     * @param {string} currentSpotObjectId
     */
    sendTrackOnInteraction = (currentSpotObjectId) => {
        if (this.isPortal || this.interacted) { return; }
        this.interacted = true;

        ga.name('amViewerTracker').set('page', `/?id=${this.albumCode}&interacted`);

        // tracking interaction
        ga.name('amViewerTracker').pageview({
            hitType: 'pageview',
            page: `/?id=${this.albumCode}&interacted`,
            title: this.albumCode
        });

        ga.name('amViewerTracker').event('spot', 'view', currentSpotObjectId);
        this.startEventStream();
        this.spotTracker.track(currentSpotObjectId);
    };

    /**
     * Sent when the viewer loads for the first time
     */
    sendTrackOnLoad = () => {
        if (this.isPortal) { return; }

        ga.name('amViewerTracker').set('page', `/?id=${this.albumCode}`);

        ga.name('amViewerTracker').pageview({
            hitType: 'pageview',
            page: `/?id=${this.albumCode}`,
            title: this.albumCode,
            sessionControl: 'start'
        });
    };

    /**
     * Starts the stream of events to correctly track session duration
     */
    startEventStream = () => {
        if (this.isPortal || this.eventStreamStarted) { return; }
        this.eventStreamStarted = true;

        setInterval(() => {
            ga.name('amViewerTracker').event('Active', 'keep-alive', 'keep alive event');
        }, 5000);
    };

    /**
     * Sent when a spot changes
     * @param {string} objectId
     */
    spotChanged = (objectId) => {
        if (this.isPortal) { return; }

        // Only fire the track is the new objectId is different from the one we're tracking
        if (this.spotTracker.currentObjectId !== objectId) {
            // Only Fire the spot view if tracker already had a spot (so not on initial track start)
            if (this.spotTracker.currentObjectId) {
                ga.name('amViewerTracker').event('spot', 'view', objectId);
            }
            this.spotTracker.track(objectId);
        }
    };

    /**
     * Setnt when an infospot is openend or closed
     * @param {string} objectId
     */
    infoSpotOpened = (objectId) => {
        if (this.isPortal) { return; }

        if (this.infoPointTracker.currentObjectId !== objectId) {
            ga.name('amViewerTracker').event('infopoint', 'view', objectId);
            this.infoPointTracker.track(objectId);
        }
    };

    /**
     * Sent when an the infoSpotIsClosed
     */
    infoSpotClosed = () => {
        if (this.isPortal) { return; }

        this.infoPointTracker.stop();
    };

    // =======================================
    // ================ CHAT =================
    // =======================================

    /**
     * Sent when a chat window is opened
     */
    sendTrackOnChatOpened = () => {
        if (this.isPortal) { return; }

        ga.name('amViewerTracker').event('chat', 'opened');
    };

    /**
     * Sent when a chat is started
     */
    sendTrackOnChatStarted = () => {
        if (this.isPortal) { return; }

        ga.name('amViewerTracker').event('chat', 'started');
    };

    /**
     * Sent when a chat message is sent
     */
    sendTrackOnChatMessageSent = () => {
        if (this.isPortal) { return; }

        ga.name('amViewerTracker').event('chat', 'messageSent');
    };


    // =======================================
    // ============= VIEW MODES ==============
    // =======================================
    /**
     * Sent when viewMode is changed
     */
    sendTrackOnViewModeChanged = (viewMode) => {
        if (this.isPortal) { return; }

        ga.name('amViewerTracker').event('viewMode', 'set', viewMode);
    };

    // =======================================
    // ============= MAIL WALL ===============
    // =======================================

    /**
     * Sent when a mailwall is used
     */
    sendTrackOnMailWallEnabled = () => {
        if (this.isPortal) { return; }

        ga.name('amViewerTracker').event('mailWall', 'enabled');
    };

    /**
     * Sent when a mailwall window is shown
     */
    sendTrackOnMailWallShown = () => {
        if (this.isPortal) { return; }

        ga.name('amViewerTracker').event('mailWall', 'shown');
    };

    /**
     * Sent when a mailwall is filled out and sent
     */
    sendTrackOnMailWallFilledOut = () => {
        if (this.isPortal) { return; }

        ga.name('amViewerTracker').event('mailWall', 'filledOut');
    };

    /**
     * Sent when a maillwall is passed with a saved token
     */
    sendTrackOnMailWallTokenUsed = () => {
        if (this.isPortal) { return; }

        ga.name('amViewerTracker').event('mailWall', 'tokenUsed');
    };

    // =======================================
    // =============== UNLOAD ================
    // =======================================
    /**
     * Sent when a page is unloaded, for session duration
     */
    sendTrackOnUnload = () => {
        this.spotTracker.stop();
        ga.name('amViewerTracker').set('page', '/unload');

        ga.name('amViewerTracker').pageview({
            hitType: 'pageview',
            page: '/unload',
            'transport': 'beacon' // eslint-disable-line
        });
    };

    /**
     * Set the customer Object ID for tracking, with the token
     * @param {string} token
     */
    set customerToken(token) {
        const _token = token.split('.')[1];
        if (window.atob) {
            const jsonToken = JSON.parse(window.atob(_token));
            this._customerObjectId = jsonToken.usr;
        } else {
            this._customerObjectId = 'unknown';
        }

        ga.name('amViewerTracker').set('dimension1', this._customerObjectId);
        this._customerToken = token;
    }
}
