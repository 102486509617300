// import 'fullscreen-polyfill';
import screenfull from 'screenfull';
import { addClass, removeClass } from './helpers';

const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

class FullScreen {
  constructor() {
    this._fullscreen = false;

    const viewportTags = document.querySelectorAll('meta[name=viewport]');
    if (viewportTags && viewportTags.length > 0) {
      // Viewport tag found
      if (viewportTags.length > 1) {
        // if there are more than one viewport tags, we should get the content of the last one
        // because only the last one is used by the browser
        this.originalTagContent = viewportTags[
          viewportTags.length - 1
        ].getAttribute('content');
      } else {
        this.originalTagContent = viewportTags[0].getAttribute('content');
      }
    }

    this._inIframe = inIframe();
    this._isDisabled = !!screenfull.isEnabled === false;
  }

  setFullscreen = (targetState) => {
    this._fullscreen = targetState;

    // TODO
    // if (A.main.fullscreenInTab) {
    //     return;
    // }

    // TODO
    // if (galleryVisible) {
    //     amViewer.main.toggleGallery();
    // }
    //
    // if (viewModesVisible) {
    //     amViewer.main.toggleViewModes();
    //     $am('.am-view-mode-entry').off('click');
    // }

    // TODO Switch fullsreen button -> this will be done by checking the current state in the react component
    // $am('.fullscreen-button').toggleClass('fullscreen-button--active', isFullscreen);

    // TODO
    // var acj = $am('#around-container');
    // if (isLayoutBoxed) {
    //     acj.toggleClass('am-layout-boxed');
    // }
    const aroundDomElement = document.getElementById('around'); // TODO pass in this name?

    if (this._isMobile) {
      // This is a wordpress integration hack :)
      if (typeof wp !== 'undefined') {
        const mainHeader = document.getElementById('main-header');
        if (mainHeader) {
          if (this._fullscreen) {
            addClass(mainHeader, 'amav-hidden');
          } else {
            removeClass(mainHeader, 'amav-hidden');
          }
        }
      }

      this.toggleFullscreen(aroundDomElement);

      this.toggleMetaTags();
    } else {
      if (screenfull.isEnabled) {
        if (screenfull.isFullscreen !== this._fullscreen) {
          screenfull.toggle(aroundDomElement);
        }
      } else {
        this.toggleFullscreen(aroundDomElement);
      }
    }

    if (!this._fullscreen) {
      this.exitCallback && this.exitCallback();
    }

    this.fullScreenChange && this.fullScreenChange();
  };

  toggleFullscreen = (domElement) => {
    if (this._fullscreen) {
      addClass(domElement, 'amav-fullscreen');
    } else {
      removeClass(domElement, 'amav-fullscreen');
    }
  };

  registerFullscreenEscapeListener = () => {
    // Only listen to fullscreen change if not on mobile devices!
    if (!this._isMobile) {
      if (screenfull.isEnabled) {
        document.addEventListener(screenfull.raw.fullscreenchange, () => {
          if (!screenfull.isFullscreen) {
            this.setFullscreen(false);
          }
        });
      }
    }
  };

  // META TAGS HACK
  toggleMetaTags = () => {
    if (!this._fullscreen) {
      setTimeout(() => {
        this.applyToViewportTags(this.originalTagContent);
      }, 300);
    } else {
      setTimeout(() => {
        this.setViewportTagsWithWidth(this._containerWidth);
      }, 300);
    }
  };

  setViewportTagsWithWidth = (width) => {
    this.applyToViewportTags(
      `width=${
        width || 'device-width'
      }, initial-scale=1.0, minimum-scale = 1.0, maximum-scale = 1.0, minimal-ui`
    );
  };

  applyToViewportTags = (content) => {
    document.head.removeChild(document.querySelector('meta[name=viewport]'));
    const meta = document.createElement('meta');
    meta.name = 'viewport';
    meta.content = content;
    document.getElementsByTagName('head')[0].appendChild(meta);
  };

  get isFullscreen() {
    return this._fullscreen;
  }

  set containerWidth(width) {
    this._containerWidth = width;
  }

  set isMobile(bool) {
    this._isMobile = bool;
  }

  set onExitFullscreenCallback(func) {
    if (typeof func === 'function') {
      this.exitCallback = func;
    }
  }

  set onFullScreenChange(func) {
    if (typeof func === 'function') {
      this.fullScreenChange = func;
    }
  }

  get inIframe() {
    return this._inIframe;
  }

  get isDisabled() {
    return this._isDisabled;
  }
}

export default new FullScreen();
