// Base
// import React from 'react';
import * as THREE from 'three/build/three.min';
import BaseComponent from '@aroundmedia/frontend-library/lib/shared/basecomponent/BaseComponent';

import SphereHelper from '../../helpers/sphere-helper';

export default class SpherePart extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      metadata: SphereHelper.getSpherePartMetaData(props.parts, props.part),
      currentSpot: this.props.currentSpot,
      cols: SphereHelper.colsForParts(this.props.parts),
      rows: SphereHelper.rowsForParts(this.props.parts)
    };

    this.mesh = new THREE.Mesh(
      new THREE.SphereGeometry(
        1,
        this.state.metadata.segments,
        this.state.metadata.segments,
        this.state.metadata.phiStart,
        this.state.metadata.phiLength,
        this.state.metadata.thetaStart,
        this.state.metadata.thetaLength
      ),
      new THREE.MeshBasicMaterial({ color: 0x000000 })
    );

    this.mesh.material.side = THREE.DoubleSide;
    this.mesh.name = `part ${this.props.part}`;

    this.props.sphere.add(this.mesh);

    // Set the spherePart Material when the lowResResolves
    // data comes back as: { texture, objectId: this.objectId }
    this.updateCurrentSpot();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.currentSpot.objectId &&
      nextProps.currentSpot.objectId !== this.props.currentSpot.objectId
    ) {
      this.setState(
        {
          currentSpot: nextProps.currentSpot
        },
        () => {
          this.updateCurrentSpot();
        }
      );
    }
  }

  updateSphereFilter = () => {
    let brightnessValue = 0.0;
    let contrastValue = 0.0;
    let hueValue = 0.0;
    let saturationValue = 0.0;
    let warmthValue = 0.0;

    const filterList = this.props.currentSpot.filterList;
    if (filterList !== undefined && filterList !== null) {
      for (let i = 0; i < filterList.length; i++) {
        const filterType = filterList[i].type;
        const filterValue = filterList[i].value;

        if (filterType === 'brightness') {
          brightnessValue = filterValue;
        } else if (filterType === 'contrast') {
          contrastValue = filterValue;
        } else if (filterType === 'hue') {
          hueValue = filterValue;
        } else if (filterType === 'saturation') {
          saturationValue = filterValue;
        } else if (filterType === 'warmth') {
          warmthValue = filterValue;
        } else {
          window.console.log(`Unknown filter type for panorama: ${filterType}`);
        }
      }
    }

    this.mesh.material.uniforms.uBrightness.value = brightnessValue;
    this.mesh.material.uniforms.uContrast.value = contrastValue;
    this.mesh.material.uniforms.uHue.value = hueValue;
    this.mesh.material.uniforms.uSaturation.value = saturationValue;
    this.mesh.material.uniforms.uWarmth.value = warmthValue;
    this.mesh.material.needsUpdate = true;
  };

  updateCurrentSpot() {
    // Set the spherePart Material when the lowResResolves
    this.props.currentSpot.lowResTexture.then((lowData) => {
      if (lowData.objectId !== this.props.currentSpot.objectId) {
        return;
      }

      const tex = lowData.texture;
      tex.magFilter = THREE.LinearFilter;
      tex.minFilter = THREE.LinearMipMapLinearFilter;
      tex.anisotropy = this.props.maxAni;

      if (this.mesh.material.type === 'MeshBasicMaterial') {
        this.mesh.material = SphereHelper.createSphereMaterial(
          tex,
          THREE,
          this.state.cols,
          this.state.rows
        );
        this.mesh.material.uniforms.uUVOffset.value = this.state.metadata.spherePartUVOffset;
        this.mesh.material.side = THREE.DoubleSide;
        this.props.onLowResLoad && this.props.onLowResLoad();
      } else {
        this.mesh.material.uniforms.uTex.value = tex;
        this.mesh.material.uniforms.uUVOffset.value = this.state.metadata.spherePartUVOffset;
        this.mesh.material.uniforms.uUVParts.value = new THREE.Vector2(
          this.state.cols,
          this.state.rows
        );
      }

      this.updateSphereFilter();
      this.mesh.material.needsUpdate = true;
      this.props.renderLoop && this.props.renderLoop.kick();

      // Mobile hack until we optimize texture use
      if (!this.props.isMobile) {
        // As the hiRes for this part resolves, we should add them to the sphere
        this.props.currentSpot.highResTextures[this.props.part].then(
          (hiData) => {
            if (hiData.objectId !== this.props.currentSpot.objectId) {
              return;
            }
            const hiTex = hiData.texture;
            hiTex.magFilter = THREE.LinearFilter;
            hiTex.minFilter = THREE.LinearMipMapLinearFilter;
            hiTex.anisotropy = this.props.maxAni;

            this.mesh.material.uniforms.uTex.value = hiTex;
            this.mesh.material.uniforms.uUVOffset.value = new THREE.Vector2(
              0.0,
              0.0
            );
            this.mesh.material.uniforms.uUVParts.value = new THREE.Vector2(
              1.0,
              1.0
            );
            this.mesh.material.needsUpdate = true;

            this.props.renderLoop && this.props.renderLoop.kick();
          }
        );
      }
    });
  }

  render() {
    return false;
  }
}
