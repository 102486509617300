// Imports
// Base

export default class LogoShaders {
    // TODO - check if the shaders exist yet, don't append them if they do

    /**
     * chreate the vertexShader
     * @param scriptId
     * @returns {Element}
     */
    static createVertexShader(scriptId) {
        const script = document.createElement('script');
        script.id = scriptId;
        script.type = 'x-shader/x-vertex';
        script.textContent =
            `varying vec2 vUv;
             void main() {
               vUv = uv;
               gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
            }`;
        document.head.appendChild(script);
        return script;
    }

    /**
     * create the fragment shader
     * @param scriptId
     * @returns {Element}
     */
    static createFragmentShader(scriptId) {
        const script = document.createElement('script');
        script.id = scriptId;
        script.type = 'x-shader/x-fragment';
        script.textContent =
            `precision mediump float;
            varying vec2 vUv;
            uniform sampler2D uTex;

            void main() {
              vec4 color = texture2D(uTex, vUv);
              float d = distance(vec2(0.5, 0.5), vUv);
              if (d > 0.5) { color.a = 0.0; }
              gl_FragColor = color;
            }`;
        document.head.appendChild(script);
        return script;
    }
}
