// Imports
// Base

// Exports
export default class LogoHelper {
    /**
     * create the sphere material that goes on the sphere
     * @param tex - the texture to be applied
     * @param THREE - the THREE context
     * @returns {THREE.ShaderMaterial}
     */
    static createLogoMaterial(tex, THREE) {
        const uniforms = {
            uTex: { type: 't', value: tex }
        };

        return new THREE.ShaderMaterial({
            uniforms,
            vertexShader: document.getElementById('vShader-logo').textContent,
            fragmentShader: document.getElementById('fShader-logo').textContent
        });
    }
}
