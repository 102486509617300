import React, { useReducer, createContext } from 'react';
import { ViewModes } from './config/types/ViewModes';
export const MiniStoreContext = createContext();

const initialState = {
  viewMode: ViewModes.normal
};

const MiniStore = (props) => {
  const [appState, appStateDispatch] = useReducer((state, action) => {
    switch (action?.type) {
      case 'reset':
        return {
          ...initialState
        };
      case 'changeViewmode':
        return { ...state, viewMode: action.payload };
      default:
        return state;
    }
  }, initialState);
  return (
    <MiniStoreContext.Provider value={{ appState, appStateDispatch }}>
      {props.children}
    </MiniStoreContext.Provider>
  );
};

export const changeViewModeAction = (type) => ({
  type: 'changeViewmode',
  payload: type
});

export function withMiniStore(Component) {
  return function componentWithStore(props) {
    return (
      <MiniStoreContext.Consumer>
        {(contexts) => <Component {...props} {...contexts} />}
      </MiniStoreContext.Consumer>
    );
  };
}

export default MiniStore;
