// Imports
// Base
import { ViewerSDK } from '@aroundmedia/frontend-library/lib/sdks/ViewerSDK';
import { getQueryParameter } from '@aroundmedia/frontend-library/lib/shared/helpers/General';

// import * as TwilioIp from 'twilio-ip-messaging/dist/twilio-ip-messaging.min';
// import * as TwilioCommon from 'twilio-common/dist/twilio-common.min';

// import { AccessManager } from 'twilio-common';
// import IPMessaging from 'twilio-ip-messaging';
import { generateRandomString, loadScriptByUrlAndId } from './helpers';

// const Twilio = window.Twilio;

// TODO - MAKE THIS PROMISES
// Exports
class TwilioManager {
  constructor() {
    this.currentMessages = [];
    this.addedLocations = [];

    loadScriptByUrlAndId(
      'https://media.twiliocdn.com/sdk/js/common/v0.1/twilio-common.min.js',
      'twilio-common'
    );
    loadScriptByUrlAndId(
      'https://media.twiliocdn.com/sdk/js/chat/v3.2/twilio-chat.min.js',
      'twilio-chat'
    );

    // loadScriptByUrlAndId('https://media.twiliocdn.com/sdk/rtc/js/ip-messaging/v0.10/twilio-ip-messaging.min.js',
    // 'twilio-ip-messaging');
  }
  /**
   * Start a new customer ticket
   * @param {string} identifier
   * @param {string} albumObjectId
   * @param callback
   */
  startCustomerTicket = (identifier, albumObjectId, callback) => {
    this.currentMessages = [];

    const generatedChatChannelName = `tour_${albumObjectId}_${generateRandomString(
      5
    )}`;
    // const generatedChatChannelName = 'tour_1468918544699-8B7K3GjSpO_X1CP6';

    this.createTwilioToken(generatedChatChannelName, identifier, albumObjectId)
      .then((data) => {
        this.createTwilioClient(
          data.chatToken,
          data.chatIdentity,
          generatedChatChannelName,
          callback
        );
      })
      .catch((error) => {
        window.console.log(`error: ${error}`);
      });
  };

  /**
   * returns the promise to a new customer ticket
   * @param callData
   * @param albumObjectId
   */
  createCustomerTicket = (callData, albumObjectId) =>
    ViewerSDK.createNewCustomerTicket(callData, albumObjectId);

  /**
   * create a token to 'auth' with the chat client
   * @param {string} channelName
   * @param {string} identity
   * @param {string} albumObjectId
   */
  createTwilioToken = (channelName, identity, albumObjectId) =>
    ViewerSDK.getChatToken({ email: identity, albumObjectId }, albumObjectId);

  /**
   * Create a new Twilio client
   * @param {string} chatToken
   * @param {string} identity
   * @param {string} channelName
   * @param {*} callback - What to do when Twilio Channel is created and set up.
   */
  createTwilioClient = (chatToken, identity, channelName, callback) => {
    const Twilio = window.Twilio;

    this.accessManager = Twilio.AccessManager(chatToken);

    this.chatChannelName = channelName;
    this._chatIdentity = identity;

    Twilio.Chat.Client.create(chatToken, this.accessManager).then((client) => {
      this.messagingClient = client;

      this.messagingClient.getChannelByUniqueName(this.chatChannelName).then(
        (channel) => {
          this.chatChannel = channel;

          if (!this.chatChannel) {
            // no chat channel found: Create new channel
            this.createTwilioChannel(this.messagingClient, callback);
          } else {
            // Existing chat channel found: Join it!
            this.setupTwilioChannel(this.chatChannel.sid, callback);
          }
        },
        () => {
          // no chat channel found: Create new channel
          this.createTwilioChannel(this.messagingClient, callback);
        }
      );
    });
  };

  /**
     //      * createTwilioChannel -  Create channel if it doesn't exist yet
     //      * @param messagingClient
     //      * @param callback
     //      */
  createTwilioChannel = (messagingClient, callback) => {
    messagingClient
      .createChannel({
        uniqueName: this.chatChannelName,
        friendlyName: 'Around chat'
      })
      .then(
        (resultingChannel) => {
          this.chatChannel = resultingChannel;
          this.setupTwilioChannel(this.chatChannel.sid, callback);
        },
        (error) => {
          window.console.log(`error: ${JSON.stringify(error)}`); // TODO - handle it maybe
        }
      );
  };

  /**
     //      * setupTwilioChannel -  Set up channel after it has been found
     //      * @param chatChannelSID
     //      * @param callback
     //      */
  setupTwilioChannel = (chatChannelSID, callback) => {
    // Check if member has already joined channel before
    if (this.chatChannel.state.status !== 'joined') {
      // Join the general channel
      this.chatChannel.join().then((channel) => {
        // Get the messages
        this.getChannelMessages(chatChannelSID, channel, callback);
      });
    } else {
      // Already joined, continue loading messages
      this.getChannelMessages(chatChannelSID, this.chatChannel, callback);
    }

    // Listen for new messages sent to the channel
    this.chatChannel.on('messageAdded', (message) => {
      this.currentMessages.push(message);

      if (message) {
        const body = JSON.parse(message.body);
        if (body && body.isLocation) {
          this.addLocation(body.spotObjectId, body.spotPoint);
        }
      }

      this.onMessageAdd && this.onMessageAdd();
    });

    // this.chatChannel.on('memberJoined', (member) => {
    //     const body = {
    //         text: `online: ${member.identity}`,
    //         isLocation: false
    //     };
    //
    //     // printMessage(null, JSON.stringify(body), true);
    // });
    //
    // this.chatChannel.on('memberLeft', (member) => {
    //     const body = {
    //         text: `offline: ${member.identity}`,
    //         isLocation: false
    //     };
    //
    //     // if (member.identity != chatIdentity) {
    //     //     printMessage(null, JSON.stringify(body), true);
    //     // }
    // });
  };

  /**
     //      * getChannelMessages -  Get the channel messages after joining the chat
     //      * @param chatChannelSID
     //      * @param channel
     //      * @param callback
     //      */
  getChannelMessages = (chatChannelSID, channel, callback) => {
    channel.getMessages().then(
      (messages) => {
        if (
          typeof messages.items !== 'undefined' &&
          messages.items !== null &&
          messages.items.length > 0
        ) {
          messages.items.forEach((message) => {
            const msg = JSON.parse(message.body);
            if (message && msg && !msg.isGiphy) {
              this.currentMessages.push(message);
              if (msg && msg.isLocation) {
                this.addLocation(msg.spotObjectId, msg.spotPoint);
              }
            }
          });
        }

        this._channelJoined = true;

        callback(this.chatChannelName, chatChannelSID);
      },
      () => {
        callback(this.chatChannelName, chatChannelSID);
      }
    );
  };

  /**
   * Create a message body
   * @param {string} text
   * @param {string} albumObjectId
   * @param {string} spotObjectId
   * @param {boolean} isLocation
   * @param {THREE.Vector3} position
   */
  createMessage = (text, albumObjectId, spotObjectId, isLocation, position) => {
    const pos = position; // || A.main.getCamera().position; TODO - GET CAM POS
    const spotPoint = [pos.x.toFixed(3), pos.y.toFixed(3), pos.z.toFixed(3)];

    const body = {
      text,
      albumObjectId,
      spotObjectId,
      isLocation,
      spotPoint,
      isGiphy: false
    };

    // adding it to the pois will happen when it comes in as a message

    return JSON.stringify(body);
  };

  /**
   * Send a new massage
   * @param {string} text
   * @param {string} albumObjectId
   * @param {string} spotObjectId
   * @param {boolean} isLocation
   * @param {THREE.Vector3} position
   * @param [callback]
   */
  sendMessage = (
    text,
    albumObjectId,
    spotObjectId,
    isLocation,
    position,
    callback
  ) => {
    const value = text || '';
    this.chatChannel
      .sendMessage(
        this.createMessage(
          value,
          albumObjectId,
          spotObjectId,
          isLocation,
          position
        )
      )
      .then(() => {
        // TODO - ENABLE ANALYTICS HERE
        // A.analytics.sendTrackOnChatMessageSent();

        callback && callback();
      });
  };

  addLocation = (spotObjectId, point) => {
    const id = generateRandomString(5);
    const location = {
      id,
      spotObjectId,
      point
    };
    this.addedLocations.push(location);

    return id;
  };

  clearLocations = () => {
    this.addedLocations = [];
  };

  clearLocationWithId = (id) => {
    this.addedLocations = this.addedLocations.filter(
      (location) => location.id !== id
    );
  };

  getLocationWithId = (id) => {
    let location;
    this.addedLocations.forEach((loc) => {
      if (loc.id === id) {
        location = loc;
      }
    });

    return location;
  };

  get messages() {
    return this.currentMessages;
  }

  get locations() {
    return this.addedLocations;
  }

  set onMessageAddCallback(onMessageAdd) {
    this.onMessageAdd = onMessageAdd;
  }

  set reconnecting(bool) {
    this._reconnecting = bool;
  }

  get reconnecting() {
    return this._reconnecting;
  }

  set reconnected(bool) {
    this._reconnected = bool;
  }

  get reconnected() {
    return this._reconnected;
  }

  get channelJoined() {
    return this._channelJoined;
  }

  get chatIdentity() {
    return this._chatIdentity;
  }

  hasChannelInfo = () => {
    const chatChannelName = getQueryParameter('chatChannelName');
    const chatChannelIdentifier = getQueryParameter('chatChannelIdentifier');

    return chatChannelName && chatChannelIdentifier;
  };

  /**
   * attampt to reconnect to a existing channel
   */
  attemptReconnect = (callback) => {
    const chatChannelName = getQueryParameter('chatChannelName');
    const chatChannelIdentifier = getQueryParameter('chatChannelIdentifier');

    const albumObjectIdFromChannel = chatChannelIdentifier.split('_')[1];

    if (chatChannelName && chatChannelIdentifier) {
      this.createTwilioToken(
        chatChannelName,
        chatChannelIdentifier,
        albumObjectIdFromChannel
      )
        .then((data) => {
          this.createTwilioClient(
            data.chatToken,
            data.chatIdentity,
            chatChannelName,
            callback
          );
        })
        .catch((error) => {
          window.console.log(`error: ${error}`);
        });
    }
  };
}

export default new TwilioManager();

//
// /**
//  * Twilio calls helpers bundled as statics in this chatmanager
//  */
// (function (A, C, U, $am) {
//     'use strict';
//
//     var tcm = {
//         previousAuthor: ''
//     };
//     var accessManager;
//     var messagingClient;
//     var chatChannel;
//     var chatChannelName;
//     var chatIdentity;
//     var DOMroot = '.amv-chat-items';
//     var DOMChatRoot = '.amv-chat-messages-container';
//     var DOMInput = '.amv-chat-input';
//     tcm.locations = [];
//
//     /**
//      * start a new customer ticket
//      */
//     tcm.startCustomerTicket = function (identifier, callback) {
//         var generatedChatChannelName = 'tour_' + A.dataCtrl.tour.objectId + '_' + A.utils.generateRandomString(5);
//
//         createTwilioToken(
//             generatedChatChannelName,
//             identifier,
//             function (data) {
//                 createTwilioChannel(
//                     data.chatToken,
//                     data.chatIdentity,
//                     generatedChatChannelName,
//                     callback
//                 );
//             },
//
//             function (error) {
//                 console.log('error: ' + JSON.stringify(error));
//             }
//         );
//     };
//
//     tcm.hasChannelInfo = function () {
//         var chatChannelName = U.getQueryVariableViewer('chatChannelName');
//         var chatChannelIdentifier = U.getQueryVariableViewer('chatChannelIdentifier');
//
//         return chatChannelName && chatChannelIdentifier;
//     };
//
//     /**
//      * attampt to reconnect to a existing channel
//      */
//     tcm.attemptReconnect = function (callback) {
//         var chatChannelName = U.getQueryVariableViewer('chatChannelName');
//         var chatChannelIdentifier = U.getQueryVariableViewer('chatChannelIdentifier');
//
//         if (chatChannelName && chatChannelIdentifier) {
//             createTwilioToken(
//                 chatChannelName,
//                 chatChannelIdentifier,
//                 function (data) {
//                     createTwilioChannel(
//                         data.chatToken,
//                         data.chatIdentity,
//                         chatChannelName,
//                         callback
//                     );
//                 },
//
//                 function (errorMessage) {
//                     console.log('error reconnecting to channel');
//                 }
//             );
//         }
//     };
//
//     tcm.createMessage = function (text, isLocation, position) {
//         var pos = position || A.main.getCamera().position;
//         var spotPoint = [pos.x.toFixed(3),
//             pos.y.toFixed(3),
//             pos.z.toFixed(3)];
//
//         var body = {
//             text: text,
//             albumObjectId: A.dataCtrl.tour.objectId,
//             spotObjectId: A.dataCtrl.currentSpotObjectId,
//             spotPoint: spotPoint,
//             isLocation: isLocation,
//             isGiphy: false
//         };
//
//         //adding it to the pois will happen when it comes in as a message
//
//         return JSON.stringify(body);
//     };
//
//     tcm.sendMessage = function (text, isLocation, callback, position) {
//         var value = text || $am(DOMInput).val() || '';
//         chatChannel.sendMessage(tcm.createMessage(value, isLocation, position)).then(function () {
//
//             A.analytics.sendTrackOnChatMessageSent();
//
//             callback && callback();
//         });
//     };
//
//     tcm.scrollChatToBottom = function () {
//         $am(DOMChatRoot).scrollTop($am(DOMChatRoot)[0].scrollHeight);
//     };
//
//     tcm.getLocationPois = function () {
//         var pois = [];
//         for (var i in tcm.locations) {
//             (function (iter) {
//                 var poi = {
//                     objectId: 'loc',
//                     type: 2,
//                     point: U.flipPoint(tcm.locations[iter].spotPoint),
//                     isPlaceholder: false,
//                     linkReference: {
//                         objectId: tcm.locations[iter].objectId
//                     },
//                     linkType: 'location_point_ref'
//                 };
//
//                 if (poi.linkReference.objectId === A.dataCtrl.currentSpotObjectId) {
//                     pois.push(poi);
//                 }
//             })(i);
//         }
//
//         return pois;
//     };
//
//     /**
//      * add a location POI
//      * @param body - message body
//      * @param body.spotUUID - message body
//      * @param body.spotPoint - message body
//      * @param body.tourObjectId - message body
//      */
//     tcm.addLocationPOI = function (body) {
//         tcm.locations.push(new U.positionObject(
//             body.spotObjectId,
//             body.spotPoint,
//             body.albumObjectId
//         ));
//     };
//
//     /**
//      * clear location points
//      */
//     tcm.clearLocationPoints = function () {
//         tcm.locations = [];
//     };
//
//     //PRIVATES AND HELPERS
//     $am(window).on('beforeunload', function () {
//         if (chatChannel !== undefined && chatChannel !== null) {
//             chatChannel.leave();
//         }
//     });
//
//     /** printInfo - Helper function to print info messages to the chat window
//      * @param {*} infoMessage
//      * @param {boolean} asHtml
//      */
//     var printInfo = function (infoMessage, asHtml) {
//
//         var $msg = $am('<div class="amv-chat-info">');
//         if (asHtml) {
//             $msg.html(infoMessage.text);
//         } else {
//             $msg.text(infoMessage.text);
//         }
//
//         // $am(DOMChatRoot).append($msg);
//     };
//
//     /** printMessage - Helper function to print chat messages to the chat window
//      * @param {string|null} fromUser
//      * @param {*} message
//      * @param {boolean} italic
//      */
//     var printMessage = function (fromUser, message, italic) {
//         if (message) {
//             message = JSON.parse(message);
//         }
//
//         if (message.isGiphy) {
//             return;
//         }
//
//         var $container = $am('<div class="message-container">');
//         var userIsMe = false;
//         if (fromUser) {
//             userIsMe = fromUser === chatIdentity;
//
//             var author = tcm.previousAuthor !== fromUser ? fromUser : null;
//             tcm.previousAuthor = fromUser;
//             if (author) {
//                 author = author.split('---SkynetRules---')[1] || author;
//
//                 var $user = $am('<span class="username">').text(author + ':');
//                 $user.toggleClass('me', userIsMe);
//
//                 $container.append($user);
//             }
//
//             $container.toggleClass('me', userIsMe);
//
//         }
//
//         var $messageWrapper = $am('<div class="message-wrapper">');
//         var $message = $am('<span class="message">').text(message.text);
//
//         if (message.isLocation) {
//             var text = userIsMe ? C.aLocStr.CTMUserSharedLocation : C.aLocStr.CTMGeneralSharedLocation;
//             $message = $am('<span class="message">').text(text);
//             var $icon = $am('<i class="material-icons icon-my-location active">');
//
//             $messageWrapper.addClass('isLocation');
//             $messageWrapper.append($icon);
//             $messageWrapper.on('click', function () {
//                 A.main.orientCameraInTour({
//                     lookAtPoint: message.spotPoint,
//                     objectId: message.spotObjectId
//                 });
//             });
//
//             tcm.addLocationPOI(message);
//
//         }
//
//         $messageWrapper.append($message);
//
//         $container.append($messageWrapper);
//         if (italic) {
//             $container.addClass('italic');
//         }
//
//         $am(DOMChatRoot).append($container);
//         $am(DOMChatRoot).scrollTop($am(DOMChatRoot)[0].scrollHeight);
//     };
//
//     /**
//      * create a token to 'auth' with the chat client
//      * @param {string} channelName
//      * @param {string} identity
//      * @param {*} successCallback
//      * @param {*} failedCallback
//      */
//     var createTwilioToken = function (channelName, identity, successCallback, failedCallback) {
//         var params = {
//             email: identity,
//             albumObjectId: A.dataCtrl.tour.objectId
//         };
//
//         A.dataCtrl.getMessagingChatToken(
//             params,
//             function (data) {
//                 successCallback && successCallback(data);
//             },
//
//             function (errMsg) {
//                 console.log('error creating token: ' + errMsg);
//                 failedCallback && failedCallback(errMsg);
//             }
//         );
//     };
//
//     /**
//      * Create a new Twilio channel or connect to it if needed
//      * @param {string} chatToken
//      * @param {string} identity
//      * @param {string} channelName
//      * @param {*} callback - What to do when Twilio Channel is created and set up.
//      */
//     var createTwilioChannel = function (chatToken, identity, channelName, callback) {
//         accessManager = new Twilio.AccessManager(chatToken);
//         messagingClient = new Twilio.IPMessaging.Client(accessManager);
//
//         chatChannelName = channelName;
//         chatIdentity = identity;
//
//         messagingClient.getChannelByUniqueName(chatChannelName).then(function (channel) {
//             chatChannel = channel;
//             if (!chatChannel) {
//                 // If it doesn't exist, let's create it
//                 messagingClient.createChannel({
//                     uniqueName: chatChannelName,
//                     friendlyName: 'Around chat'
//                 }).then(function (channel) {
//                         chatChannel = channel;
//
//                         setupTwilioChannel(chatChannel.sid, callback);
//                     }, function (error) {
//
//                         console.log('error: ' + JSON.stringify(error));
//                     }
//                 );
//             } else {
//                 setupTwilioChannel(chatChannel.sid, callback);
//             }
//         }, function (error) {
//
//             console.log('error: ' + JSON.stringify(error));
//         });
//     };
//
//     /**
//      * setupTwilioChannel -  Set up channel after it has been found
//      * @param chatChannelSID
//      * @param callback
//      */
//     var setupTwilioChannel = function (chatChannelSID, callback) {
//         // Join the general channel
//         chatChannel.join().then(function (channel) {
//
//             channel.getMessages().then(
//                 function (messagesHistory) {
//                     if (messagesHistory !== undefined && messagesHistory !== null && messagesHistory.length > 0) {
//
//                         for (var i = 0; i < messagesHistory.length; i++) {
//                             var message = messagesHistory[i];
//                             printMessage(message.author, message.body, false);
//                             var mBody = JSON.parse(message.body);
//                         }
//                     }
//
//                     A.spotsCtrl.rebuildNavPoints();
//                     setTimeout(A.spotsCtrl.updateNavPoints, 40);
//
//                     callback(chatChannelName, chatChannelSID);
//                 }, function (error) {
//
//                     callback(chatChannelName, chatChannelSID);
//                 });
//
//         });
//
//         // Listen for new messages sent to the channel
//         chatChannel.on('messageAdded', function (message) {
//
//             printMessage(message.author, message.body, false);
//
//             if (message && JSON.parse(message.body).isLocation) {
//                 A.spotsCtrl.rebuildNavPoints();
//                 setTimeout(A.spotsCtrl.updateNavPoints, 40);
//             }
//         });
//
//         chatChannel.on('memberJoined', function (member) {
//             var body = {
//                 text: 'online: ' + member.identity,
//                 isLocation: false
//             };
//
//             //printMessage(null, JSON.stringify(body), true);
//
//         });
//
//         chatChannel.on('memberLeft', function (member) {
//             var body = {
//                 text: 'offline: ' + member.identity,
//                 isLocation: false
//             };
//
//             // if (member.identity != chatIdentity) {
//             //     printMessage(null, JSON.stringify(body), true);
//             // }
//         });
//     };
//
//     amViewer.twilioChatManager = tcm;
//
// })(amViewer, amViewer.config, amViewer.utils, $am);
