import React, { useEffect, useState, useRef } from 'react';
import { shape, string, func } from 'prop-types';
import { VRButton } from 'three/examples/jsm/webxr/VRButton.js';

const VRStartButton = ({
  renderer,
  buttonContent,
  onClicked,
  returnToNormalMode
}) => {
  const buttonContainerRef = useRef();
  const [isPresenting, setPresenting] = useState(renderer.xr.isPresenting);

  useEffect(() => {
    const buttonDomElement = VRButton.createButton(renderer);
    buttonDomElement.id = 'vrbutton-other-id';
    buttonDomElement.class = 'amav-vr-start-button';

    setTimeout(() => {
      buttonDomElement.style = '';
      buttonDomElement.innerHTML = buttonContent;
      buttonContainerRef.current.appendChild(buttonDomElement);
    }, 100);
  }, []);

  useEffect(() => {
    setPresenting((pres) => {
      // here we can listen for changes
      if (pres !== renderer.xr.isPresenting) {
        if (renderer.xr.isPresenting) {
          onClicked();
        } else {
          returnToNormalMode();
        }
        return renderer.xr.isPresenting;
      }
      return pres;
    });
  }, [renderer.xr.isPresenting]);

  return (
    <div
      key="vr"
      style={{ position: 'relative', display: 'flex' }}
      ref={buttonContainerRef}
    ></div>
  );
};

VRStartButton.propTypes = {
  renderer: shape({}),
  buttonContent: string,
  onClicked: func.isRequired,
  returnToNormalMode: func.isRequired
};

export default VRStartButton;
