// Base
// import React from 'react';
import BaseComponent from '@aroundmedia/frontend-library/lib/shared/basecomponent/BaseComponent';
// import SpotLinkHelper from '../../helpers/spotlink-helper';
import { ViewerSDK } from '@aroundmedia/frontend-library/lib/sdks/ViewerSDK';

export default class SpotLink3D extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = props;

    const spriteMaterial = new THREE.SpriteMaterial({ color: 0xffffff });
    this.sprite = new THREE.Sprite(spriteMaterial);

    this.props.scene.add(this.sprite);

    this.count = 0;
    this.counter = null;
    this.countingDown = false;

    this.textures = null;
    this.posObject = null;

    this.sprite.position.set(
      this.props.posObject.Point3D.x,
      this.props.posObject.Point3D.y,
      this.props.posObject.Point3D.z
    );
    this.sprite.scale.set(50, 50, 1.0); // imageWidth, imageHeight

    this.maxCount = 3;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.textures && nextProps.textures !== this.textures) {
      this.textures = nextProps.textures;

      this.sprite.material.map = this.textures[0];
      this.sprite.material.map.needsUpdate = true;
    }

    if (
      this.textures &&
      nextProps.posObject &&
      nextProps.posObject !== this.posObject
    ) {
      this.posObject = nextProps.posObject;

      if (!this.countingDown) {
        if (
          nextProps.posObject.distance < 75.0 &&
          this.props.frustum.containsPoint(this.props.posObject.Point3D)
        ) {
          this.startCountDown();
        }
      } else {
        if (nextProps.posObject.distance > 75.0) {
          this.cancelCountDown();
        }
      }
    }
    // Option For Checking distance to center in 3D
    // This gets the vector between the camera and the object.
    // var objectDir = new THREE.Vector3().subVectors(objectWorldPos, cameraWorldPos).normalize();

    // By checking the angle between this vector and the lookat of the camera we know that the object
    // gets closer to the center of the screen if the as the angle approaches zero.
  }

  startCountDown = () => {
    this.countingDown = true;
    this.count = 0;
    this.counter = setInterval(this.tick, 1000);
  };

  tick = () => {
    this.count += 1;
    const textureIndex = this.maxCount + 1 - this.count;
    this.setTextureTo(textureIndex);
    window.console.log('ticked');

    if (this.count >= this.maxCount) {
      this.finishCountDown();
    }
  };

  finishCountDown = () => {
    this.count = 0;
    clearInterval(this.counter);
    setTimeout(() => {
      ViewerSDK.setCurrentSpot(this.props.data.linkReference.objectId);
    }, 1000);
  };

  cancelCountDown = () => {
    this.count = 0;
    clearInterval(this.counter);
    this.countingDown = false;
    this.setTextureTo(0);
  };

  setTextureTo = (index) => {
    if (this.textures) {
      this.sprite.material.map = this.textures[index];
      this.sprite.material.map.needsUpdate = true;
    }
  };

  componentWillUnmount() {
    this.cancelCountDown();
    this.props.scene.remove(this.sprite);
  }

  render() {
    return false;
  }
}
