// Imports
// Base
import { Vector2 } from 'three';

// Exports
/**
 * Spotlink helper Class
 */
export default class SpotLinkHelper {
  /**
   * Get the 2D point for a point in 3D
   * @param point3D
   * @param cam
   *
   * @param {*} view
   * @param {Int} view.width
   * @param {Int} view.height
   *
   * @return {Vector2}
   */
  static get2Dfor3D(point3D, cam, view) {
    // This creates a normalised Vector2
    const tempVec = point3D.clone().project(cam);
    const point2D = new Vector2(tempVec.x, tempVec.y);

    // This inverts the normalized Vector2 and applies the canvas size to it.
    point2D.x = ((point2D.x + 1) / 2) * view.clientWidth;
    point2D.y = (-(point2D.y - 1) / 2) * view.clientHeight;

    return point2D;
  }

  /**
   * Get the 3D point scaled up to the size of the sphere
   * @param {THREE.Vector3} point - normalised 3D point
   * @param offset
   */
  static get3DPointOnSphere(point, offset = 0) {
    const point3D = point;
    const scaleMultiplier = 500 - offset; // TODO - Fix Magic Number

    point3D.multiplyScalar(scaleMultiplier);
    return point3D;
  }
}
