// Base
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// SFL
import BaseComponent from '@aroundmedia/frontend-library/lib/shared/basecomponent/BaseComponent';
// import { ViewerSDK } from '@aroundmedia/frontend-library/lib/sdks/ViewerSDK';

import ENV from '../../../config/active-environment/env.json';
import UIWidget from './UIWidget';

// Helpers
/**
 * Create a gallery entry
 * @param imageUrl
 * @param title
 * @param objectId
 * @param key
 * @param currentSpotObjectId
 * @param onEntryClickHandler
 */
const createGalleryEntry = (
  imageUrl,
  title,
  objectId,
  key,
  currentSpotObjectId,
  onEntryClickHandler
) => (
  <div
    className={classnames({
      content__entry: true, // eslint-disable-line
      'content__entry-active': objectId === currentSpotObjectId
    })}
    style={{
      backgroundImage: `url(${ENV.viewerUrl}assets/image-placeholder.jpg)`
    }}
    key={key}
    onClick={() => onEntryClickHandler && onEntryClickHandler(objectId)}
  >
    <img
      className="content__image"
      src={`${ENV.baseImageUrl}/h140/${imageUrl}`}
      alt="gallery-entry"
    />
    <div className="content__overlay" />
    <div className="content__title">
      <div className="content__title__gradient" />
      <div className="content__title__text">{title}</div>

      <div
        className={classnames({
          'material-icons': true,
          'amav-icon': true,
          content__title__icon: true,
          'amav-icon--keyboard-arrow-right':
            !currentSpotObjectId || objectId !== currentSpotObjectId
        })}
      />
    </div>
  </div>
);

/**
 * parse the spot list and return an array of gallery entries
 * @param dataList
 * @param currentSpotObjectId
 * @param onEntryClickHandler
 * @return {Array}
 */
const parseGalleryDataList = (
  dataList,
  currentSpotObjectId,
  onEntryClickHandler
) => {
  const array = [];
  dataList
    .sort((a, b) => a.index - b.index)
    .forEach((item, index) => {
      if (!item.hideInGallery) {
        array.push(
          createGalleryEntry(
            item.panoramaUrl,
            item.name,
            item.objectId,
            index,
            currentSpotObjectId,
            onEntryClickHandler
          )
        );
      }
    });

  return array;
};

/**
 * UIGallery Widget Component
 */
class UIGalleryWidget extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = props;

    // TODO - enable as prop too
    this.title = this.props.localizer.get('gallery');
  }

  onEntryClick = (objectId) => {
    if (objectId !== this.props.currentSpot.objectId) {
      this.props.callSpotChangeEvent &&
        this.props.callSpotChangeEvent(objectId);
    }

    if (this.props.isMobile) {
      this.props.onCloseClick(this.state.type);
    }

    // Enable this if you want to make the gallery close when choosing an option
    // this.props.onCloseClick(this.state.type);
  };

  render() {
    const currentSpotObjectId = this.props.currentSpot
      ? this.props.currentSpot.objectId
      : null;
    const galleryEntries = parseGalleryDataList(
      this.props.spotList,
      currentSpotObjectId,
      this.onEntryClick
    );

    return (
      <UIWidget
        size={this.props.size}
        {...this.props}
        typeModifier="amav-widget--gallery"
        headerTitle={this.title}
      >
        {galleryEntries}
        <div className="amav-clearfix" />
      </UIWidget>
    );
  }
}

UIGalleryWidget.propTypes = {
  spotList: PropTypes.arrayOf(PropTypes.object),
  onEntryClickHandler: PropTypes.func
};

export default UIGalleryWidget;
