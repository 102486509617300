// Base
import React from 'react';
import BaseComponent from '@aroundmedia/frontend-library/lib/shared/basecomponent/BaseComponent';
import classnames from 'classnames';

import { ViewModes } from '../../../config/types/ViewModes';
import BrowserHelper from '../../../helpers/browser-helper';
import { withMiniStore, changeViewModeAction } from '../../../MiniStore';
import VRStartButton from './VRStartButton';

class ViewModeToggle extends BaseComponent {
  constructor(props) {
    super(props);

    this.entries = ViewModes;

    this.mobileWarningLabel = this.props.localizer.get('mobileOnly');
    const { appState } = this.props;

    // Default Mode is normal
    // TODO - listen to prop in case we want to start a viewer in a certain mode
    this.state = {
      // active: this.props.WebVR.viewMode || ViewModes.normal
      // active: ViewModes.normal
      active: appState.viewMode
    };
  }

  componentDidMount() {
    // WebVR.canPresent()
    //   .then((canPresent) => {
    //     this.setState({ canPresent, promiseDone: true });
    //   })
    //   .catch((err) => {
    //     this.setState({ promiseDone: true });
    //   });
  }

  onEntryClick = (clickedEntry) => {
    console.log('clicked entry', clickedEntry);
    if (this.state.active.type !== clickedEntry.type) {
      this.setState({
        active: clickedEntry
      });

      const { appStateDispatch } = this.props;
      appStateDispatch(changeViewModeAction(clickedEntry));

      this.props.onViewModeToggle && this.props.onViewModeToggle();
    }
  };

  createToggleEntry = (entry, asHTML) => {
    // If the entry is the current active entry, this one should be set to active
    // const activeClass = (this.state.active.type === entry.type) ? 'amav-view-mode-toggle__button--active' : '';
    if (entry.type === ViewModes.vr.type && BrowserHelper.isAndroid()) {
      return false;
    }

    const isNormalEntry = entry.type === ViewModes.normal.type;
    const isGyroEntry = entry.type === ViewModes.gyro.type;
    const isVrEntry = entry.type === ViewModes.vr.type;

    const gyroDisabled =
      !window.DeviceOrientationEvent ||
      (window.DeviceOrientationEvent && !('ontouchstart' in window));
    const vrDisabled = !this.state.canPresent;

    // alert(`gyro ${gyroDisabled}`);
    // alert(`vr ${vrDisabled}`);
    const entryClassName = classnames(
      'amav-view-mode-toggle__button',
      'amav-button',
      entry.class,
      {
        'amav-view-mode-toggle__button--active':
          this.state.active.type === entry.type,
        'amav-view-mode-toggle__button--disabled': gyroDisabled
      }
    );
    let showMobileWarning = false;
    if (isGyroEntry && gyroDisabled) {
      showMobileWarning = true;
    } else if (isVrEntry && vrDisabled) {
      showMobileWarning = true;
    }

    let mobileWarning;
    let clickHandler = () => this.onEntryClick(entry);
    if (showMobileWarning) {
      if (asHTML) {
        mobileWarning = `<p class="amav-view-mode-toggle__button__no-gyro">
        ${this.mobileWarningLabel}
      </p>`;
      } else {
        mobileWarning = (
          <p className="amav-view-mode-toggle__button__no-gyro">
            {this.mobileWarningLabel}
          </p>
        );
      }
      clickHandler = null;
    }
    if (asHTML) {
      return `<div class="${entryClassName}">
        <i class="material-icons amav-icon ${entry.icon}" ></i>
        <div class="amav-view-mode-toggle__button__text">
          <p class="amav-view-mode-toggle__button__title">
            ${this.props.localizer.get(entry.type)}
          </p>
        </div>
      </div>`;
    }

    return (
      <div className={entryClassName} onClick={clickHandler} key={entry.type}>
        <i className={`material-icons amav-icon ${entry.icon}`} />
        <div className="amav-view-mode-toggle__button__text">
          <p className="amav-view-mode-toggle__button__title">
            {this.props.localizer.get(entry.type)}
          </p>
          {mobileWarning}
        </div>
      </div>
    );
  };

  createToggleEntries = () => {
    const entries = [];

    Object.keys(this.entries).forEach((key) => {
      const currentEntry = this.entries[key];
      if (key === ViewModes.vr.type && this.props.renderer) {
        entries.push(
          <VRStartButton
            key="vr"
            onClicked={() => this.onEntryClick(currentEntry)}
            returnToNormalMode={() => this.onEntryClick(this.entries['normal'])}
            renderer={this.props.renderer}
            buttonContent={this.createToggleEntry(this.entries[key], true)}
          />
        );
      } else {
        entries.push(this.createToggleEntry(currentEntry));
      }
    });

    return entries;
  };

  render() {
    const entries = this.createToggleEntries();
    return <div className="amav-view-mode-toggle">{entries}</div>;
  }
}

export default withMiniStore(ViewModeToggle);
