// Base
import PropTypes from 'prop-types';
import BaseComponent from '@aroundmedia/frontend-library/lib/shared/basecomponent/BaseComponent';
import * as THREE from 'three/build/three.min';

class FadePlane extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      fade: 'none'
    };

    this._name = 'fadePlane';
    this.fadeTotal = 0;
    this.fadeDuration = this.props.fadeDuration || 300; // speed in ms
    this.fading = false;

    // Fade-to-black plane creation
    this.fadePlane = new THREE.Mesh(
      new THREE.PlaneBufferGeometry(60, 30, 2, 2),
      new THREE.MeshBasicMaterial({
        color: 0x000,
        opacity: 0,
        transparent: true,
        side: THREE.DoubleSide
      })
    );

    this.fadePlane.name = this._name;

    const cameraLookAtVec3 = new THREE.Vector3(0, 0, -1);
    this.fadePlane.position.set(
      cameraLookAtVec3.x,
      cameraLookAtVec3.y,
      cameraLookAtVec3.z * 5
    );
    this.props.camera.add(this.fadePlane);
  }

  componentWillUnmount() {
    const selectedObject = this.props.camera.getObjectByName(this._name);
    this.props.camera.remove(selectedObject);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newState = { deltaTime: nextProps.deltaTime };

    if (nextProps.fade !== this.state.fade) {
      newState.fade = nextProps.fade;
      this.fading = true;
    }

    this.setState(newState);
  }

  procFade = (invert) => {
    this.fadeTotal += Math.min(this.state.deltaTime, 16);

    const opacityAmount = this.fadeTotal / this.fadeDuration;

    this.fadePlane.material.opacity = invert
      ? 1 - opacityAmount
      : opacityAmount;
    // this.fadePlane.material.opacity = 0.5;

    if (this.fadeTotal > this.fadeDuration) {
      this.fading = false;
      this.fadeTotal = 0;
      setTimeout(() => {
        this.props.onFaded && this.props.onFaded(this.props.fade);
      }, 0);
    }
  };

  render() {
    if (this.fading) {
      this.procFade(this.props.fade === 'in');
    }

    return false;
  }
}

FadePlane.propTypes = {
  deltaTime: PropTypes.number.isRequired,
  onFaded: PropTypes.func,
  fade: PropTypes.string.isRequired,
  fadeDuration: PropTypes.number
};

export default FadePlane;
