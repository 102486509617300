// Base
import React from 'react';
import * as THREE from 'three/build/three.min';
// import classnames from 'classnames';
import { ViewerSDK } from '@aroundmedia/frontend-library/lib/sdks/ViewerSDK';

import BaseComponent from '@aroundmedia/frontend-library/lib/shared/basecomponent/BaseComponent';

export default class SpotLink extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {};

    this.Point3D = this.props.posObject.Point3D;

    switch (this.props.data.linkType) {
      case 'location_point_ref':
        this.typeClass = 'spot-link--location';
        this.iconClass = '';
        break;
      case 'info_point_ref':
        this.typeClass = 'spot-link--info';
        this.iconClass = 'amav-icon--info';
        break;
      case 'spot_point_ref':
      default:
        this.typeClass = 'spot-link--spot';
        this.iconClass = 'amav-icon--place';

        ViewerSDK.getSpot(this.props.data.linkReference.objectId).then(
          (spot) => {
            this.title = spot.name;
          }
        );
        break;
    }

    // Explanation for the Magic number 4: The width is in css, while the sphere radius is in 3D space.
    // There's no way to really convert that from 2D to 3D
    this.cullingSphere = new THREE.Sphere(this.Point3D, this.props.width / 4);
  }

  onSpotLinkClick = () => {
    if (this.props.data.linkType !== 'location_point_ref') {
      // Call the parent
      this.props.onSpotLinkClick(
        this.props.data.linkType,
        this.props.data.linkReference.objectId
      );
    }
  };

  render() {
    const styleString = `translate3d(
            ${this.props.posObject.Point2D.x - this.props.width / 2}px,
            ${this.props.posObject.Point2D.y - this.props.height / 2}px,
            0px)`;
    const hiddenStateClass = !this.props.frustum.intersectsSphere(
      this.cullingSphere
    )
      ? 'amav-hidden'
      : '';

    let title;
    if (this.title) {
      title = <span className="spot-link__title">{this.title}</span>;
    }

    let icon;
    if (this.iconClass) {
      icon = (
        <i
          className={`spot-link__icon icon ${this.iconClass} material-icons`}
        />
      );
    }

    return (
      <div
        style={{ transform: styleString }}
        className={`spot-links-container__spot-link ${this.typeClass} ${hiddenStateClass}`}
        onClick={this.onSpotLinkClick}
      >
        {icon}
        {title}
      </div>
    );
  }
}
