// Base
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// React Helpers
import Measure from 'react-measure';

// Lodash
import keyBy from 'lodash.keyby';
import reject from 'lodash.reject';
import isNil from 'lodash.isnil';
import forEach from 'lodash.foreach';

// SFL
import BaseComponent from '@aroundmedia/frontend-library/lib/shared/basecomponent/BaseComponent';
import { AFLValidator } from '@aroundmedia/frontend-library/lib/shared/helpers/AFLValidator';
import { ViewerSDK } from '@aroundmedia/frontend-library/lib/sdks/ViewerSDK';
import Helpers from '@aroundmedia/frontend-library/lib/shared/helpers/Helpers';

// AFL - UI Components
import AFLForm from '@aroundmedia/frontend-library/lib/shared/components/forms/AFLForm';
import AFLOauthButton from '@aroundmedia/frontend-library/lib/shared/components/buttons/AFLOauthButton';
import AFLContentLoader from '@aroundmedia/frontend-library/lib/shared/components/loaders/AFLContentLoader';
import { Notifier } from '@aroundmedia/frontend-library/lib/shared/Notifier';

import ENV from '../../../config/active-environment/env.json';

export default class Authenticator extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {};

    this.authTitle = this.props.localizer.get('authTitle');
    this.authSubTitle = this.props.localizer.get('authSubTitle');
    this.authFormLabel = this.props.localizer.get('authFormLabel');
    this.authInputLabel = this.props.localizer.get('authInputLabel');

    this.mailWallTitle = this.props.localizer.get('mailWallTitle');
    this.mailWallSubTitle = this.props.localizer.get('mailWallSubTitle');

    this.mailWallData = {
      fields: {
        mw_firstName: {
          fieldType: 'input',
          type: 'text',
          label: this.props.localizer.get('mailWallFirst'),
          value: '',
          status: 0,
          size: 2
        },

        mw_lastName: {
          fieldType: 'input',
          type: 'text',
          label: this.props.localizer.get('mailWallLast'),
          value: '',
          status: 0,
          size: 2
        },

        spacer_1: {
          fieldType: 'field__spacer',
          includeMargin: true,
          size: 1
        },

        mw_email: {
          fieldType: 'input',
          type: 'email',
          label: this.props.localizer.get('mailWallEmail'),
          value: '',
          status: 0,
          size: 2
        },

        mw_phone: {
          fieldType: 'input',
          type: 'text',
          label: this.props.mailWallPhoneNumberMandatory
            ? this.props.localizer.get('mailWallPhoneMandatory')
            : this.props.localizer.get('mailWallPhone'),
          value: '',
          status: 0,
          size: 2
        }
      },
      rules: {
        mw_firstName: {
          required: true
        },
        mw_lastName: {
          required: true
        },
        mw_email: {
          email: true,
          required: true
        },
        mw_phone: {}
      },
      valid: false
    };

    if (this.props.mailWallPhoneNumberMandatory) {
      this.mailWallData.rules.mw_phone.required = true;
    }
  }

  componentDidMount = () => {
    Notifier.publish('cookies.warn');
  };

  onAuthClick = () => {
    this.props.onAuthenticate &&
      this.props.onAuthenticate(
        this.props.albumCode,
        this.accessCodeInput.value
      );
  };

  onMailWallClick = () => {
    AFLValidator.validate(this.mailWallData.fields, this.mailWallData.rules)
      .then((result) => {
        // Filter null or undefined values from result
        let tempValidatorResult = reject(result, isNil);

        // Check if result is empty, if so: form is valid
        this.mailWallData.valid =
          !tempValidatorResult || tempValidatorResult.length === 0;
        this.mailWallData.lastValidated = Date.now();

        // Convert field validator array to dictionary > Object {}
        tempValidatorResult = keyBy(tempValidatorResult, 'fieldKey');

        // Update all fields with new status and message
        forEach(this.mailWallData.fields, (fieldProps, key) => {
          // Update mailWallData fields with validator results
          this.mailWallData.fields[key].status = tempValidatorResult[key]
            ? tempValidatorResult[key].status
            : 200;
          this.mailWallData.fields[key].message = tempValidatorResult[key]
            ? tempValidatorResult[key].message
            : null;
        });

        // Update state with new values and status
        this.setState({ mailWallData: this.mailWallData }, () => {
          if (this.mailWallData.valid) {
            // Retry with sessionToken and set cookie
            ViewerSDK.createCustomer({
              email: this.mailWallData.fields.mw_email.value,
              firstName: this.mailWallData.fields.mw_firstName.value,
              lastName: this.mailWallData.fields.mw_lastName.value,
              phoneNumber: this.mailWallData.fields.mw_phone.value
            }).then((tokenResult) => {
              if (tokenResult.token) {
                this.customerTokenAcquired(tokenResult.token);
              }
            });
          }
        });
      })
      .catch((error) => {
        // TODO - Handle error
        window.console.log('--- error - validate error: %o', error);
      });
  };

  onMailWallFieldChange = (ref, value) => {
    this.mailWallData.fields[ref].value = value;
    this.setState({ lastActionAt: Date.now() });
  };

  buildAuthStatusContent = () => {
    let authStatusContent;
    if (this.props.authStatus.code === 403) {
      authStatusContent = (
        <AFLContentLoader
          contentStatusCode={403}
          description={this.props.authStatus.message}
        />
      );
    } else if (this.state.authStatusOverride) {
      authStatusContent = (
        <AFLContentLoader contentStatusCode={1} description={''} />
      );
    } else {
      authStatusContent = (
        <AFLContentLoader
          contentStatusCode={this.props.authStatus.code}
          description={this.props.authStatus.message}
        />
      );
    }

    return authStatusContent;
  };

  onOAuthSuccess = (oauth2Code, oauth2Provider, oauth2RedirectUri) => {
    if (oauth2Provider && oauth2Code) {
      this.authStatusOverride = true;
      this.setState(
        {
          authStatusOverride: true
        },
        () => {
          ViewerSDK.createCustomerWithOauth({
            oauth2Provider,
            oauth2Code,
            oauth2RedirectUri
          })
            .then((tokenResult) => {
              this.customerTokenAcquired(tokenResult.token);
            })
            .catch(() => {
              this.setState({
                authStatusOverride: false
              });
            });
        }
      );
    }
  };

  customerTokenAcquired = (token) => {
    this.props.analytics.customerToken = token;
    this.props.analytics.sendTrackOnMailWallFilledOut();
    Helpers.setCookie('around-customer-token', token, 604800000); // 604 800 000 = One Week

    if (this.props.onAuthenticate) {
      this.props.onAuthenticate(this.props.albumCode, null, token);
    }
  };

  buildMailWall = () => (
    <ResizingModal
      title={this.mailWallTitle}
      subTitle={this.mailWallSubTitle}
      contentLoader={this.buildAuthStatusContent()}
      viewerSize={this.props.size}
    >
      <div className="afl-form">
        {!this.props.mailWallPhoneNumberMandatory && (
          <Fragment>
            <AFLOauthButton
              className="amav-auth-modal__action-button amav-auth-modal__action-button--facebook"
              type="facebook"
              onAuthSuccess={this.onOAuthSuccess}
              env={ENV}
            >
              Facebook
            </AFLOauthButton>

            <AFLOauthButton
              className="amav-auth-modal__action-button amav-auth-modal__action-button--linkedin"
              type="linkedIn"
              onAuthSuccess={this.onOAuthSuccess}
              env={ENV}
            >
              LinkedIn
            </AFLOauthButton>

            <AFLOauthButton
              className="amav-auth-modal__action-button amav-auth-modal__action-button--google"
              type="gmail"
              onAuthSuccess={this.onOAuthSuccess}
              env={ENV}
            >
              Google
            </AFLOauthButton>

            <div className="amav-auth-modal__form-message">
              {this.authFormLabel}
            </div>
          </Fragment>
        )}

        <AFLForm
          key="afl-form"
          fields={this.mailWallData.fields}
          onFieldChange={this.onMailWallFieldChange}
        />
        <div
          className="amav-auth-modal__action-button"
          onClick={this.onMailWallClick}
        >
          {this.props.localizer.get('sendInfo')}
        </div>
        <div className="amav-auth-modal__form-message">
          {this.props.localizer.get('cookieWarningText')}
        </div>
      </div>
    </ResizingModal>
  );

  buildAuth = () => (
    <ResizingModal
      title={this.authTitle}
      subTitle={this.authSubTitle}
      contentLoader={this.buildAuthStatusContent()}
      viewerSize={this.props.size}
    >
      <div className="afl-form">
        <div className="form__field-wrapper amav-auth-modal__form-field-wrapper">
          <label
            className="amav-auth-modal__input-label field__label"
            htmlFor="amav-auth-modal__input"
          >
            {this.authInputLabel}
          </label>
          <input
            type="text"
            id="amav-auth-modal__input form__field"
            className="amav-auth-modal__input-field form__field"
            ref={(input) => {
              this.accessCodeInput = input;
            }}
          />
        </div>
        <div
          className="amav-auth-modal__action-button"
          onClick={this.onAuthClick}
        >
          {this.props.localizer.get('authorize')}
        </div>
      </div>
    </ResizingModal>
  );

  render() {
    if (this.props.accessLevel === 1) {
      // IS MAILWALL
      return this.buildMailWall();
    }

    // IS PRIVATE
    return this.buildAuth();
  }
}

class ResizingModal extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      dimensions: {
        bounds: {
          height: -1,
          width: -1
        },
        margin: {
          top: 0,
          left: 0,
          bottom: 0,
          right: 0
        }
      }
    };
  }

  render() {
    const dims = this.state.dimensions;
    let height = dims.bounds.height + dims.margin.top + dims.margin.bottom;
    if (height > this.props.viewerSize.height) {
      height = this.props.viewerSize.height - 10; // giving it some "padding" by subtracting 20
    }

    let width = dims.bounds.width + dims.margin.left + dims.margin.right;
    let minWidth;
    if (width > this.props.viewerSize.width) {
      width = this.props.viewerSize.width - 10; // giving it some "padding" by subtracting 20
    } else {
      if (this.props.viewerSize.width > 420) {
        minWidth = 400;
      }
    }
    const left = this.props.viewerSize.width / 2 - width / 2 || 0;

    const wrapperSizeStyle = { height, width };
    const sizeStyle = { left, ...wrapperSizeStyle };
    const containerStyle = { minWidth };

    return (
      <div className="amav-auth-modal" style={sizeStyle}>
        <div className="amav-auth-modal__wrapper" style={wrapperSizeStyle}>
          <Measure
            margin
            bounds
            onResize={(dimensions) => {
              this.setState({ dimensions });
            }}
          >
            {({ measureRef }) => (
              <div
                ref={measureRef}
                className="amav-auth-modal__container"
                style={containerStyle}
              >
                <div className="amav-auth-modal__title">{this.props.title}</div>
                <div className="amav-auth-modal__sub-title">
                  {this.props.subTitle}
                </div>
                {this.props.children}
              </div>
            )}
          </Measure>
          {this.props.contentLoader}
        </div>
      </div>
    );
  }
}

ResizingModal.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  viewerSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number
  }).isRequired
};
