// Base
import React from 'react';
import Measure from 'react-measure';
import classNames from 'classnames';
import BaseComponent from '@aroundmedia/frontend-library/lib/shared/basecomponent/BaseComponent';

import UIWidget from './UIWidget';
import { InformationTypes } from '../../../config/types/Informations';

// Helpers
/**
 * Create an infopoint content entry with text
 * @param {string} content to display
 * @param {Number} key - id for entry
 * @param {boolean} single - whether a 'single entry modifier' needs to be added in css
 * @param {boolean} sideBarMode - whether the view is in sidebar mode
 */
const createEntryWithText = (content, key, single, sideBarMode) => {
  let style;
  const _content = content;
  if (!single) {
    // const arrowDir = sideBarMode ? 'amav-arrow--up' : 'amav-arrow--down';
    // _content += `<div class="amav-arrow ${arrowDir}">`;
    style = {
      display: 'block',
      overflowY: 'scroll',
      position: 'absolute',
      bottom: '-100px',
      height: '60px',
      width: sideBarMode ? '220px' : '560px'
    };
  }

  return (
    <div
      className={classNames({
        content__text: true, // eslint-disable-line
        'content--single': single
      })}
      style={style}
      dangerouslySetInnerHTML={{ __html: _content }} // eslint-disable-line
      key={key}
    />
  );
};

/**
 * Create an infopoint content entry with an image
 * @param {string} content to display
 * @param {Number} key - id for entry
 * @param {boolean} single - whether a 'single entry modifier' needs to be added in css
 * @param {boolean} sideBarMode - whether the view is in sidebar mode
 * @param {function} toggleExpand
 * @param {object} size
 * @param {Number} size.height
 * @param {Number} size.width
 */
class ImageEntry extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      dimensions: {
        bounds: {
          width: -1,
          height: -1
        },
        entry: {
          height: 10000,
          width: 10000
        }
      }
    };
  }

  // onImgLoad = ({ target: img }) => {
  //     if (this) {
  //         this.setState({
  //             dimensions: {
  //                 height: img.offsetHeight,
  //                 width: img.offsetWidth
  //             }
  //         });
  //     }
  // };

  render() {
    const imageHeight = { marginBottom: '100px' };
    if (!this.props.sideBarMode) {
      const height = this.props.size ? this.props.size.height * 0.8 : 400;
      const offset = 200;
      let preCalcHeight;
      if (this.state.dimensions.bounds.height < height - offset) {
        preCalcHeight = this.state.dimensions.bounds.height;
      } else {
        preCalcHeight = height - offset;
      }
      imageHeight.height = `${preCalcHeight}px`;
      imageHeight.maxHeight = '100%';
    }

    return (
      <div
        className={classNames({
          content__image: true, // eslint-disable-line
          'content--single': this.props.single
        })}
        style={imageHeight}
      >
        <Measure
          bounds
          onResize={(dimensions) => {
            if (!this.props.sideBarMode) {
              this.setState({ dimensions });
            }
          }}
        >
          {({ measureRef }) => (
            <img
              ref={measureRef}
              src={this.props.content.replace(/\.(png|gif)$/i, '.jpg')}
              alt="info-point"
            />
          )}
        </Measure>
        <div
          className="expander"
          onClick={() => this.props.toggleExpand && this.props.toggleExpand()}
        >
          <i
            className={`material-icons amav-icon amav-icon--${
              this.props.sideBarMode ? 'zoom-in' : 'zoom-out'
            }`}
          />
        </div>
      </div>
    );
  }
}

/**
 * UIInfoPoint Widget
 * @extends UIWidget
 */
export default class UIInfoPointWidget extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = props;

    // TODO - enable as prop too
    this.title = this.props.localizer.get('info');
  }

  getInfoPointData = (data) =>
    data.currentSpot.getInfoPointForObjectId(data.infoPointReferenceId);

  buildData = () => {
    this.infoPointData = this.getInfoPointData(this.state);
    this.informationList = this.infoPointData.informationList.filter(
      (value) => value.value
    );
    this.props.analytics.infoSpotOpened(this.infoPointData.objectId);
    this.setState({ single: this.informationList.length === 1 });
  };

  UNSAFE_componentWillMount() {
    this.state.onSideBarModeChange(true);
    this.buildData();
  }

  componentDidMount() {
    this.props.analytics.infoSpotOpened(this.infoPointData.objectId);
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.infoPointReferenceId !== this.state.infoPointReferenceId &&
      this.state.infoPointReferenceId !== null
    ) {
      this.props.analytics.infoSpotClosed();
    }
    this.setState(nextProps, () => this.buildData());
  };

  componentWillUnmount() {
    this.props.analytics.infoSpotClosed();
  }

  parseInfoList = (infoList, single) => {
    // Sort the entries according to our priorities
    const priority = { image: 0, text: 1, html: 2 };
    infoList.sort((a, b) => {
      const ap = priority[a.informationType];
      const bp = priority[b.informationType];
      return ap - bp;
    });

    // Build the entries
    const entries = [];
    infoList.forEach((item, index) => {
      if (
        item.informationType === InformationTypes.text ||
        item.informationType === InformationTypes.html
      ) {
        entries.push(
          createEntryWithText(item.value, index, single, this.state.sideBarMode)
        );
      } else if (item.informationType === InformationTypes.image) {
        entries.push(
          <ImageEntry
            content={item.value}
            key={`key_${item.objectId}`}
            single={single}
            sideBarMode={this.state.sideBarMode}
            toggleExpand={this.state.toggleSideBarMode}
            size={this.props.size}
          />
        );
      }
    });

    return entries;
  };

  onSizeChange = (viewerSize) => {
    if (this.state.viewerSize !== viewerSize) {
      this.setState({ viewerSize });
    }
  };

  render() {
    const widgetContent = this.parseInfoList(
      this.informationList,
      this.state.single
    );
    const height = this.state.size ? this.state.size.height : 400;
    const maxHeight = this.state.sideBarMode ? height - 20 : height * 0.8;

    return (
      <UIWidget
        {...this.state}
        maxHeight={maxHeight}
        typeModifier="amav-widget--info-point"
        headerTitle={this.title}
        onSizeChange={this.onSizeChange}
      >
        {widgetContent}
      </UIWidget>
    );
  }
}
