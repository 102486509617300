import * as eng from './eng/strings.json';
import * as nld from './nld/strings.json';
import * as fra from './fra/strings.json';

export default class Localizer {
    constructor(language) {
        let processedLanguage = '';
        const _language = language || this.defaultLanguage;

        switch (_language.length) {
            case 2: // lang iso2, convert to iso 3 and continue there
                processedLanguage = this.supportedLanguages[_language];
                break;
            case 3: // lang iso 3, check if found in list
                Object.keys(this.supportedLanguages).forEach(item => {
                    if (this.supportedLanguages[item] === _language) {
                        processedLanguage = _language;
                    }
                });

                break;
            default:
                // lang too long or too short, fallback to default
                processedLanguage = this.defaultLanguage;
                break;
        }
        // Use the language inputted, or in case none was added, use the default
        this.loc = this.languageFiles[
            processedLanguage || this.defaultLanguage
        ];
    }

    // Set the supported languages with iso2 key and iso3 value
    supportedLanguages = {
        nl: 'nld',
        en: 'eng',
        fr: 'fra'
    };

    // Set the default language to dutch
    defaultLanguage = this.supportedLanguages.en;

    // Structure the language files for easy access in constructor
    languageFiles = { eng, nld, fra };

    // Get a value from the selected localization
    get = id => this.loc.default[id];
}
