import { useState, useEffect } from 'react';
import { shape, func } from 'prop-types';

const XRPresentingHelper = ({ renderer, onLaunch, onEnd }) => {
  const [, setPresenting] = useState(renderer?.xr?.isPresenting || false);
  useEffect(() => {
    if (!renderer?.xr) {
      return;
    }
    setPresenting((pres) => {
      // here we can listen for changes
      if (pres !== renderer.xr.isPresenting) {
        if (renderer.xr.isPresenting) {
          onLaunch();
        } else {
          onEnd();
        }
        return renderer.xr.isPresenting;
      }
      return pres;
    });
  }, [renderer?.xr?.isPresenting]);

  return null;
};

XRPresentingHelper.propTypes = {
  renderer: shape({}),
  onLaunch: func,
  onEnd: func
};

XRPresentingHelper.defaultProps = {
  onLaunch: () => {},
  onEnd: () => {}
};

export default XRPresentingHelper;
