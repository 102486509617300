// Base
import React from 'react';
import BaseComponent from '@aroundmedia/frontend-library/lib/shared/basecomponent/BaseComponent';
import AFLButton from '@aroundmedia/frontend-library/lib/shared/components/buttons/AFLButton';
import { AFLVerticalDivider } from '@aroundmedia/frontend-library/lib/shared/components/helpers/Functionals';
import classnames from 'classnames';
import FullScreen from '../../../helpers/fullscreen-helper';

import ENV from '../../../config/active-environment/env.json';
import { UIComponentTypes } from '../../../config/types/Components';
import { ViewModes } from '../../../config/types/ViewModes';
import { withMiniStore, changeViewModeAction } from '../../../MiniStore';

class MenuTopBar extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    if (FullScreen.inIframe && FullScreen.isDisabled) {
      this.setState({ fullscreenPopOut: true });
    }
  }

  onMenuToggle = () => {
    this.props.onMenuToggle(UIComponentTypes.leftMenuBar);
  };

  onGalleryToggle = () => {
    this.props.closeMenuIfOpen();
    this.props.onComponentToggle(UIComponentTypes.gallery);
  };

  onFullscreenToggle = () => {
    const { appStateDispatch } = this.props;
    appStateDispatch(changeViewModeAction(ViewModes.normal));

    FullScreen.setFullscreen(!FullScreen.isFullscreen);
  };

  render() {
    const classNames = {
      'amav-menu__top-bar': true,
      'is-mobile': this.props.isMobile
    };

    let fullscreenButton = (
      <AFLButton
        onClickAction={this.onFullscreenToggle}
        className="top-bar-button top-bar-button--fullscreen"
      >
        <i
          className={classnames('material-icons amav-icon', {
            'amav-icon--fullscreen': !FullScreen.isFullscreen,
            'amav-icon--fullscreen-exit': FullScreen.isFullscreen
          })}
        />
      </AFLButton>
    );

    if (this.state.fullscreenPopOut) {
      fullscreenButton = (
        <a
          href={`${ENV.viewerUrl}?id=${this.props.albumData.code}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <AFLButton
            onClickAction={this.onFullscreenToggle}
            className="top-bar-button top-bar-button--fullscreen"
          >
            <i
              className={classnames('material-icons amav-icon', {
                'amav-icon--fullscreen': !FullScreen.isFullscreen,
                'amav-icon--fullscreen-exit': FullScreen.isFullscreen
              })}
            />
          </AFLButton>
        </a>
      );
    }

    return (
      <div
        className={classnames(classNames)}
        style={{ width: this.props.size.width }}
      >
        <AFLButton
          onClickAction={this.onMenuToggle}
          className="top-bar-button top-bar-button--menu"
        >
          <i className="material-icons amav-icon amav-icon--menu" />
        </AFLButton>
        <AFLVerticalDivider />
        <AFLButton
          onClickAction={this.onGalleryToggle}
          className="top-bar-button top-bar-button--gallery"
        >
          <span>{this.props.currentSpotName}</span>
        </AFLButton>
        {fullscreenButton}
      </div>
    );
  }
}

export default withMiniStore(MenuTopBar);
