// Base
import React from 'react';
import Measure from 'react-measure';
import classnames from 'classnames';

// SFL
import BaseComponent from '@aroundmedia/frontend-library/lib/shared/basecomponent/BaseComponent';

export default class UIWidget extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      type: props.type,
      sideBarMode: props.type.defaultState === 'sidebar',
      headerTitle: props.headerTitle,
      typeModifier: props.typeModifier
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.sideBarMode !== this.state.sideBarMode) {
      this.setState({
        sideBarMode: nextProps.sideBarMode
      });
    }
  }

  onHeightMeasure = (dim) => {
    if (dim) {
      if (dim.height) {
        if (this.state.height !== dim.height) {
          this.setState({ height: dim.height });
          this.props.onContentMeasureChanged(dim.height);
        }
      }
      this.props.onSizeChange && this.props.onSizeChange(dim);
    }
  };

  onCloseClick = () => {
    this.props.onCloseClick(this.state.type);
  };

  render() {
    let typeClass = '';
    if (this.state.type.isModal) {
      typeClass = this.state.sideBarMode
        ? 'amav-widget--sidebar'
        : 'amav-widget--expanded';
    }
    if (this.state.typeModifier) {
      typeClass += ` ${this.state.typeModifier} ${
        this.props.expandable ? 'amav-widget--expandable' : ''
      }`;
    }

    let style;
    let contentStyle;
    let contentWrapperStyle;
    if (this.state.sideBarMode) {
      style = { maxHeight: this.props.maxHeight };
    } else {
      contentStyle = {
        width: '100%',
        maxHeight: `${this.props.maxHeight - 120}px`
      }; // reduced by 60 so the padding is ignored;
      contentWrapperStyle = {
        width: '100%',
        maxHeight: `${this.props.maxHeight - 60}px`
      };
    }

    return (
      <div className="amav-widget__wrapper">
        <div className={classnames('amav-widget', typeClass)} style={style}>
          <div className="amav-widget__header">
            <div className="header__title">{this.state.headerTitle}</div>
            <div className="header__close-button" onClick={this.onCloseClick}>
              <i className="material-icons amav-icon amav-icon--close" />
            </div>
          </div>
          <div
            className="amav-widget__content--wrapper"
            style={contentWrapperStyle}
          >
            <Measure
              bounds
              whitelist={['height']}
              onResize={(widgetDimensions) => {
                this.onHeightMeasure(widgetDimensions.bounds);
              }}
            >
              {({ measureRef }) => (
                <div
                  ref={measureRef}
                  className="amav-widget__content"
                  style={contentStyle}
                >
                  {this.props.children}
                </div>
              )}
            </Measure>
          </div>
        </div>
      </div>
    );
  }
}
