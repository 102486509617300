/* eslint-disable max-len */
// Base
import React from 'react';
import BaseComponent from '@aroundmedia/frontend-library/lib/shared/basecomponent/BaseComponent';

import Logo from '../../../active-entity/components/Logo';

export default class CompanyLogo extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Logo hasBackground={this.props.hasBackground} />;
  }
}
/* eslint-enable max-len */
