// Base
import React from 'react';
import * as THREE from 'three/build/three.min';
import BaseComponent from '@aroundmedia/frontend-library/lib/shared/basecomponent/BaseComponent';
import SpherePart from './SpherePart';

export default class Sphere extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = props;

    this._name = 'sphereCollection';

    const sphereRadius = this.state.sphereDiameter / 2;
    this.sphereObject = new THREE.Object3D();
    this.sphereObject.name = this._name;
    this.sphereObject.scale.set(-sphereRadius, sphereRadius, sphereRadius);
    this.sphereYRotOffset = 180;
    this.props.scene.add(this.sphereObject);

    this.rotAxises = {
      x: new THREE.Vector3(1, 0, 0),
      y: new THREE.Vector3(0, 1, 0),
      z: new THREE.Vector3(0, 0, 1)
    };

    this.setRotationWithSpot(props.currentSpot);

    this.partLoadedPromises = [];
    this.partLoadedResolvers = [];
    this.partLoadedRejecters = [];

    this.firstSpotLoaded = false;
  }

  componentWillUnmount() {
    const selectedObject = this.scene.getObjectByName(this._name);
    this.props.scene.remove(selectedObject);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.currentSpot &&
      nextProps.currentSpot.objectId !== this.props.currentSpot.objectId
    ) {
      this.setState(
        {
          currentSpot: nextProps.currentSpot
        },
        () => {
          this.setRotationWithSpot(nextProps.currentSpot);
        }
      );
    }
  }

  setRotationWithSpot = (spot) => {
    // Reset Rotation
    this.sphereObject.rotation.set(0, 0, 0);

    // Calculate the final worldspace rotation
    const x = (360 - spot.panoramaRotation[0]) * (Math.PI / 180);
    const y =
      (360 - spot.panoramaRotation[1] - this.sphereYRotOffset) *
      (Math.PI / 180);
    const z = (360 - spot.panoramaRotation[2]) * (Math.PI / 180);

    // Rotate the container around the axis.
    this.sphereObject.rotateOnAxis(this.rotAxises.y, y);
    this.sphereObject.rotateOnAxis(this.rotAxises.z, z);
    this.sphereObject.rotateOnAxis(this.rotAxises.x, x);
  };

  buildParts = () => {
    const content = [];
    for (let part = 0; part < this.state.parts; part++) {
      if (!this.firstSpotLoaded) {
        this.partLoadedPromises[part] = new Promise((resolve, reject) => {
          this.partLoadedResolvers[part] = resolve;
          this.partLoadedRejecters[part] = reject;
        });
      }

      content.push(
        <SpherePart
          parts={this.state.parts}
          part={part}
          key={part}
          sphere={this.sphereObject}
          currentSpot={this.state.currentSpot}
          onLowResLoad={() => {
            this.lowPartLoaded(part);
          }}
          isMobile={this.props.isMobile}
          maxAni={this.props.maxAni}
          renderLoop={this.props.renderLoop}
        />
      );
    }

    if (!this.firstSpotLoaded) {
      this.firstSpotLoaded = true;
      Promise.all(this.partLoadedPromises)
        .then(() => {
          // All Low Res Images are loaded to we trigger the sphere Loaded event
          this.props.onLoaded && this.props.onLoaded();
        })
        .catch(() => {
          // TODO - send Error to Sentry/google ?
        });
    }

    return content;
  };

  lowPartLoaded = (part) => {
    this.partLoadedResolvers[part]();
  };

  render() {
    if (this.state.currentSpot) {
      const content = this.buildParts();

      return <div>{content}</div>;
    }

    return false;
  }
}
