// import React from 'react';
// import ReactDOM from 'react-dom';

import { ViewModes } from '../../config/types/ViewModes';
// import FullScreen from '../../helpers/fullscreen-helper';
/**
 * type {class} WebVR
 */
export default class WebVR {
    constructor(effect, viewModeChangeCallback, canvasTarget, analytics) {
        this._effect = effect;
        this._ViewMode = ViewModes.normal;
        this._viewModeChangeCallback = viewModeChangeCallback;
        this._canvasTarget = canvasTarget;
        this._analytics = analytics;

        this._tutorial = null;

        window.addEventListener(
            'vrdisplaypresentchange',
            this.onVRDisplayPresentChange,
            false
        );
        this._analytics && this._analytics.sendTrackOnViewModeChanged('normal');
    }

    static isLatestAvailable() {
        // console.warn('WEBVR: isLatestAvailable() is being deprecated. Use .isAvailable() instead.');
        return this.isAvailable();
    }

    static isAvailable() {
        return typeof navigator.getVRDisplays !== 'undefined';
    }

    static canPresent() {
        if (this.isAvailable()) {
            return new Promise((resolve, reject) => {
                navigator
                    .getVRDisplays()
                    .then(displays => {
                        if (displays && displays.length > 0) {
                            resolve(displays[0].capabilities.canPresent);
                        }

                        reject(new Error('No VR Displays Found'));
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }

        return Promise.reject('novrdisplays');
    }

    makeBackButton = () => {
        const button = document.createElement('button');
        button.style.position = 'relative';
        button.style.left = '0px';
        button.style.top = '0px';
        button.style.width = '100px';
        button.style.height = '100px';
        button.style.border = '0';
        button.style.background = 'none';
        button.style.color = '#fff';
        button.style.zIndex = '20000';

        button.onclick = () => {
            this.exitVR();
        };

        this.backButton = button;
    };

    procViewModeChangeLogic = () => {
        switch (this._ViewMode) {
            case ViewModes.gyro:
                this.exitVR();
                this.enterGyro();
                this._analytics &&
                    this._analytics.sendTrackOnViewModeChanged('gyro');
                break;
            case ViewModes.vr:
                this.exitGyro();
                this.enterVR();
                this._analytics &&
                    this._analytics.sendTrackOnViewModeChanged('vr');
                break;
            case ViewModes.normal:
                this.exitVR();
                this.exitGyro();
                this._analytics &&
                    this._analytics.sendTrackOnViewModeChanged('normal');
                break;
            default:
                this.exitVR();
                this.exitGyro();
        }
    };

    /**
     * This is very dirty
     */
    supplantSplashHack = () => {
        if (this.hacked) {
            return;
        }

        const list = document.getElementsByTagName('a');
        let tutorialWrapper;
        for (let i = 0; i < list.length; i++) {
            if (
                list[i].href ===
                'https://www.google.com/get/cardboard/get-cardboard/'
            ) {
                tutorialWrapper = list[i].parentNode.parentNode;
            }
        }

        if (tutorialWrapper) {
            this._tutorial = tutorialWrapper;
            this.hacked = true;
        }
    };

    onViewModeChange = () => {
        this._viewModeChangeCallback && this._viewModeChangeCallback();
        setTimeout(() => {
            this._viewModeChangeCallback && this._viewModeChangeCallback();
        }, 500);
    };

    toggle = () => {
        this._effect.isPresenting
            ? this._effect.exitPresent()
            : this._effect.requestPresent();
        this.onViewModeChange();
    };

    exitVR = () => {
        if (this._effect.isPresenting) {
            this._effect.exitPresent();
        }

        if (this.backButton && this.backButton.parentNode) {
            this.backButton.parentNode.removeChild(this.backButton);
        }
        this._canvasTarget.setAttribute('style', '');
        this.onViewModeChange();
    };

    enterVR = () => {
        if (!this._effect.isPresenting) {
            this._effect.requestPresent();
            this.onViewModeChange();
            setTimeout(() => {
                this.supplantSplashHack();
            }, 20);
            setTimeout(() => {
                this.makeBackButton();
                this._canvasTarget.parentNode.insertBefore(
                    this.backButton,
                    this._canvasTarget
                );
            }, 2000);
        }
    };

    // TODO - Figure this out
    enterGyro = () => {
        // if (!this._effect.isPresenting) {
        //     this._effect.requestPresent();
        //     this.onViewModeChange();
        // }
    };

    exitGyro = () => {
        // if (!this._effect.isPresenting) {
        //     this._effect.requestPresent();
        //     this.onViewModeChange();
        // }
    };

    onVRDisplayPresentChange = data => {
        if (data && data.detail && data.detail.display) {
            if (!data.detail.display.isPresenting) {
                this._ViewMode = ViewModes.normal;
                this.procViewModeChangeLogic();
            }
        }
    };

    destroy = () => {
        window.removeEventListener(
            'vrdisplaypresentchange',
            this.onVRDisplayPresentChange,
            false
        );
    };

    setZIndexOfTutorial = zIndex => {
        this._tutorial.style.zIndex = zIndex;
    };

    set viewMode(viewMode) {
        this._ViewMode = viewMode;
        this.procViewModeChangeLogic();
    }

    get viewMode() {
        return this._ViewMode;
    }

    set effect(effect) {
        this._effect = effect;
    }

    get effect() {
        return this._effect;
    }

    set coverImageUrl(url) {
        this._coverImageUrl = url;
    }

    get tutorialNode() {
        return this._tutorial;
    }
}
