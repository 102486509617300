// Base
import React from 'react';
import BaseComponent from '@aroundmedia/frontend-library/lib/shared/basecomponent/BaseComponent';
import FullScreen from '../../helpers/fullscreen-helper';

export default class HomeViewButton extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {};

    this.homeViewString = 'START HOMEVIEW';
  }

  onHomeViewClick = () => {
    FullScreen.setFullscreen(true);
    this.props.onClick && this.props.onClick();
  };

  render() {
    return (
      <div className="amav-homeview-button-wrapper">
        <div className="amav-homeview-button-view">
          <div className="amav-homeview-button" onClick={this.onHomeViewClick}>
            {this.homeViewString}
          </div>
        </div>
      </div>
    );
  }
}
