// Imports
// Base

// Exports
export const ViewModes = {
  normal: {
    type: 'normal',
    title: 'normal',
    class: 'amav-button--normal',
    icon: 'amav-icon--remove-red-eye'
  },
  gyro: {
    type: 'gyro',
    title: 'gyroscope',
    class: 'amav-button--gryo',
    icon: 'amav-icon--explore'
  },
  vr: {
    type: 'vr',
    title: 'virtual reality',
    class: 'amav-button--vr',
    icon: 'amav-icon--3d-rotation'
  }
};

export const isNormalViewMode = (mode) => mode === ViewModes.normal;
export const isGyroViewMode = (mode) => mode === ViewModes.gyro;
export const isVRViewMode = (mode) => mode === ViewModes.vr;
