// Base
import React from 'react';
import BaseComponent from '@aroundmedia/frontend-library/lib/shared/basecomponent/BaseComponent';
// import { Notifier } from '@aroundmedia/frontend-library/lib/shared/Notifier';
import { Matrix4, Frustum, TextureLoader, Vector3, Vector2 } from 'three';

import { withMiniStore } from '../../MiniStore';

import SpotLink from './SpotLink';
import SpotLink3D from './SpotLink3D';

import SpotLinkHelper from '../../helpers/spotlink-helper';
import TwilioManager from '../../helpers/twilio-helper';
import { loadAssetWithName } from '../../helpers/helpers';

import { isVRViewMode } from '../../config/types/ViewModes';

class SpotLinksContainer extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentSpot: props.currentSpot
    };

    // Note - This may be lifted higher when approriate to do so.
    this.frustum = new Frustum();

    this.frustum.setFromProjectionMatrix(
      new Matrix4().multiplyMatrices(
        this.props.camera.projectionMatrix,
        this.props.camera.matrixWorldInverse
      )
    );

    if (this.props.isMobile) {
      const textures = [
        'vr-icon-place.png',
        'vr-icon-one.png',
        'vr-icon-two.png',
        'vr-icon-three.png'
      ];

      const snapShotPromises = [];
      const textureLoader = new TextureLoader();
      textureLoader.crossOrigin = '';

      textures.forEach((item) => {
        const snapShotPromise = new Promise((resolve) => {
          loadAssetWithName(
            textureLoader,
            item,
            (_texture) => {
              const texture = _texture;
              texture.magFilter = THREE.LinearFilter;
              texture.minFilter = THREE.LinearFilter;
              texture.needsUpdate = true;
              resolve(texture);
            },
            () => {},
            (error) => {
              window.console.log(`error loading vr texture: ${error}`);
            }
          );
        });

        snapShotPromises.push(snapShotPromise);
      });

      Promise.all(snapShotPromises).then((results) => {
        this.onSpotLinkTexturesCreated(results);
      });
    } else {
      setTimeout(() => {
        this.onSpotLinkTexturesCreated();
      }, 0);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.currentSpot &&
      nextProps.currentSpot.objectId !== this.props.currentSpot.objectId
    ) {
      this.setState({
        currentSpot: nextProps.currentSpot
      });
    }
  }

  onSpotLinkClick = (type, objectId) => {
    if (type === 'info_point_ref') {
      this.props.onInfoPointClick(objectId);
    }
    if (type === 'spot_point_ref') {
      this.props.onSpotPointClick(objectId);
    }
  };

  onSpotLinkTexturesCreated = (textures) => {
    this.generatedTextures = textures;
    this.props.onVRTexturesLoad();
    this.setState({ texturesDone: true });
  };

  createPositionObject = (spotLink) => {
    const spotLinkPosObject = {};

    const point3 = new Vector3(
      spotLink.point[0],
      spotLink.point[1],
      spotLink.point[2]
    ).normalize();

    spotLinkPosObject.Point3D = SpotLinkHelper.get3DPointOnSphere(point3, 20);
    spotLinkPosObject.Point2D = SpotLinkHelper.get2Dfor3D(
      spotLinkPosObject.Point3D,
      this.props.camera,
      this.props.view
    );

    const centerPoint = new Vector2(
      this.props.view.clientWidth / 2,
      this.props.view.clientHeight / 2
    );
    spotLinkPosObject.distance = centerPoint.distanceTo(
      spotLinkPosObject.Point2D
    );

    return spotLinkPosObject;
  };

  buildSpotLinks = () => {
    const links = [];
    if (this.props.albumData) {
      // TODO - make it figure out the width and height based on CSS
      this.state.currentSpot.spotLinkList.forEach((spotLink) => {
        if (
          spotLink.linkType === 'spot_point_ref' &&
          this.props.albumData.spotList.filter((e) => {
            if (
              e &&
              e.objectId &&
              spotLink &&
              spotLink.linkReference &&
              spotLink.linkReference.objectId
            ) {
              return e.objectId === spotLink.linkReference.objectId;
            }

            return false;
          }).length <= 0
        ) {
          return;
        }

        const posObject = this.createPositionObject(spotLink);

        // TODO - MOVE THIS TO componentWillReceiveProps
        // Check if the spotlink should be hidden
        let isHidden = false;
        this.props.albumData.spotList.forEach((item) => {
          if (
            item.objectId === spotLink.linkReference.objectId &&
            item.hideInGallery
          ) {
            isHidden = true;
          }
        });
        const { appState } = this.props;

        if (!isHidden) {
          if (
            isVRViewMode(appState.viewMode) &&
            spotLink.linkType === 'spot_point_ref'
          ) {
            links.push(
              <SpotLink3D
                data={spotLink}
                posObject={posObject}
                view={this.props.view}
                key={spotLink.objectId}
                width={30}
                height={30}
                onSpotLinkClick={this.onSpotLinkClick}
                textures={this.generatedTextures}
                scene={this.props.scene}
                frustum={this.frustum}
              />
            );
          } else {
            links.push(
              <SpotLink
                data={spotLink}
                posObject={posObject}
                camera={this.props.camera}
                view={this.props.view}
                key={spotLink.objectId}
                width={30}
                height={30}
                frustum={this.frustum}
                onSpotLinkClick={this.onSpotLinkClick}
              />
            );
          }
        }
      });
    }

    if (TwilioManager) {
      TwilioManager.locations.forEach((location) => {
        if (location.spotObjectId === this.props.currentSpot.objectId) {
          // Flip it, because reasons..
          const posObject = this.createPositionObject({
            point: [
              location.point.x * -1 || location.point[0] * -1 || 0,
              location.point.y * -1 || location.point[1] * -1 || 0,
              location.point.z * -1 || location.point[2] * -1 || 0
            ]
          });
          const data = { linkType: 'location_point_ref' };

          links.push(
            <SpotLink
              data={data}
              posObject={posObject}
              camera={this.props.camera}
              view={this.props.view}
              key={`location_${location.id}`}
              width={20}
              height={20}
              frustum={this.frustum}
            />
          );
        }
      });
    }

    return links;
  };

  render() {
    this.props.camera.updateMatrix(); // make sure camera's local matrix is updated
    this.props.camera.updateMatrixWorld(); // make sure camera's world matrix is updated
    this.props.camera.matrixWorldInverse.getInverse(
      this.props.camera.matrixWorld
    );

    this.frustum.setFromProjectionMatrix(
      new Matrix4().multiplyMatrices(
        this.props.camera.projectionMatrix,
        this.props.camera.matrixWorldInverse
      )
    );

    const links = this.buildSpotLinks();

    let creator;
    // if (!this.state.texturesDone) {
    //     creator = (
    //         <SpotLinkTextureCreator
    //             isMobile={this.props.isMobile}
    //             onCreated={this.onSpotLinkTexturesCreated}
    //             loaded={this.props.loaded}
    //         />
    //     );
    // }

    return (
      <div className="spot-links-container">
        {links}
        {creator}
      </div>
    );
  }
}

export default withMiniStore(SpotLinksContainer);
