// Imports
// Base

// Exports

/**
 * get the src url of the script for this string
 * @param scriptName
 * @return {string|*}
 */
export const scriptSource = (scriptName) => {
    const scripts = document.getElementsByTagName('script');
    let location;
    let script;

    for (let i = 0; i < scripts.length; ++i) {
        location = scripts[i].src;
        if (location.indexOf(scriptName) > -1) {
            script = scripts[i];
        }
    }

    if (script.getAttribute.length) {
        return script.src;
    }

    return script.getAttribute('src', -1);
};

/**
 * get the dir for this script
 * @param scriptSrc
 * @return {string}
 */
export const scriptDir = (scriptSrc) => {
    const base = scriptSrc;
    const elems = base.split('/');
    const fileName = elems[elems.length - 1];
    const fileParentFolder = elems[elems.length - 2];
    const lengthOfEnd = fileName.length + fileParentFolder.length + 2;
    const lengthOfNewString = base.length - lengthOfEnd;

    return base.substring(0, lengthOfNewString);
};

/**
 * load a css file
 * @param url
 * @param Class
 * @param noCache
 * @param timestamp
 * @return {Element}
 */
export const loadCssByUrl = (url, Class, noCache, timestamp) => {
    let _url = url;
    const head = document.getElementsByTagName('head')[0];
    const link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    if (Class) {
        link.className = Class;
    }

    if (noCache) {
        _url += `?ts=${Date.now()}`;
    } else if (timestamp) {
        _url += `?v=${timestamp}`;
    }

    link.href = _url;
    head.appendChild(link);
    return link;
};

/**
 * load a script file
 * @param url
 * @param id
 */
export const loadScriptByUrlAndId = (url, id) => {
    const _url = url;

    const script = document.createElement('script');
    script.src = _url;
    script.async = true;
    script.id = id;

    document.body.appendChild(script);
};

/**
 * remove a script from the dom.
 * @param id
 */
export const removeScriptById = (id) => {
    const script = document.getElementById(id);
    script.parentNode.removeChild(script);
};

export const generateRandomString = (length) => {
    let _length = length;
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZ'.split('');

    if (!_length) {
        _length = Math.floor(Math.random() * chars.length);
    }

    let str = '';
    for (let i = 0; i < _length; i++) {
        str += chars[Math.floor(Math.random() * chars.length)];
    }

    return str;
};

const posForEvent = (evt, canvas, THREE) => {
    const pos = new THREE.Vector2();

    // Save View Size
    const vS = {
        w: canvas.clientWidth,
        h: canvas.clientHeight
    };

    // Calculate Ratio between view and screen
    const ratio = {
        x: window.innerWidth / vS.w,
        y: window.innerHeight / vS.h
    };

    // make values relative to the canvas
    const x = ((evt.offsetX) * ratio.x) / window.innerWidth;
    const y = ((evt.offsetY) * ratio.y) / window.innerHeight;

    pos.x = x * 2 - 1; // eslint-disable-line
    pos.y = -y * 2 + 1; // eslint-disable-line

    return pos;
};

/**
 * find point in 3D
 * @param e
 * @param canvas
 * @param scene
 * @param camera
 * @param THREE
 */
export const findPoint3D = (e, canvas, scene, camera, THREE) => {
    const point2D = posForEvent(e, canvas, THREE);

    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(point2D, camera);

    const Objects = scene.getObjectByName('sphereCollection').children;
    const intersects = raycaster.intersectObjects(Objects, true);

    let point3D = { found: 'error' };
    const normalizer = 1000; // Because Radius of circle is 500 and magic.

    intersects.forEach((intersect) => {
        point3D = {
            found: 'success',
            x: Math.round(intersect.point.x) / normalizer,
            y: Math.round(intersect.point.y) / normalizer,
            z: Math.round(intersect.point.z) / normalizer
        };
    });

    return point3D;
};

// Flip it, because, reasons..
// Update: A reason may have been found, the camera is actually behind the midpoint looking beyond the midpoint
export const flipPoint3D = (point3D) => {
    const point = point3D;

    point.x *= -1;
    point.y *= -1;
    point.z *= -1;

    return point;
};

/**
 * Load the customcss by adding a css resource that is served
 */
export const loadCssForAlbum = (albumCode, token, version) => {
    const scriptDirectory = scriptDir(scriptSource('am-viewer'));

    loadCssByUrl(
        `${scriptDirectory}/buildcss/?id=${albumCode}&token=${token}&amv=${version}`,
        'customCss', false, false);
};

/**
 * Load an asset with name
 * @param {THREE.TextureLoader} texLoader - the loader for textures
 * @param {string} name - The asset name
 * @param {function} onLoad
 * @param {function} onProgress
 * @param {function} onError
 */
export const loadAssetWithName = (texLoader, name, onLoad, onProgress, onError) => {
    const scriptDirectory = scriptDir(scriptSource('am-viewer'));

    return texLoader.load(`${scriptDirectory}/assets/${name}`, onLoad, onProgress, onError);
};

/* eslint-disable no-param-reassign */
export const hasClass = (el, className) => {
    if (el.classList) {
        return el.classList.contains(className);
    }

    return !!el.className.match(new RegExp(`(\\s|^)${className}(\\s|$)`));
};

export const addClass = (el, className) => {
    if (el.classList) {
        el.classList.add(className);
    } else if (!hasClass(el, className)) {
        el.className += ` ${className}`;
    }
};

export const removeClass = (el, className) => {
    if (el.classList) {
        el.classList.remove(className);
    } else if (hasClass(el, className)) {
        const reg = new RegExp(`(\\s|^)${className}(\\s|$)`);
        el.className = el.className.replace(reg, ' ');
    }
};
/* eslint-enable no-param-reassign */
